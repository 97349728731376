import { logout } from "actions/authActions";
import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";
// reactstrap components
import {
	Button, Container, DropdownToggle, Media, Nav, Navbar, UncontrolledDropdown
} from "reactstrap";
import { compose } from "redux";

class AdminNavbar extends React.Component {
	render() {
		return (
			<>
				<Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
					<Container fluid>
						<Link
							className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
							to="/"
						>
							{this.props.brandText}
						</Link>
						<Nav className="align-items-center d-none d-md-flex" navbar>
							<Button
								onClick={e => {
									e.preventDefault();
									this.props.logout();


								}}
							>
								Logout
							</Button>

							<UncontrolledDropdown nav>
								<DropdownToggle className="pr-0" nav>
									<Media className="align-items-center">
									</Media>
								</DropdownToggle>
							</UncontrolledDropdown>
						</Nav>
					</Container>
				</Navbar>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		authError: state.auth_consultant.authError,
		consultants: state.firestore.ordered.consultants,
		raters: state.firestore.ordered.raters,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(logout()),
	};
};

export default compose(
	firestoreConnect([
		{ collection: "users" },
		{ collection: "consultants" },
		{ collection: "raters" },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(AdminNavbar);
