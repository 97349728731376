import { deleteAssessment } from 'actions/clientActions';
import { reset } from 'actions/clientActions';
import { addAssessment, editAssessment } from 'actions/clientActions';
import AlertDialog from 'components/AlertDialog';
import DeleteDialog from 'components/DeleteDialog';
import Header from 'components/Headers/Header';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invite } from 'actions/clientActions';
import { firestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import ReactExport from 'react-export-excel';

// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	Col,
	Button,
	CardBody,
	Table,
	Media,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Form,
	FormFeedback,
	UncontrolledTooltip,
	ModalFooter,
} from 'reactstrap';
import { compose } from 'redux';
import { getExportData } from 'actions/buyAssessment';
import moment from 'moment';
import { timezones } from 'variables/constants';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class viewProject extends Component {
	state = {
		Question: false,
		invite: false,
		confirmAssessment: false,
		shortOverview: false,
		assessmentType: '',
		ownerFirstName: '',
		ownerLastName: '',
		ownerEmail: '',
		ownerRole: '',
		ownerLang: 'english',
		assessmentID: '',
		showAlert: false,
		showAlertKey: '',
		deleteAssessment: false,
		deleteKey: '',
		deleteAssessmentId: '',
		showOnwerForm: false,
		assessmentTitle: '',

	};
	prevLink = null;
	toggleOverviewModal = () => {
		this.setState({
			shortOverview: !this.state.shortOverview,
		});
	};
	toggle = e => {
		this.setState({ Question: !this.state.Question });
	};
	clearFields = () => {
		this.setState({
			ownerFirstName: '',
			ownerLastName: '',
			ownerEmail: '',
			assessmentType: '',
			ownerRole: '',
			ownerFirstName_error: '',
			ownerLastName_error: '',
			ownerEmail_error: '',
			ownerRole_error: '',
			ownerLang_error: '',

		})

		this.toggle()
	}
	componentDidMount() {
		if (this.props.location.state !== null) {
			localStorage.setItem(
				'selected_client',
				this.props.location.state?.client
			);
			this.prevLink = this.props.location.state?.prevLink;
		}
	}
	toggleDeleteAssessment = () => {
		this.setState({
			deleteAssessment: !this.state.deleteAssessment,
		})
	}
	toggleOwnerForm = () => {
		this.setState({ showOnwerForm: !this.state.showOnwerForm })
	}
	toggleCAssessment = e => {
		this.setState({ confirmAssessment: !this.state.confirmAssessment });
	};
	getTimezone(val) {
		let obj = timezones.find(item => item.value === val);
		return obj;
	}
	toggleShowAlert = () => {
		this.setState({
			showAlert: !this.state.showAlert,
		})
	}

	inviteToggle = e => {
		e.preventDefault();
		this.setState({ invite: !this.state.invite });
	};
	getAssessment(objs) {

		let obj = this.props.assessments.find(
			item => item.id === objs.assessmentId
		);
		if (obj !== null) {
			return obj;
		} else {
			return {
				id: '',
				title: '',
			};
		}
	}
	getExportData = async (client_id, project_id, assessnent_id) => {
		await this.props.getExportData(client_id, project_id, assessnent_id);
		return this.props.exportData[assessnent_id];
	};
	getTotalRaters = () => {
		let obj =
			this.props.invitations &&
			this.props.invitations.filter(
				inv => inv.assessmentId === this.state.assessmentId
			);
		if (obj) return obj.length;
	};
	getAnsweredRaters = () => {
		let obj =
			this.props.invitations &&
			this.props.invitations.filter(
				inv =>
					inv.assessmentId === this.state.assessmentId &&
					(inv?.answers?.length > 0 || inv?.freeTexts?.length > 0)
			);
		if (obj) return obj.length;
	};

	sendInvitation = (
		thisAssessment,
		thisConsultant,
		thisProject,
		thisClient,
		template,
	) => {
		let consultantName =
			thisConsultant?.first_name + ' ' + thisConsultant?.last_name;
		let email = this.state.ownerEmail
		let firstname = this.state.ownerFirstName;
		let lastname = this.state.ownerLastName;
		const lang = this.state.ownerLang

		let subject = template[lang] && template[lang].subject;
		let message = template[lang] && template[lang].message;

		let clientName = thisClient?.name;
		let projectName = thisProject?.name;
		let assessmentName = thisAssessment?.title;

		const assessmentId = this.state.assessmentID
		const clientId = this.props.match.params.clientId;
		const projectId = this.props.match.params.id;


		const position = this.state.ownerRole
		const type = "Owner"

		const emailTemplateData = {
			assessmentId,
			projectId,
			clientId,
			lang,
			email,
			type,
			position,
			relationship: "",
			// This will be saved inside inviation document
			invitationBody: {
				firstname,
				lastname,
				type,
				lang,
				assessmentName,
				consultantName,
			},
			emailContent: {
				subject,
				message,
			},
			emailData: {
				RaterFirstname: firstname,
				RaterLastname: lastname,
				RaterEmail: email,
				ConsultantCompany: thisConsultant.companyName,
				ConsultantName: consultantName,
				ClientName: clientName,
				AssessmentName: assessmentName,

				LeaderName: `${this.state.ownerFirstName ?? ""} ${this.state.ownerLastName ?? ""}`.trim(),
				LeaderEmail: this.state.ownerEmail ?? null,
				RaterRole: position ?? "",
				RaterRelationship: "",
				ProjectName: projectName,
				ProjectStartDate: thisProject?.start_date,
				ProjectEndDate: thisProject?.end_date,
				type,

			}
		}
		this.props.invite(emailTemplateData);

	}

	render() {
		const { clients, assessments, consultants, uid, invitations } = this.props;
		const thisConsultant =
			consultants && consultants.find(con => con.id === uid);
		const clientId = this.props.match.params.clientId;
		const projectId = this.props.match.params.id;
		// const assessmentId = this.props.match.params.assessmentId;
		const assessmentId = this.state.assessmentID;
		const thisClient = clients && clients.filter(c => c.id === clientId)[0];
		const thisProject =
			thisClient &&
			thisClient.projects.filter(pro => pro.id === projectId)[0];
		const thisAssessment =
			assessments && assessments.find(ass => ass.id === assessmentId);
		let template = thisProject && thisProject.template;

		const assessmentEditFunction = (assId) => {
			const assessmentToEditData = assessments?.find((ass) => ass.id === assId)
			const leaderInformation = thisProject.owners[assId]
			this.setState({
				assessmentTitle: assessmentToEditData?.id,
				ownerFirstName: leaderInformation.firstName,
				ownerLastName: leaderInformation.lastName,
				ownerLang: leaderInformation.language ?? "english",
				ownerRole: leaderInformation.role,
				ownerEmail: leaderInformation.email
			})
		}
		const getLeaderName = (assessmentId) => {
			return thisProject.owners[assessmentId]

		}


		const handleEditAssessment = () => {
			const assessmentId = this.state.assessmentTitle
			const owners = {
				email: this.state.ownerEmail,
				firstName: this.state.ownerFirstName,
				lastName: this.state.ownerLastName,
				language: this.state.ownerLang,
				role: this.state.ownerRole
			}
			this.props.editAssessment(assessmentId, owners, clientId, projectId)
			this.toggleOwnerForm()
		}

		return (
			<>
				<Header />
				{/* Page content */}
				<Container className='mt--9' fluid>
					{/* forms */}
					<Row>
						<div className='col'>
							<Card className=' shadow '>
								<CardHeader className=' border-0 '>
									<div style={{ display: 'flex' }}>
										<Link
											to={this.prevLink ? this.prevLink : `/admin/clients/viewClient/${clientId}`}
										>
											<i
												className='fas fa-long-arrow-alt-left '
												style={{ fontSize: '25px' }}
											/>
										</Link>
										<h3 className='ml-3  '>Project</h3>
									</div>{' '}
								</CardHeader>

								<CardBody className=' mb-0 '>
									<Row className='mb-2'>
										<Col>
											<h3 className=' mb-0 '>
												Client:{' '}
												{thisClient && thisClient.name}
											</h3>
											<h3 className=' mb-0 '>
												Name:{' '}
												{thisProject &&
													thisProject.name}
											</h3>
											<h3 className=' mb-0 '>
												Start Date:{' '}
												{thisProject &&
													thisProject.start_date}
											</h3>
										</Col>
										<Col>
											<h3 className=' mb-0 '>
												Time Zone:{' '}
												{thisProject &&
													this.getTimezone(
														thisProject.time_zone
													).name}
											</h3>
											<h3 className=' mb-0 '>
												End Date:{' '}
												{thisProject &&
													thisProject.end_date}
											</h3>
										</Col>
									</Row>
									<Row>
										<Col className='text-right'>
											<Button
												className='mb-2 mt-2'
												color='default'
												href='#pablo'
												size='md'
												onClick={this.clearFields}
											>
												Add Assessment
											</Button>
											{(this.state.Question || this.state.showOnwerForm) && (
												<Modal
													isOpen={this.state.Question || this.state.showOnwerForm}
													toggle={this.state.showOnwerForm ? this.toggleOwnerForm : this.toggle}
												>
													<ModalHeader
														toggle={this.state.showOnwerForm ? this.toggleOwnerForm : this.toggle}
													>
														{this.state.showOnwerForm ? "Edit Assessment" : "Add Assessment"}
													</ModalHeader>
													<ModalBody>
														<Form
															onSubmit={async (e) => {
																e.preventDefault();
																var error = false;
																let ops =
																	thisProject.assessments.includes(
																		e.target
																			.assessment
																			.value
																	);
																if (e.target.assessment.value === 'Assessment') {
																	this.setState({
																		assessment_error:
																			'Please Select an Assessment',
																	});
																	error = true;
																} else if (!this.state.showOnwerForm) {
																	if (ops === true) {
																		this.setState({
																			assessment_error:
																				'This assessment is already assigned here',
																		});
																		error = true;
																	}
																}


																else if (this.state.assessmentType === "Leader") {

																	if (e.target.ownerFirstName.value === '') {
																		this.setState({
																			ownerFirstName_error:
																				'Please enter Owner First Name',
																		});
																		error = true;
																	}
																	else if (e.target.ownerLastName.value === '') {
																		this.setState({
																			ownerLastName_error:
																				'Please enter Owner Last Name',
																		});
																		error = true;
																	}
																	else if (e.target.ownerEmail.value === '') {
																		this.setState({
																			ownerEmail_error:
																				'Please enter Owner Email',
																		});
																		error = true;
																	}
																	else if (e.target.ownerRole.value === '') {
																		this.setState({
																			ownerRole_error:
																				'Please enter Owner Role',
																		});
																		error = true;
																	} else if (e.target.ownerLang.value === '') {
																		this.setState({
																			ownerLang_error:
																				'Please enter Language',
																		});
																		error = true;
																	}

																}

																else {
																	this.setState({
																		assessment_error:
																			'',
																		assessment:
																			e.target
																				.assessment
																				.value,
																	});
																}
																if (!error) {
																	if (this.state.assessmentType === "Leader") {
																		this.sendInvitation(
																			thisAssessment,
																			thisConsultant,
																			thisProject,
																			thisClient,
																			template,
																		)

																	}
																	this.setState(
																		{
																			assessment:
																				e
																					.target
																					.assessment
																					.value,
																		},

																	);
																	this.toggleCAssessment();
																}

															}}
														>
															{thisConsultant?.buyAssessment && (
																<div style={{ padding: "10px" }} >
																	<Label>
																		Select Assessment
																	</Label>
																	<Input
																		name='assessment'
																		type="select"
																		onChange={(e) => {
																			const value = this.props.assessments.find((assessment) => assessment.id === e.target.value)
																			this.setState({
																				assessmentType: value.type,
																				assessmentID: e.target.value,
																				assessmentTitle: value.title
																			})
																		}}
																		disabled={this.state.showOnwerForm}
																		valid={
																			!this.state
																				.assessment_error &&
																			this.state.assessmentID
																		}
																		defaultValue={this.state.assessmentTitle}
																		invalid={
																			this.state
																				.assessment_error
																		}
																	>
																		<option hidden>
																			Assessment
																		</option>
																		{assessments &&


																			thisConsultant?.buyAssessment

																				?.map(
																					ass => {
																						return (
																							<option
																								value={this.getAssessment(ass)?.id}
																								selected={this.state.showOnwerForm}
																							>

																								{this.getAssessment(ass)?.title}
																							</option>
																						);
																					}
																				)}
																	</Input>
																	<FormFeedback invalid >
																		{
																			this.state
																				.assessment_error
																		}
																	</FormFeedback>
																</div>
															)}
															{this.state.assessmentType === "Leader" || this.state.showOnwerForm ? (
																<div style={{ padding: "10px", }} >
																	<h4> Leader Information </h4>
																	<Label>First Name </Label>
																	<Input
																		name='ownerFirstName'
																		defaultValue={this.state.ownerFirstName}
																		onChange={(e) => {
																			this.setState({
																				ownerFirstName: e.target.value
																			})
																		}}
																		valid={
																			!this.state
																				.ownerFirstName_error &&
																			this.state
																				.ownerFirstName
																		}
																		invalid={
																			this.state
																				.ownerFirstName_error
																		}

																	/>
																	<FormFeedback invalid>
																		{this.state.ownerFirstName_error}
																	</FormFeedback>
																	<Label>Last Name </Label>
																	<Input
																		name='ownerLastName'
																		defaultValue={this.state.ownerLastName}

																		onChange={(e) => {
																			this.setState({
																				ownerLastName: e.target.value
																			})
																		}}
																		valid={
																			!this.state
																				.ownerLastName_error &&
																			this.state
																				.ownerLastName
																		}
																		invalid={
																			this.state
																				.ownerLastName_error
																		}
																	/>
																	<FormFeedback invalid>
																		{this.state.ownerLastName_error}
																	</FormFeedback>
																	<Label>Email address </Label>
																	<Input
																		name='ownerEmail'
																		defaultValue={this.state.ownerEmail}
																		disabled={this.state.showOnwerForm}

																		type="email"
																		onChange={(e) => {
																			this.setState({
																				ownerEmail: e.target.value
																			})
																		}}
																		valid={
																			!this.state
																				.ownerEmail_error &&
																			this.state
																				.ownerEmail
																		}
																		invalid={
																			this.state
																				.ownerEmail_error
																		}
																	/>
																	<FormFeedback invalid>
																		{this.state.ownerEmail_error}
																	</FormFeedback>
																	<Label> Role </Label>
																	<Input
																		name='ownerRole'
																		defaultValue={this.state.ownerRole}

																		type='select'
																		onChange={(e) => {
																			this.setState({
																				ownerRole: e.target.value
																			})
																		}}
																		className='text-capitalize'
																		required
																	>
																		<option
																			value=""
																			hidden
																		>
																			SELECT ROLE
																		</option>

																		{this.props.components?.find((com) => com.id === "rater_roles")?.role?.map(key => (
																			<option
																				value={
																					key
																				}
																				selected={this.state.showOnwerForm}
																				className='text-capitalize'
																			>
																				{key}
																			</option>
																		))}
																	</Input>
																	<FormFeedback invalid>
																		{this.state.ownerRole_error}
																	</FormFeedback>
																	<Label> Language </Label>
																	<Input
																		name='ownerLang'
																		defaultValue={this.state.ownerLang}
																		type='select'
																		onChange={(e) => {
																			this.setState({
																				ownerLang: e.target.value
																			})
																		}}
																		className='text-capitalize'
																		required
																	>
																		<option
																			value=""
																			hidden
																		>
																			SELECT LANGUAGE
																		</option>
																		{template &&
																			Object.keys(
																				template
																			).map(key => (
																				<option
																					value={
																						key
																					}
																					selected={this.state.ownerLang === key}
																					className='text-capitalize'
																				>
																					{key}
																				</option>
																			))}
																	</Input>
																	<FormFeedback invalid>
																		{this.state.ownerLang_error}
																	</FormFeedback>
																</div>
															) : null}

															<Row className="my-2"   >
																<Col className="text-right">
																	<Link className="text-center" to="/admin/buyassessments/assessmentCheckout">
																		{thisConsultant?.buyAssessment ? <small>Buy Assessment </small> : <large>Don't have assessments? Click here to buy </large>}
																	</Link>
																</Col>
															</Row>
															{this.state.showOnwerForm ? (
																<Row>
																	<Col className='text-right'>
																		<Button
																			color='default'
																			size='md'
																			onClick={() => {
																				handleEditAssessment()
																			}}
																		>
																			Edit Assessment
																		</Button>
																	</Col>
																</Row>
															) :
																thisConsultant?.buyAssessment && (
																	<Row className='mt-2'>
																		<Col className='text-right'>
																			<Button
																				color='default'
																				size='md'
																			>
																				Add Assessment
																			</Button>
																		</Col>
																	</Row>
																)
															}


														</Form>
													</ModalBody>
												</Modal>
											)}
											{!this.state.showOnwerForm && (
												<Modal
													isOpen={
														this.state.confirmAssessment
													}
													toggle={this.toggleCAssessment}
												>
													<ModalHeader
														toggle={
															this.toggleCAssessment
														}
													>
														Confirm Assessment
													</ModalHeader>
													<ModalBody>
														<p>
															Do you really want to
															Assign this Assessment
															to Client:
															{this.props.clients &&
																this.props.clients.find(
																	client =>
																		client.id ==
																		clientId
																)?.name}{' '}
															on Project{' '}
															{thisProject &&
																thisProject.name}{' '}
															?
														</p>
														<span className='text-danger font-weight-bold'>
															<small>
																Note: It will Deduct
																from Assessment's
																Quantity.
															</small>
														</span>
													</ModalBody>
													<ModalFooter>
														<Button
															color='success'
															onClick={e => {


																this.props.addAssessment(
																	{
																		buyAssessment:
																			thisConsultant.buyAssessment,
																		assessment: this.state.assessmentID,
																		assessmentType: this.state.assessmentType,

																		owners: {
																			firstName: this.state.ownerFirstName,
																			lastName: this.state.ownerLastName,
																			email: this.state.ownerEmail,
																			role: this.state.ownerRole,
																			langugae: this.state.ownerLang
																		},
																		projectId:
																			projectId,
																		clientId:
																			clientId,
																		consultantId:
																			thisConsultant.id,
																	}
																);

																this.state.assessmentType === "Leader" && (
																	this.setState({
																		showAlert: true,
																		showAlertKey: "invitationSentOwner"
																	})
																)

																this.toggleCAssessment();
																this.toggle();
															}}
														>
															Yes
														</Button>
														<Button
															color='danger'
															onClick={e =>
																this.toggleCAssessment()
															}
														>
															No
														</Button>
													</ModalFooter>
												</Modal>
											)}
										</Col>
									</Row>
									<h3 className='ml-3 mb-2 mt-4'>
										Assessments
									</h3>

									<Table
										className='align-items-center  table-flush '
										responsive
									>
										<thead className='thead-light'>
											<tr>
												<th scope='col'> Title</th>
												<th scope='col'> Type</th>
												<th scope='col'> Leader Name </th>
												<th scope='col'> Completed </th>
												<th scope='col'>Actions</th>
											</tr>
										</thead>
										<tbody>
											{assessments &&
												assessments
													.filter(
														ass =>
															thisProject &&
															thisProject.assessments.includes(
																ass.id
															)
													)
													.map((ass, id) => {
														let completedRaters = 0;
														let totalRaters = 0;
														const invitationData = invitations?.filter((inv) => {
															return inv.assessmentId === ass.id && inv.projectId === projectId;
														})
														const data = invitationData?.map((data) => {
															if (data.status === "completed") {
																completedRaters++
															}
															totalRaters++
														})
														const leaderInformation = getLeaderName(ass.id)
														ass = {
															...ass,
															completedRaters: completedRaters,
															totalRaters: totalRaters
														}

														return (
															<tr key={id} >
																<td>
																	<Link
																		style={{
																			padding:
																				'0.25rem 0.5rem',
																		}}
																		to={
																			'/admin/clients/' +
																			clientId +
																			'/viewProject/' +
																			projectId +
																			'/viewAssessment/' +
																			ass.id
																		}
																	>
																		{ass.title}
																	</Link>
																</td>
																<td>
																	{ass.type}
																</td>
																<td> {ass.type === "Leader" ? `${leaderInformation?.firstName} ${leaderInformation?.lastName}` : "---"}	</td>

																<td  >
																	{ass.completedRaters} / {ass.totalRaters}
																</td>
																<td>
																	<Button
																		onClick={e => {
																			e.preventDefault();
																			this.setState({
																				deleteAssessmentId: ass.id,
																				deleteKey: 'deleteAssessment'
																			})
																			this.toggleDeleteAssessment()
																		}}
																		size='sm'
																		color='white'
																		id={`btnDel${id}`}
																	>
																		<i className='fas fa-trash text-red  ' />
																	</Button>
																	<UncontrolledTooltip
																		placement='top'
																		target={`btnDel${id}`}
																	>
																		Delete
																		Assessment
																	</UncontrolledTooltip>
																	{/* edit  */}
																	{ass.type === "Leader" && (
																		<>
																			<Button
																				onClick={e => {
																					e.preventDefault();
																					assessmentEditFunction(ass.id)
																					this.toggleOwnerForm()
																				}}
																				size='sm'
																				color='white'
																				id={`btnEdit${id}`}
																			>
																				<i className="ni ni-settings text-primary " />
																			</Button>
																			<UncontrolledTooltip
																				placement='top'
																				target={`btnEdit${id}`}
																			>
																				Edit
																				Assessment
																			</UncontrolledTooltip>
																		</>
																	)}

																	<Button
																		onClick={e => {
																			e.preventDefault();
																			var confirm =
																				window.confirm(
																					'Are you sure you want to reset Assessment?'
																				);
																			if (
																				confirm
																			) {
																				this.props.resetAssessment(
																					ass.id
																				);
																				alert(
																					'Assement is reset'
																				);
																			}
																		}}
																		size='sm'
																		color='white'
																		id={`btnReset${id}`}
																	>
																		<i className='far fa-hourglass' />
																	</Button>
																	<UncontrolledTooltip
																		placement='top'
																		target={`btnReset${id}`}
																	>
																		Reset
																		Assessment
																	</UncontrolledTooltip>
																	<Link
																		style={{
																			padding:
																				'0.25rem 0.5rem',
																		}}
																		to={
																			'/admin/clients/' +
																			clientId +
																			'/viewProject/' +
																			projectId +
																			'/viewAssessment/' +
																			ass.id
																		}
																		id={`btnView${id}`}
																	>
																		<Button
																			size='sm'
																			color='white'
																		>
																			<i className='fas fa-eye  ' />
																		</Button>
																	</Link>
																	<UncontrolledTooltip
																		placement='top'
																		target={`btnView${id}`}
																	>
																		View
																		Assessment
																		Detail
																	</UncontrolledTooltip>

																	<Link
																		style={{
																			padding:
																				'0.25rem 0.5rem',
																		}}
																		to={{
																			pathname:
																				'/admin/clients/' +
																				clientId +
																				'/viewProject/' +
																				projectId +
																				'/viewAssessment/' +
																				ass.id +
																				'/viewReport',
																			state: ass.id,
																		}}
																		id={`btnViewChart${id}`}
																	>
																		<Button
																			size='sm'
																			color='white'
																		>
																			<i className='fas fa-chart-bar  ' />
																		</Button>
																	</Link>
																	<UncontrolledTooltip
																		placement='top'
																		target={`btnViewChart${id}`}
																	>
																		View
																		Assessment
																		Report
																	</UncontrolledTooltip>

																	<Button
																		size='sm'
																		color='white'
																		style={{
																			padding:
																				'0.25rem 0.5rem',
																		}}
																		onClick={async e => {
																			await this.getExportData(
																				clientId,
																				projectId,
																				ass.id
																			);
																			await this.setState(
																				{
																					assessmentId:
																						ass.id,
																					assessmentTitle:
																						ass.title,
																				}
																			);
																			await this.toggleOverviewModal();
																		}}
																		id={`btnExport${id}`}
																	>
																		<i className='fas fa-download' />
																	</Button>
																	<UncontrolledTooltip
																		placement='top'
																		target={`btnExport${id}`}
																	>
																		Export
																		Report
																	</UncontrolledTooltip>
																</td>
															</tr>
														);
													})}
											{this.state.showAlert && (
												<AlertDialog
													showId={this.state.showAlertKey}
													toggle={this.toggleShowAlert}
													showAlert={this.state.showAlert}
												/>
											)}
										</tbody>
										{this.state.deleteAssessment && (
											<DeleteDialog
												toggle={this.toggleDeleteAssessment}
												deleteKey={this.state.deleteKey}
												deleteData={this.state.deleteAssessment}
												onDelete={async () => {
													await this.props.deleteAssessment(this.state.deleteAssessmentId, projectId, clientId);
												}}
											/>
										)}
									</Table>
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container>
				<Modal
					isOpen={this.state.shortOverview}
					toggle={this.toggleOverviewModal}
				>
					<ModalHeader toggle={this.toggleOverviewModal}>
						Overview
					</ModalHeader>
					<ModalBody>
						<Row>
							<Col className='font-weight-bold'>
								Invited Raters
							</Col>
							<Col>{this.getTotalRaters()}</Col>
						</Row>
						<Row>
							<Col className='font-weight-bold'>
								Answered Raters
							</Col>
							<Col>{this.getAnsweredRaters()}</Col>
						</Row>
						<div className='d-flex mt-3'>
							<ExcelFile
								element={
									<Button
										color='primary'
										style={{
											padding: '0.25rem 0.5rem',
										}}
										onClick={e =>
											this.toggleOverviewModal()
										}
									>
										Export
									</Button>
								}
								filename={`${thisProject && thisProject.name
									} - ${this.state.assessmentTitle}(${moment(
										new Date()
									).format('DD-MM-YYYY')})`}
							>
								<ExcelSheet
									data={
										this.props.exportData &&
										this.state.assessmentId &&
										this.props.exportData[
											this.state.assessmentId
										].filter(d => d.rater_id !== '')
									}
									name='Data'
								>
									<ExcelColumn
										label='Client'
										value='client'
									/>
									<ExcelColumn
										label='Project'
										value='project'
									/>
									<ExcelColumn
										label='Assessment'
										value='assessment'
									/>
									<ExcelColumn
										label='Rater ID'
										value='rater_id'
									/>
									<ExcelColumn
										label='Rater Name'
										value='rater_name'
									/>
									<ExcelColumn
										label='Dimension'
										value='dimension'
									/>
									<ExcelColumn
										label='Category'
										value='category'
									/>
									<ExcelColumn
										label='Competency'
										value='competency'
									/>
									<ExcelColumn
										label='Question'
										value='text'
									/>
									<ExcelColumn
										label='Question Type'
										value='type'
									/>
									<ExcelColumn
										label='Answer'
										value='answer'
									/>
									<ExcelColumn
										label='Project Start Date'
										value='project_start_date'
									/>
									<ExcelColumn
										label='Project End Date'
										value='project_end_date'
									/>
								</ExcelSheet>
							</ExcelFile>
							<Button
								color='dark'
								className='ml-2'
								style={{
									padding: '0.25rem 0.5rem',
								}}
								onClick={this.toggleOverviewModal}
							>
								Cancel
							</Button>
						</div>
					</ModalBody>
				</Modal>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		authError: state.auth_consultant.authError,
		clients: state.firestore.ordered.clients,
		consultants: state.firestore.ordered.consultants,
		assessments: state.firestore.ordered.assessments,
		invitations: state.firestore.ordered.invitations,
		components: state.firestore.ordered.components,
		exportData: state.assessment.exportData,
	};
};
const mapDispatchToProps = dispatch => {

	return {
		// editProject: (project, previousP, clientId) =>
		//   dispatch(editProjects(project, previousP, clientId)),
		addAssessment: (ass, projectId, clientId, consultantId, owners) =>
			dispatch(addAssessment(ass, projectId, clientId, consultantId, owners)),
		editAssessment: (assessmentId, owners, projectId, clientId,) =>
			dispatch(editAssessment(assessmentId, owners, projectId, clientId)),
		deleteAssessment: (ass, projectId, clientId) =>
			dispatch(deleteAssessment(ass, projectId, clientId)),
		resetAssessment: id => dispatch(reset(id)),
		getExportData: (client_id, project_id, assessment_id) =>
			dispatch(getExportData(client_id, project_id, assessment_id)),
		invite: (invitationObject) =>
			dispatch(invite(invitationObject)),
	};
};

export default compose(
	firestoreConnect([
		{ collection: 'clients' },
		{ collection: 'consultants' },
		{ collection: 'assessments' },
		{ collection: 'invitations' },
		{ collection: "components" }
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(viewProject);
