const initialState = {
    exportData: {

    },
    isLoading: false,
}

export function assessmentReducer(state = initialState, action) {
    switch (action.type) {
        case "EXPORT_DATA":
            let exportData = JSON.parse(JSON.stringify(state["exportData"]));
            exportData[action.id] = action.payload;
            return {
                ...state,
                exportData: exportData
            }

        default:
            return {
                ...state,
            }
    }
}