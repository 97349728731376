import { getFirebase } from "react-redux-firebase";
import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { getFirestore, reduxFirestore } from "redux-firestore";
import thunk from "redux-thunk";
import firebase, { firebaseConfig } from "./firebase";
import rootReducer from "./reducers/rootReducer";

const saveToLocalStorage = state => {
	const ls = JSON.parse(localStorage.getItem("auth_consultant"));
	if (ls === "" || ls === undefined) {
		const serializedConsultant = JSON.stringify(state.auth_consultant);
		localStorage.setItem("auth_consultant", serializedConsultant);
	}
};

const checkLocalStorage = () => {
	const serializedConsultant = localStorage.getItem("auth_consultant");
	if (serializedConsultant === null) return undefined;
	return {
		auth_consultant: JSON.parse(serializedConsultant),
	};
};

export const store = createStore(
	rootReducer,
	checkLocalStorage(),
	composeWithDevTools(
		compose(
			applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
			reduxFirestore(firebase, firebaseConfig)
		)
	)
);

store.subscribe(() => saveToLocalStorage(store.getState()));
