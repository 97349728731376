import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Table,
  Media,
  Input,
  Label,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormFeedback,
  UncontrolledTooltip,
  Alert
} from 'reactstrap';
import { timezones } from 'variables/constants';
import moment from 'moment';
import { compose } from 'redux';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftjstohtml from 'draftjs-to-html';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { addProjects } from 'actions/clientActions';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Editor } from 'react-draft-wysiwyg';


const AddProjectForm = ({ clientId, toggle, isOpenForm, props }) => {
  const [projectData, setProjectData] = useState({
    name: null,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(2, 'weeks').format('YYYY-MM-DD'),
    description: null,
    timeZone: '+00:00',
    templateId: null,
    templateContent: null,
    templateLanguage: null,
    templateData: null,
    templateSubject: null,
    templateMessage: null
  })
  const possibleVariables = [
    '*|RaterFirstname|*',
    '*|RaterLastname|*',
    '*|RaterEmail|*',
    '*|ConsultantName|*',
    '*|ConsultantCompany|*',
    '*|ClientName|*',
    '*|ProjectName|*',
    '*|ProjectStartDate|*',
    '*|ProjectEndDate|*',
    '*|AssessmentName|*',
    '*|LeaderName|*',
    '*|LeaderEmail|*',
    '*|RaterRole|*',
    '*|RaterRelationship|*',
  ]
  const [editorState, setEditorState] = useState(null)
  const [copied, setCopied] = useState(null)


  useEffect(() => {
    if (projectData?.templateData) {
      const { templateData } = projectData
      const message = templateData?.[projectData?.templateLanguage]?.message
      const blocksFromHtml = htmlToDraft(message)
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState)
    }
  }, [projectData.templateLanguage])

  const onEditorStateChange = (editorState) => {
    let { templateData } = JSON.parse(JSON.stringify(projectData));
    templateData[projectData.templateLanguage].message = draftjstohtml(convertToRaw(editorState.getCurrentContent()));
    setEditorState(editorState)
    setProjectData({
      ...projectData,
      templateData
    })

  };


  const handleSubmit = (e) => {
    e.preventDefault()
    let addProjectContent = {}

    addProjectContent = {
      name: projectData.name,
      start_date: projectData.startDate,
      end_date: projectData.endDate,
      time_zone: projectData.timeZone,
      status: 'Open',
      assessments: [],
      template: projectData.templateData,
      template_id: projectData.templateId,
      description: projectData.description
    }
    props.addProject(addProjectContent, clientId)
    toggle()

  }
  return (

    <>
      <Modal
        isOpen={isOpenForm}
        toggle={toggle}
        size='lg'
      >
        <ModalHeader
          toggle={toggle}
        >
          Add Project
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={handleSubmit}
          >
            <Label>Name</Label>
            <Input
              name='name'
              type="text"
              required
              value={projectData.name}
              onChange={(event) => {
                setProjectData({
                  ...projectData,
                  name: event.target.value
                })
              }}

            />

            <Label>
              Start Date
            </Label>
            <Input
              name='start'
              type='date'
              required
              value={projectData.startDate}
              onChange={(event) => {
                setProjectData({
                  ...projectData,
                  startDate: event.target.value
                })
              }}

            />


            <Label>End Date</Label>
            <Input
              name='end'
              required
              type='date'
              value={projectData.endDate}
              onChange={(event) => {
                setProjectData({
                  ...projectData,
                  endDate: event.target.value
                })
              }}

            />

            <Label> Description </Label>
            <Input
              name="description"
              type="textarea"
              required
              value={projectData.description}
              onChange={(event) => {
                setProjectData({
                  ...projectData,
                  description: event.target.value
                })
              }}

            />
            <Label>Time Zone  </Label>
            <Input
              name='time_zone'
              required
              type='select'
              value={projectData.timeZone}
              onChange={(event) => {
                setProjectData({
                  ...projectData,
                  timeZone: event.target.value
                })
              }}
            >
              {timezones?.map(time => {
                return (
                  <option
                    value={time.value}
                    selected={projectData.timeZone}
                  >
                    {time.name}
                  </option>
                );
              })}
            </Input>

            <Label> Template </Label>
            <Input
              name='template'
              required
              type='select'
              value={projectData.templateId}
              onChange={(event) => {
                const selectedTemplate = props.templates.find((tem) => tem.id === event.target.value)
                setProjectData({
                  ...projectData,
                  templateId: event.target.value,
                  templateContent: selectedTemplate,
                  templateLanguage: "english",
                  templateData: selectedTemplate?.template,
                  templateSubject: selectedTemplate?.template[projectData?.templateLanguage]?.subject,
                  templateMessage: selectedTemplate?.template[projectData?.templateLanguage]?.message,
                })
              }}
            >
              <option value={''} > --Select Template-- </option>
              {props.templates?.map(temp => {
                return (
                  <option
                    value={temp.id}
                    selected={projectData.templateId}
                  >
                    {temp.title}
                  </option>
                );
              })}
            </Input>


            {projectData.templateId && (
              <>
                <Label>
                  <b>
                    Possible
                    Variables:
                  </b>
                </Label>
                <ul>
                  {possibleVariables.map((variable, idx) => (
                    <CopyToClipboard
                      text={
                        variable
                      }
                      options={{
                        format: "text/plain"
                      }}
                      onCopy={() => {
                        setCopied(idx)
                        window.setTimeout(
                          () => {
                            setCopied(-1)
                          },
                          1500
                        );
                      }}
                    >
                      <li className='pointer'>
                        {variable}
                        <span
                          className={`ml-3 font-weight-bold text-success ${copied === idx ? 'd-inline-block' : 'd-none'
                            }`}
                        >
                          Copied
                        </span>
                      </li>
                    </CopyToClipboard>
                  )
                  )}
                </ul>
                <b>
                  Note:
                  *|Variable|*,
                  Kindly
                  use
                  variable
                  as
                  mentioned
                </b>
                <br />
                <Label>
                  Subject
                </Label>
                <Input
                  onChange={(e) => {
                    let { templateData } = JSON.parse(JSON.stringify(projectData));
                    templateData[projectData.templateLanguage].subject = e.target.value;
                    setProjectData({
                      ...projectData,
                      templateData
                    })
                  }}
                  value={projectData.templateData[projectData.templateLanguage]?.subject}
                  type='text'
                ></Input>


                <Label>
                  Message
                </Label>
                <Editor
                  stripPastedStyles={
                    true
                  }

                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  wrapperClassName='demo-wrapper'
                  editorClassName='demo-editor'
                  toolbarClassName='toolbar-wrapper'
                />
              </>
            )}

            <Row className='mt-2'>
              <Col className='text-right'>
                <Button
                  color='default'
                  size='md'
                  type='submit'
                >
                  Add Project
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}
const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    authError: state.auth_consultant.authError,
    clients: state.firestore.ordered.clients,
    consultants: state.firestore.ordered.consultants,
    assessments: state.firestore.ordered.assessments,
    templates: state.firestore.ordered.templates,
    rawTemplates: state.firestore.data.templates,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addProject: (project, clientId) =>
      dispatch(addProjects(project, clientId)),

  };
};

export default compose(
  firestoreConnect([
    { collection: 'clients' },
    { collection: 'consultants' },
    { collection: 'assessments' },
    { collection: 'templates' },
  ]),
  connect(mapStateToProps, mapDispatchToProps)
)(AddProjectForm);
