import React, { useState } from "react";
import firebase from "firebase"
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { Button, Form } from "reactstrap";
import { buyAssessment } from "actions/buyAssessment";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";

const CheckoutForm = props => {
	const [redirect, setRedirect] = useState(false);
	const [dis, setDis] = useState(false);
	const { assessmentId, consultants, uid } = props;
	const thisConsultant = consultants && consultants.find(con => con.id === uid);

	const handleSubmit = async e => {
		e.preventDefault();
		setDis(true);
		if (thisConsultant) {
			firebase.functions().httpsCallable("chargeUser")({
				amount: props.amount * 100,
				description: `${thisConsultant.first_name + " " + thisConsultant.last_name
					} has bought ${props.assessmentName}`,
				assessmentId: assessmentId,
				quantity: props.quantity,
				uid: thisConsultant.uid,
			}
			)
				.then(data => {
					setTimeout(() => {
						setRedirect(true);
						setDis(false);
						props.delTodo();
					}, 1000);
				})
				.catch(error => {
					setDis(false);
					props.delTodo();
				});
		}
	};
	if (redirect) {
		return <Redirect from="/" to="/admin/buyassessments" />;
	}
	return (
		<Form className="text-center text-dark" onSubmit={handleSubmit}>
			<Button className="mt-2" color="primary" disabled={dis}>
				{dis ? (
					<Loader type="TailSpin" color="#00BFFF" height={20} width={20} />
				) : (
					"Buy"
				)}
			</Button>
		</Form>
	);
};

const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		authError: state.auth_consultant.authError,
		consultants: state.firestore.ordered.consultants,
		assessments: state.firestore.ordered.assessments,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		buyAssessment: (assessmentId, consultantId) =>
			dispatch(buyAssessment(assessmentId, consultantId)),
		// addAssessment: (ass, projectId, clientId) =>
		//   dispatch(addAssessment(ass, projectId, clientId)),
		// deleteAssessment: (ass, projectId, clientId) =>
		//   dispatch(deleteAssessment(ass, projectId, clientId)),
	};
};

export default compose(
	firestoreConnect([
		{ collection: "consultants" },
		{ collection: "assessments" },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(CheckoutForm);
