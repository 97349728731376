import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  pdf,
  Document,
  Page,
  Image,
  Font,
} from "@react-pdf/renderer";
import * as Mustache from "mustache";
import moment from "moment";

export const pdfLogo = require("../assets/img/brand/ia-logo.png");

Font.register({
  family: "Milliard",
  fonts: [
    {
      src: require("../assets/fonts/milliard-cufonfonts/Rene_Bieder_Milliard_SemiBold.otf"),
    },
    {
      src: require("../assets/fonts/milliard-cufonfonts/Rene_Bieder_Milliard_Light.otf"),
      fontStyle: "italic",
    },
  ],
});

// Create styles
export const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
  },
  section: {
    padding: 5,
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "8px",
    width: "100%",
    borderRadius: "8px",
  },
  header: {
    color: "#1C549A",
    padding: 8,
    fontSize: "8px",
  },

  titleDiv: {
    marginBottom: "40px",
    marginTop: "20px",
  },

  assessmentTitle: {
    color: "#3B3F40",
    fontSize: "25px",
    marginLeft: "6px",
    fontFamily: "Milliard",
  },
  subtitleStyle: {
    color: "#3B3F40",
    marginLeft: "4px",
    fontSize: "15px",
    fontFamily: "Milliard",
    marginTop: "10px",
  },
  leader: {
    color: "#069BEC",
    fontSize: "23px",
    marginBottom: "5px",
    fontFamily: "Milliard",
  },

  logoDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    width: "100%",
    marginBottom: "15px",
  },
  logo: {
    width: "40%",
  },
  leaderDiv: {
    marginTop: "25px",
  },
  dotsDiv: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
  },
  dot: {
    width: "5px",
    height: "5px",
    backgroundColor: "#069BEC",
    marginLeft: "6px",
    marginRight: "4px",
  },
  footerText: {
    color: "white",
    fontSize: "8px",
    paddingVertical: "5px",
    paddingHorizontal: "2px",
  },
  footerDiv: {
    width: "100%",
    backgroundColor: "#069BEC",
    display: "flex",
    paddingVertical: "5px",
    paddingHorizontal: "10px",
    justifyContent: "space-between",
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
  },
  page2LogoDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "20px",
    width: "100%",
    marginLeft: "-30px",
  },
  page2Logo: {
    width: "16%",
  },
  textAnswerStyle: {
    width: "100%",
    borderLeft: '1px',
    borderRight: '1px',
    alignSelf: "center",
  },
  questionText: {
    backgroundColor: "#F2F2F2",
    fontSize: "10px",
    paddingVertical: "8px",
    paddingLeft: "4px",
    fontFamily: "Milliard",
    fontStyle: "italic",
  },
  answerText: {
    fontSize: "9px",
    padding: "4px",
    fontFamily: "Milliard",
    fontStyle: "italic",
  },
  raterView: {
    fontSize: "12px",
    fontFamily: "Milliard",
    marginLeft: "6px",
    marginTop: "3px",
    marginBottom: "3px"
  },
});

export const SideLogo = () => {
  return (
    <View fixed style={pdfStyles.page2LogoDiv}>
      <Image style={pdfStyles.page2Logo} src={pdfLogo} />
    </View>
  );
};

export const PageHeaderDiv = ({
  pageHeader,
}: {
  pageHeader: PDFHeader;
}) => {
  return (
    <View fixed style={pdfStyles.leaderDiv}>
      <Text style={pdfStyles.leader}> {pageHeader?.leader} </Text>
      <Text style={pdfStyles.assessmentTitle}>{pageHeader?.title}
        {pageHeader?.groupTitle && (<span style={{ fontSize: '28px' }} > -  {pageHeader?.groupTitle}</span>)}
      </Text>
      <View
        style={{
          width: "8%",
          height: "5px",
          backgroundColor: "#069BEC",
          marginTop: "7px",
          marginLeft: "6px",
        }}
      />
    </View>
  );
};

export const PdfLogo = () => {
  return (
    <View fixed style={pdfStyles.logoDiv}>
      <Image style={pdfStyles.logo} src={pdfLogo} />
    </View>
  )

}
export const ThreeDots = () => {
  return (
    <View fixed style={pdfStyles.dotsDiv}>
      <View style={pdfStyles.dot} />
      <View style={pdfStyles.dot} />
      <View style={pdfStyles.dot} />
    </View>
  );
};

export const PageFooter = ({ pageHeader }: { pageHeader: PDFHeader }) => {
  return (
    <View fixed style={pdfStyles.footerDiv}>
      <Text style={{ ...pdfStyles.footerText }}>
        {moment().format("MMMM Do YYYY, h:mm:ss a")}
      </Text>
      <Text style={{ ...pdfStyles.footerText }}>
        Integral Agility – All rights reserved
      </Text>
      <Text
        style={{ ...pdfStyles.footerText }}
        render={({ pageNumber, totalPages }) =>
          `v${pageHeader?.version ?? "1.0.0"} | Page ${pageNumber}`
        }
      />
    </View>
  );
};

export const answersComponents = ({ questions }: { questions: Question[] }): JSX.Element[] => {
  const elements: JSX.Element[] = []

  questions?.forEach((que) => {
    elements.push(<View break key={que.id} style={pdfStyles.textAnswerStyle}>
      <View
        style={{
          width: "100%",
          backgroundColor: "black",
          height: "1px",
        }}
      />
      <Text style={pdfStyles.questionText}> {que?.title} </Text>
      <View
        style={{
          width: "100%",
          backgroundColor: "black",
          height: "1px",
        }}
      />
    </View>)

    if (que.answer?.length) {
      que.answer.forEach((ans, index) => {
        if (ans) {
          elements.push(
            <Text style={{ ...pdfStyles.answerText, ...pdfStyles.textAnswerStyle }}>
              {ans}
            </Text>
          );
          if (index < que.answer.length) {
            elements.push(
              <Text style={{ ...pdfStyles.answerText, ...pdfStyles.textAnswerStyle }}>
                -
              </Text>
            );
          }
        }
      })

    } else {
      elements.push(<Text style={{ ...pdfStyles.answerText, ...pdfStyles.textAnswerStyle }}> Not Answered </Text>)
    }

    elements.push(<View
      style={{
        width: "100%",
        backgroundColor: "black",
        height: "1px",
      }}
    />)
  })
  return elements;
};

export function renderPdfColumn(depth = 0, column: PDFColumn) {
  return (
    <View wrap={depth !== 0} style={{ flexDirection: "row" }}>
      <View style={{ margin: 2, flex: column.flex }}>
        <View
          style={{
            backgroundColor: `${column?.color ?? "white"}`,
            ...pdfStyles.section,
            alignItems: "center",
            justifyContent: column?.textAligment,
          }}
        >
          {typeof column.value === "string" ? (
            <Text style={{ color: `${column?.textColor ?? ""}` }}>
              {column.value}
            </Text>
          ) : (
            <> {column.value}</>
          )}
        </View>
      </View>

      {column.cells && (
        <View
          style={{
            flexDirection: "column",
            flex: column.totalFlex! - column.flex,
          }}
        >
          {column.cells!.map((c) => renderPdfColumn(depth + 1, c))}
        </View>
      )}
    </View>
  );
}

// Create Document Component
const PdfDocument = ({
  pdfData,
  pdfRaterViewData,
  pdfLeaderViewData,
  pdfRelationshipData,
}: {
  pdfData: PdfReportData;
  pdfRaterViewData: PdfReportData;
  pdfLeaderViewData: PdfReportData;
  pdfRelationshipData: PdfReportData;
}) => {

  return (
    <Document>
      <Page size="A4" style={pdfStyles.page} orientation="portrait" wrap>
        <View
          fixed
          style={{ width: "100%", height: "5px", backgroundColor: "#069BEC" }}
        ></View>
        {/* logo */}
        <View wrap style={{ marginHorizontal: "70px" }}>
          <PdfLogo />
          {/* main */}
          {/* leader and assessment name */}
          <PageHeaderDiv pageHeader={pdfData?.pageHeader} />
          <View style={{ marginTop: "10px" }}>
            <Text style={pdfStyles.subtitleStyle}>
              {pdfData?.pageHeader?.subtitle}
            </Text>
          </View>

          {/* <View fixed style={{ height: "30px" }} /> */}
          {/* content */}
          <View style={{ display: "flex", flexDirection: "column" }}>
            {/* table */}
            {pdfData?.cells?.map((column) => renderPdfColumn(0, column))}
          </View>
          {/* indicator*/}
          <View fixed style={{ marginVertical: "20px" }}>
            {pdfData.footer}
          </View>

          {/* 3 dots */}
          <ThreeDots />
          <View fixed style={{ height: "70px" }} />
        </View>
        {/* real footer */}


        {/* page 2 => rater view */}

        <View>
          {/* <SideLogo /> */}

          <View style={{ marginHorizontal: "70px", marginTop: "20px" }}>
            {/* LOGO */}
            <PdfLogo />
            <PageHeaderDiv pageHeader={pdfData?.pageHeader} />
            {/* total raters */}
            <View style={{ marginTop: "3px" }}>
              <Text style={pdfStyles.subtitleStyle}>
                {pdfData?.pageHeader?.subtitle}
              </Text>
            </View>
            {/* text-rater view */}
            <Text fixed style={pdfStyles.raterView}>
              Rater View - Lowest to Highest
            </Text>
            {/* content */}
            <View style={{ display: "flex", flexDirection: "column" }}>
              {/* table */}
              <View style={{ display: "flex", flexDirection: "column" }}>
                {pdfRaterViewData?.cells?.map((column) =>
                  renderPdfColumn(0, column)
                )}
              </View>
              {/* indicator*/}
              <View fixed style={{ marginTop: "20px" }}>
                {pdfData.footer}
              </View>
              {/* aspect response */}
              <View fixed style={{ marginVertical: "15px" }}>
                <Text style={{ fontSize: "8px", marginLeft: "7px" }} >Aspect Response on a 10 point scale </Text>
              </View>
              {/* 3 dots */}
              <ThreeDots />
            </View>
          </View>
          <View fixed style={{ height: "70px" }} />
        </View>

        {/* page 3 => leader view */}
        <View break>
          {/* <SideLogo /> */}
          <View style={{ marginHorizontal: "70px" }}>

            {/* LOGO */}
            <PdfLogo />
            <PageHeaderDiv pageHeader={pdfData?.pageHeader} />
            {/* total raters */}
            <View style={{ marginTop: "3px" }}>
              <Text style={pdfStyles.subtitleStyle}>
                {pdfData?.pageHeader?.subtitle}
              </Text>
            </View>
            {/* text-leader view */}
            <Text fixed style={pdfStyles.raterView}>
              Leader View - Lowest to Highest
            </Text>

            {/* content */}
            <View style={{ display: "flex", flexDirection: "column" }}>
              {/* table */}
              <View style={{ display: "flex", flexDirection: "column" }}>
                {pdfLeaderViewData?.cells?.map((column) =>
                  renderPdfColumn(0, column)
                )}
              </View>
              {/* indicator*/}
              <View fixed style={{ marginTop: "20px" }}>
                {pdfData.footer}
              </View>
              {/* aspect response */}
              <View fixed style={{ marginVertical: "15px" }}>
                <Text style={{ fontSize: "8px", marginLeft: "7px" }} >Aspect Response on a 10 point scale </Text>
              </View>
              {/* 3 dots */}

              <ThreeDots />
            </View>
          </View>
          <View fixed style={{ height: "70px" }} />
        </View>

        {/* page 4 => relationship table */}
        {/* <View break>
          <SideLogo />
          <View style={{ marginHorizontal: "70px" }}>
            LOGO
            <PdfLogo />
            <LeaderNameAndAssessment pageHeader={pdfData?.pageHeader} />

            <View style={{ marginTop: "3px" }}>
              <Text style={pdfStyles.subtitleStyle}>
                {pdfData?.pageHeader?.subtitle}
              </Text>
            </View>
            <Text fixed style={pdfStyles.raterView}>
              Relationship Table
            </Text>

            <View style={{ display: "flex", flexDirection: "column" }}>
              {pdfRelationshipData?.cells?.map((column) =>
                renderPdfColumn(0, column)
              )}
            </View>
            <View fixed style={{ marginVertical: "15px" }}>
              <Text style={{ fontSize: "8px", marginLeft: "7px" }} >Aspect Response on a 10 point scale </Text>
            </View>
          </View>
        </View> */}


        {/* page 5 */}
        {/* free text answers */}
        {/* logo */}
        <View>
          {/* <SideLogo /> */}
          <View style={{ marginHorizontal: "70px" }}>
            <PdfLogo />
            {/* leader name  */}
            <View fixed style={pdfStyles.leaderDiv}>
              <Text style={{ ...pdfStyles.leader, marginLeft: "8px" }}>
                {pdfData?.pageHeader?.leader}
              </Text>
              <Text style={{ ...pdfStyles.assessmentTitle, fontSize: "20px" }}>Free text questions report</Text>
              <View
                style={{
                  width: "8%",
                  height: "5px",
                  backgroundColor: "#069BEC",
                  marginTop: "7px",
                  marginLeft: "6px",
                }}
              />
            </View>
            <View fixed style={{ height: "30px" }} />
            {/* answer box */}
            {answersComponents({ questions: pdfData.questions })}
            <View fixed style={{ height: "40px" }}></View>
            {/* 3 dots */}
            <ThreeDots />
          </View>
        </View>

        <PageFooter pageHeader={pdfData?.pageHeader} />
      </Page>
    </Document>
  );
};

export default PdfDocument;
export const ProgressDiv = ({ leaderWidth, raterWidth }: any) => {
  return (
    <View
      style={{
        width: "100%",
      }}
    >
      <View
        style={{
          width: `${raterWidth ? raterWidth : 0}%`,
          height: "3px",
          backgroundColor: `#bcd680`,
          borderRadius: "10px",
        }}
      />
      <View style={{ height: "3px" }} />
      <View
        style={{
          width: `${leaderWidth ? leaderWidth : 0}%`,
          height: "3px",
          backgroundColor: `#a4a0ef`,
          borderRadius: "10px",
        }}
      />
    </View>
  );
};

export function getFreeTextAnswers(
  invitations: any[],
  questionId: any,
  raterType?: any
) {
  if (invitations?.length === 0) return [];
  let answerInvitations = invitations;
  if (raterType) {
    answerInvitations = invitations.filter((i) => i.type === raterType);
  }
  const raterAnswers = answerInvitations?.map((inv) => inv.answers ?? {});
  const allAnswers = raterAnswers?.reduce((all, answer) => {
    if (Object.keys(answer).includes(questionId)) {
      all.push(answer[questionId]);
    }
    return all;
  }, []);
  return allAnswers;
}


export const getScaleQuestions = (props: ScaleQuestionProps) => {
  const { assessment, allQuestionsFromClient } = props;
  const filteredQuestions = assessment?.questions?.filter((que: any) => que?.type === "SCALE")
  const questions = allQuestionsFromClient ? allQuestionsFromClient : filteredQuestions

  const orderedQuestions = questions?.map((que: any) => {
    let value;
    if (que?.dimension === "Personal Agility") {
      value = 10;
    }
    else if (que?.dimension === "Business Agility") {
      value = 20
    }
    else if (que?.dimension === "Interpersonal Agility") {
      value = 30
    }
    else if (que?.dimension === "Organizational Agility") {
      value = 40
    }
    else if (que?.dimension === "Tesco Mobile MindSet") {
      value = 50
    }
    else {
      value = 60
    }
    return {
      ...que,
      value: value
    }
  })

  return orderedQuestions?.sort((a: any, b: any) => {
    let dimenstionCompare = a?.value - b?.value;
    if (dimenstionCompare !== 0) {
      return dimenstionCompare;
    }
    let categoryCompare = a.category?.localeCompare(b.category) ?? -1;
    return categoryCompare;
  });

}

export const getScaleQuestionsByRaterSorting = (props: ScaleQuestionsBySortProps) => {
  const { assessment, invitations, allQuestionsFromClient } = props;
  const filteredQuestions = assessment?.questions
    ?.filter((que: any) => que.type === "SCALE")
  const questions = allQuestionsFromClient ? allQuestionsFromClient : filteredQuestions

  return questions?.sort((a: any, b: any) => {
    return getRatersProgress(invitations, a.id)! >
      getRatersProgress(invitations, b?.id)!
      ? 1
      : -1;
  });

}





export const getScaleQuestionsByLeadersSorting = (props: ScaleQuestionsBySortProps) => {
  const { assessment, invitations, allQuestionsFromClient } = props;
  const filteredQuestions = assessment?.questions
    ?.filter((que: any) => que.type === "SCALE")
  const questions = allQuestionsFromClient ? allQuestionsFromClient : filteredQuestions
  return questions?.sort((a: any, b: any) => {
    return getOwnerProgress(invitations, a.id)! >
      getOwnerProgress(invitations, b?.id)!
      ? 1
      : -1;
  });
}

export function getRatersProgress(invitations: any[], questionId: string) {

  const raterInvitations = invitations?.filter((inv) => {
    if (inv?.type === "Owner") {
      return false
    }
    return true
  });


  if (raterInvitations?.length === 0) return;
  const raterAnswers = raterInvitations?.map((inv) => inv.answers ?? {});

  const totalRating = raterAnswers?.reduce((total, answer) => {
    if (Object.keys(answer).includes(questionId)) {
      total += answer[questionId];
    }
    return total;
  }, 0);


  const totalRaters = raterAnswers?.filter((answer) =>
    Object.keys(answer).includes(questionId)
  ).length;
  if (totalRating === 0 || totalRaters === 0) return;



  return (totalRating / totalRaters) * 10;
}

export function getOwnerProgress(invitations: any[], questionId: string) {
  const ownerInvitations = invitations?.filter((inv) => inv.type === "Owner");
  if (ownerInvitations?.length === 0) return;
  const ownerAnswers = ownerInvitations?.map((inv) => inv.answers ?? {});

  const totalOwnerRating = ownerAnswers?.reduce((total, answer) => {
    if (Object.keys(answer).includes(questionId)) {
      total += answer[questionId];
    }
    return total;
  }, 0);

  const totalOwners = ownerAnswers?.filter((answer) =>
    Object.keys(answer).includes(questionId)
  ).length;



  if (totalOwnerRating === 0 || totalOwners === 0) return;

  return (totalOwnerRating / totalOwners) * 10;
}

export function getRaterAnswers(raterInvitations: any[], questionId: string) {
  if (raterInvitations?.length === 0) return;
  const raterAnswers = raterInvitations?.map((inv) => inv.answers ?? {});
  const totalRaters = raterAnswers?.filter((answer) =>
    Object.keys(answer).includes(questionId)
  ).length;
  return totalRaters;
}

export function getAnswers(validInvitations: any[], questionId: string) {
  if (validInvitations?.length === 0) return;
  const answers = validInvitations?.map((inv) => {
    return inv.answers[questionId];
  });
}

// total Invitaions that are completed
export function getTotalAnswerdInvitations(
  invitations: any[],
  assessmentId: string
) {
  const answerdInvitations = invitations?.filter(
    (inv) => inv.assessmentId === assessmentId
  );
  return answerdInvitations?.length;
}

// total raters only that has completed the assessment

export function getTotalAnsweredRaters(
  invitations: any[],
  assessmentId: string
) {
  const answerdRaters = invitations?.filter(
    (inv) => inv.assessmentId === assessmentId && inv.type !== "Owner"
  );
  return answerdRaters?.length ?? 0;
}

// total leaders that has completed the assessment
export function getTotalAnsweredLeader(
  invitations: any[],
  assessmentId: string
) {
  const answerdLeader = invitations?.filter(
    (inv) => inv.assessmentId === assessmentId && inv.type === "Owner"
  );
  return answerdLeader?.length ?? 0;
}

// total completed rater of relationship boss
export function getTotalRatersWithRelationship(
  invitations: any[],
  assessmentId: string,
  relationship: string
) {
  return invitations?.filter(
    (inv) =>
      inv.assessmentId === assessmentId &&
      inv.type !== "Owner" &&
      inv?.relationship === relationship
  ).length;
}

// pdf rater without rater and leader view
export function getPdfData(assessment: any, invitations: any[], leader: any) {
  invitations = invitations?.filter((inv) => inv.status === "completed");

  const scaleQuestions = getScaleQuestions({ assessment });

  function format(content: string) {
    return content?.split("*|").join("{{").split("|*").join("}}");
  }
  const data = {
    LeaderFirstName: leader?.firstName,
    LeaderLastName: leader?.lastName,
    LeaderEmail: leader?.email,
  };

  const leaderFullName = `${leader?.firstName} ${leader?.lastName}`;
  const cells: PDFColumn[] = [
    {
      flex: 2,
      totalFlex: 7,
      textColor: "#1C549A",
      value: "Dimension",
      cells: [
        {
          flex: 2,
          totalFlex: 5,
          textColor: "#1C549A",
          value: "Competency",
          cells: [
            {
              flex: 3,
              totalFlex: 3,
              value: (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    color: "#1C549A",
                  }}
                >
                  <Text>0</Text>
                  <Text>1</Text>
                  <Text>2</Text>
                  <Text>3</Text>
                  <Text>4</Text>
                  <Text>5</Text>
                  <Text>6</Text>
                  <Text>7</Text>
                  <Text>8</Text>
                  <Text>9</Text>
                  <Text>10</Text>
                </View>
              ),
            },
          ],
        },
      ],
    },
  ];
  Array.from(new Set<string>(scaleQuestions?.map((q: any) => q.dimension))).map((dimension) => {
    const ratersAverage = getAverageOf({ assessment, invitations, dimension, forLeader: false })
    const leaderAverage = getAverageOf({ assessment, invitations, dimension, forLeader: true })
    cells.push({
      value: `${dimension} \n\n  Raters (${(ratersAverage ? ratersAverage * 10 : 0)?.toFixed(0) ?? 0}) %  \n\n Leader (${(leaderAverage ? leaderAverage * 10 : 0)?.toFixed(0) ?? 0}) %` as String,
      color: "#069BEC",
      textColor: "white",
      flex: 2,
      totalFlex: 7,
      cells: Array.from(
        new Set(
          scaleQuestions
            ?.filter((q: any) => q.dimension === dimension)
            .map((q: any) => q.competency)
        )
      ).map((competency) => {
        return {
          value: competency as String,
          color: "#F0EBEB",
          textColor: "black",
          flex: 2,
          totalFlex: 5,
          cells: scaleQuestions
            .filter(
              (q: any) =>
                q.dimension === dimension && q.competency === competency
            )
            .map((q: any) => {
              return {
                value: (
                  <ProgressDiv
                    leaderWidth={getOwnerProgress(invitations, q?.id)}
                    raterWidth={getRatersProgress(invitations, q?.id)}
                  />
                ),
                flex: 3,
                totalFlex: 3,
              };
            }),
        };
      }),
    });
  }
  );
  return {
    pageHeader: {
      title: assessment?.title,
      leader: leaderFullName,
      version: assessment?.version,
      subtitle:
        "Total Raters: " +
        getTotalAnswerdInvitations(invitations, assessment?.id),
    },
    cells: cells,
    footer: (
      <div
        style={{
          marginLeft: 7,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <View
          style={{
            backgroundColor: "#a4a0ef",
            height: "3px",
            width: "30px",
            marginTop: "3px",
          }}
        ></View>
        <Text style={{ fontSize: "8px", marginRight: "8px", marginLeft: "5px" }}>Leader View</Text>
        <View
          style={{
            backgroundColor: "#bcd680",
            height: "3px",
            width: "30px",
            marginTop: "3px",
          }}
        ></View>
        <Text style={{ fontSize: "8px", marginLeft: "4px" }}> Rater View </Text>
      </div>
    ),
    questions: assessment?.questions
      ?.filter((que: any) => que.type === "FREE_TEXT")
      .map((question: any) => {
        const questionText = Mustache.render(
          format(question?.text ?? ""),
          data
        );
        const answers = getFreeTextAnswers(invitations, question?.id);
        return {
          id: question?.id,
          title: questionText,
          answer: answers.filter((a: any) => !!a.trim()),
        };
      }),
  };
}

// view data - RATER
export function getRaterViewData(assessment: any, invitations: any[]) {
  invitations = invitations?.filter((inv) => inv.status === "completed");
  const scaleQuestions = getScaleQuestionsByRaterSorting(
    {
      assessment,
      invitations
    }
  );
  const cells: PDFColumn[] = [
    {
      flex: 2,
      totalFlex: 6,
      textColor: "#1C549A",
      value: "Competency",
      cells: [
        {
          flex: 1,
          totalFlex: 4,
          textColor: "#1C549A",
          textAligment: "center",
          value: "Leader's Answer",
          cells: [
            {
              flex: 1,
              totalFlex: 3,
              textColor: "#1C549A",
              textAligment: "center",
              value: "Raters' Answer",
              cells: [
                {
                  flex: 2,
                  totalFlex: 2,
                  textAligment: "center",
                  value: (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        color: "#1C549A",
                      }}
                    >
                      <Text>0</Text>
                      <Text>1</Text>
                      <Text>2</Text>
                      <Text>3</Text>
                      <Text>4</Text>
                      <Text>5</Text>
                      <Text>6</Text>
                      <Text>7</Text>
                      <Text>8</Text>
                      <Text>9</Text>
                      <Text>10</Text>
                    </View>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  Array.from(new Set(scaleQuestions?.map((que: any) => que.competency))).map(
    (competency: any) => {
      cells.push({
        value: competency,
        color: "#F0EBEB",
        textColor: "black",
        flex: 2,
        totalFlex: 6,
        cells: Array.from(
          new Set(
            scaleQuestions?.filter((que: any) => que.competency === competency)
          )
        ).map((que: any) => {
          getAnswers(invitations, que?.id);
          return {
            value: `${(
              (getOwnerProgress(invitations, que?.id) ?? 0) / 10
            )?.toFixed(1)}`,
            color: "#F0EBEB",
            textColor: "black",
            flex: 1,
            textAligment: "center",

            totalFlex: 4,
            cells: Array.from(
              new Set(
                scaleQuestions?.filter(
                  (que: any) => que.competency === competency
                )
              )
            ).map((que: any) => {
              return {
                value: `${(
                  (getRatersProgress(invitations, que?.id) ?? 0) / 10
                )?.toFixed(1)}`,
                color: "#F0EBEB",
                textColor: "black",
                flex: 1,
                totalFlex: 3,
                textAligment: "center",

                cells: scaleQuestions
                  .filter((q: any) => {
                    if (q.competency === competency) {
                      if (getRaterAnswers(invitations, q?.id)! > 0) {
                        return q;
                      }
                    }
                  })
                  .map((que: any) => {
                    return {
                      value: (
                        <ProgressDiv
                          leaderWidth={getOwnerProgress(invitations, que?.id)}
                          raterWidth={getRatersProgress(invitations, que?.id)}
                        />
                      ),
                      textAligment: "center",
                      flex: 2,
                      totalFlex: 2,
                    };
                  }),
              };
            }),
          };
        }),
      });
    }
  );
  return {
    cells: cells,
  };
}

//view data - LEADER
export function getLeaderViewData(assessment: any, invitations: any[]) {
  invitations = invitations?.filter((inv) => inv.status === "completed");
  const scaleQuestions = getScaleQuestionsByLeadersSorting(
    {
      assessment,
      invitations
    }
  );
  const cells: PDFColumn[] = [
    {
      flex: 2,
      totalFlex: 6,
      textColor: "#1C549A",
      value: "Competency",
      cells: [
        {
          flex: 1,
          totalFlex: 4,
          textColor: "#1C549A",
          textAligment: "center",

          value: "Leader's Answer",
          cells: [
            {
              flex: 1,
              totalFlex: 3,
              textColor: "#1C549A",
              textAligment: "center",

              value: "Raters' Answer",
              cells: [
                {
                  flex: 2,
                  totalFlex: 2,
                  textAligment: "center",

                  value: (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        color: "#1C549A",
                      }}
                    >
                      <Text>0</Text>
                      <Text>1</Text>
                      <Text>2</Text>
                      <Text>3</Text>
                      <Text>4</Text>
                      <Text>5</Text>
                      <Text>6</Text>
                      <Text>7</Text>
                      <Text>8</Text>
                      <Text>9</Text>
                      <Text>10</Text>
                    </View>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  Array.from(new Set(scaleQuestions?.map((que: any) => que.competency))).map(
    (competency: any) => {
      cells.push({
        value: competency,
        color: "#F0EBEB",
        textColor: "black",
        flex: 2,
        totalFlex: 6,
        cells: Array.from(
          new Set(
            scaleQuestions?.filter((que: any) => que.competency === competency)
          )
        ).map((que: any) => {
          return {
            value: `${(
              (getOwnerProgress(invitations, que?.id) ?? 0) / 10
            )?.toFixed(1)}`,
            color: "#F0EBEB",
            textColor: "black",
            flex: 1,
            textAligment: "center",
            totalFlex: 4,
            cells: Array.from(
              new Set(
                scaleQuestions?.filter(
                  (que: any) => que.competency === competency
                )
              )
            ).map((que: any) => {
              return {
                value: `${(
                  (getRatersProgress(invitations, que?.id) ?? 0) / 10
                )?.toFixed(1)}`,
                color: "#F0EBEB",
                textColor: "black",
                flex: 1,
                totalFlex: 3,
                textAligment: "center",
                cells: scaleQuestions
                  .filter((q: any) => {
                    if (q.competency === competency) {
                      if (getRaterAnswers(invitations, q?.id)! > 0) {
                        return q;
                      }
                    }
                  })
                  .map((que: any) => {
                    return {
                      value: (
                        <ProgressDiv
                          leaderWidth={getOwnerProgress(invitations, que?.id)}
                          raterWidth={getRatersProgress(invitations, que?.id)}
                        />
                      ),
                      flex: 2,
                      textAligment: "center",
                      totalFlex: 2,
                    };
                  }),
              };
            }),
          };
        }),
      });
    }
  );
  return {
    cells: cells,
  };
}

interface AverageProps {
  assessment?: any;
  invitations: any[];
  questionId?: string;
  relationship?: string;
  dimension?: string;
  competency?: string;
  forLeader?: boolean;
  allQuestionsFromAssessments?: any[];
}

export function getAverageOf(props: AverageProps) {
  const {
    assessment,
    relationship,
    dimension,
    competency,
    invitations,
    forLeader,
    allQuestionsFromAssessments,
  } = props;
  const allQuestions = assessment?.questions ?? allQuestionsFromAssessments;


  const validQuestions = allQuestions?.filter((que: any) => {
    let valid = [];

    valid.push(que?.type === "SCALE");

    if (dimension) {
      valid.push(que?.dimension === dimension);
    }

    if (competency) {
      valid.push(que?.competency === competency);
    }
    return !valid.includes(false);
  });

  const validInvitations = invitations?.filter((inv) => {
    const valid = [];

    if (forLeader !== undefined) {
      valid.push(forLeader ? inv.type === "Owner" : inv.type !== "Owner");
    }

    if (relationship) {
      valid.push(inv.relationship === relationship);
    }

    return !valid.includes(false);
  });

  if (validInvitations?.length === 0) return 0;

  const invitationAnswers = validInvitations?.map(
    (inv: any) => inv.answers ?? {}
  );

  let totalScore = 0;
  let obtainedScore = 0;

  const validQuestionIds = validQuestions?.map((q: any) => q.id);

  validQuestionIds?.forEach((questionId: string) => {
    invitationAnswers?.forEach((answers) => {
      const answer = answers[questionId];
      if (answer) {
        totalScore += 10;
        obtainedScore += answer;
      }
    });
  });

  return (obtainedScore / totalScore) * 10;
}

// RELATIONSHIP table data
export function getRelationshipTableData(assessment: any, invitations: any[]) {
  invitations = invitations?.filter((inv) => inv.status === "completed");
  let relationships = Array.from(
    new Set(invitations?.map((i) => i.relationship))
  );
  relationships = relationships.filter((r) => r);

  const scaleQuestions = getScaleQuestions({ assessment });
  const allDimensions: string[] = Array.from(
    new Set(scaleQuestions?.map((q: any) => q.dimension))
  );
  const cells: PDFColumn[] = [
    {
      flex: 2,
      value: "",
      totalFlex: relationships.length + 3,
      cells: [
        {
          flex: 1,
          value: `Leader (${getTotalAnsweredLeader(
            invitations,
            assessment?.id
          )})`,
          totalFlex: relationships.length + 1,
          textColor: "#1C549A",
          textAligment: "center",
          cells: relationships.reduceRight((acc, r, index) => {
            return [
              {
                flex: 1,
                cells: acc,
                totalFlex: relationships.length - index,
                textAligment: "center",
                textColor: "#1C549A",
                value: `${r === "Direct Report" ? "Direct R" : r
                  } (${getTotalRatersWithRelationship(
                    invitations,
                    assessment?.id,
                    r
                  )})`,
              },
            ];
          }, null),
        },
      ],
    },
  ];

  allDimensions.forEach((dimension: string) => {
    // TODO: insert dimenstion row
    cells.push({
      value: dimension,
      flex: 2,
      color: "#069BEC",
      textColor: "white",
      totalFlex: relationships.length + 3,
      cells: [
        {
          value: `${getAverageOf({
            assessment,
            invitations,
            dimension,
            forLeader: true,
          })?.toFixed(1) ?? 0
            }`, // todo average of leader for this dimension
          flex: 1,
          totalFlex: relationships.length + 1,
          textAligment: "center",
          color: "#069BEC",
          textColor: "white",
          cells: relationships.reduceRight((acc, relationship, index) => {
            return [
              {
                value: `${getAverageOf({
                  assessment,
                  invitations,
                  relationship,
                  dimension,
                  forLeader: false,
                })?.toFixed(1) ?? 0
                  }`, // todo average of leader for this dimension
                flex: 1,
                cells: acc,
                textAligment: "center",
                color: "#069BEC",
                textColor: "white",
                totalFlex: relationships.length - index,
              },
            ];
          }, null),
        },
      ],
    });
    const dimensionQuestions: any[] = scaleQuestions.filter(
      (q: any) => q.dimension === dimension
    );
    dimensionQuestions.forEach((question: any) => {
      cells.push({
        value: question.competency,
        flex: 2,
        color: "#F0EBEB",
        textColor: "black",
        totalFlex: relationships.length + 3,
        cells: [
          {
            value: `${getAverageOf({
              assessment,
              invitations,
              questionId: question?.id,
              competency: question?.competency,
              forLeader: true,
            })?.toFixed(1) ?? 0
              }`, // todo average of leader for this dimension
            flex: 1,
            color: "#F0EBEB",
            textColor: "black",
            totalFlex: relationships.length + 1,
            textAligment: "center",
            cells: relationships.reduceRight((acc, relationship, index) => {
              return [
                {
                  value: `${getAverageOf({
                    assessment,
                    invitations,
                    relationship,
                    competency: question?.competency,
                    forLeader: false,
                  })?.toFixed(1) ?? 0
                    }`, // todo average of leader for this dimension
                  flex: 1,
                  cells: acc,
                  color: "#F0EBEB",
                  textColor: "black",
                  textAligment: "center",
                  totalFlex: relationships.length - index,
                },
              ];
            }, null),
          },
        ],
      });
    });
  });

  return {
    cells: cells,
  };
}

export interface PdfReportData {
  pageHeader: PDFHeader;
  cells: PDFColumn[];
  footer: React.ReactNode;
  questions: Question[];
}
export interface PDFHeader {
  title?: string;
  leader?: string;
  version?: string;
  subtitle?: string;
  groupTitle?: string;
  totalLeaders?: string
}
export interface PDFColumn {
  value: string | React.ReactNode;
  color?: string;
  textColor?: string;
  flex: number;
  totalFlex?: number;
  textAligment?:
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-around"
  | "space-between"
  | "space-evenly";
  cells?: PDFColumn[];
}

export interface ScaleQuestionProps {
  assessment?: any;
  allQuestionsFromClient?: Question[]

}

export interface ScaleQuestionsBySortProps {
  assessment?: any;
  allQuestionsFromClient?: Question[]
  invitations: any[]
}

export interface Question {
  title: string;
  id: string;
  answer: (string | React.ReactNode)[];
}
