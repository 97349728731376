import { deleteClient } from "actions/clientActions";
import Header from "components/Headers/Header.js";
import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";
import DeleteDialog from "components/DeleteDialog";
// reactstrap components
import {
  Button, Card,
  CardHeader, Col, Container,
  Row, Table, UncontrolledTooltip
} from "reactstrap";
import { compose } from "redux";


class Clients extends React.Component {
  state = {
    deleteKey: "",
    isDeleteClient: false,
    deleteClientData: {}
  }
  myref = React.createRef();
  toggleDeleteClient = () => {
    this.setState({
      isDeleteClient: !this.state.isDeleteClient,
    })
  }
  render() {
    const { clients, uid } = this.props;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--9" fluid>
          <Row>
            <Col className="text-right">
              <Link to="/admin/clients/addClient">
                <Button className=" mb-2" color="white" href="#pablo" size="md">
                  Add Client
                </Button>
              </Link>
            </Col>
          </Row>
          {/* table */}
          <Row>
            <div className="col">
              <Card className=" shadow">
                <CardHeader className=" border-0">
                  <h3 className=" mb-0">Clients</h3>
                </CardHeader>
                <Table className="align-items-center  table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* <th scope="col">Client Id</th> */}
                      <th scope="col">Client Name</th>
                      <th scope="col">Client Industry</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clients &&
                      clients
                        .filter((client) => client.consultantId === uid)
                        .map((client, id) => {
                          return (
                            <tr>
                              {/* <th scope="row"> */}
                              {/* <Media className="align-items-center">
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {client.id}
                                    </span>
                                  </Media>
                                </Media> */}
                              {/* </th> */}
                              <td>
                                <Link
                                  style={{ padding: "0.25rem 0.5rem" }}
                                  to={"/admin/clients/viewClient/" + client.id}
                                >
                                  {client.name}
                                </Link>
                              </td>

                              <td> {client.industry}</td>
                              <td>
                                <Link
                                  style={{ padding: "0.25rem 0.5rem" }}
                                  to={"/admin/clients/editClient/" + client.id}
                                  id={`btnSet${id}`}
                                >
                                  <Button size="sm" color="white">
                                    <i className="ni ni-settings  " />
                                  </Button>
                                </Link>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`btnSet${id}`}
                                >
                                  Edit Client
                                </UncontrolledTooltip>

                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      deleteKey: "deleteClient",
                                      deleteClientData: client
                                    })
                                    this.toggleDeleteClient()
                                  }}
                                  size="sm"
                                  color="white"
                                  id={`btnDel${id}`}
                                >
                                  <i className="fas fa-trash text-red " />
                                </Button>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`btnDel${id}`}
                                >
                                  Delete Client
                                </UncontrolledTooltip>
                                <Link
                                  style={{ padding: "0.25rem 0.5rem" }}
                                  to={"/admin/clients/viewClient/" + client.id}
                                  id={`btnView${id}`}
                                >
                                  <Button size="sm" color="white">
                                    <i className="fas fa-eye  " />
                                  </Button>
                                </Link>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`btnView${id}`}
                                >
                                  View Client
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          );
                        })}
                    {this.state.isDeleteClient && (
                      <DeleteDialog
                        toggle={this.toggleDeleteClient}
                        deleteKey={this.state.deleteKey}
                        deleteData={this.state.isDeleteClient}
                        onDelete={async () => {
                          await this.props.deleteClient(this.state.deleteClientData);
                        }}
                      />
                    )}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    authError: state.auth_consultant.authError,
    clients: state.firestore.ordered.clients,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteClient: (client) => dispatch(deleteClient(client)),
  };
};

export default compose(
  firestoreConnect([{ collection: "clients" }]),
  connect(mapStateToProps, mapDispatchToProps)
)(Clients);
