import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Header from "components/Headers/Header";
import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";
// reactstrap components
import {
	Button, Card, CardBody, CardHeader, Col, Container, Input,
	Label,
	Modal, ModalBody, ModalHeader, Row
} from "reactstrap";
import { compose } from "redux";
import CheckoutForm from "./CheckoutForm";

// import CardInput from "./CardInput";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const promise = loadStripe(
	"pk_test_51HobOEFK2Awr2HLwm9pQpARtqnK2iiY9cCszjhcQDfTIiv8NB36dosvJRnrZ3CVxyFNQfJuw4XivvR8vF4YlgbGQ00GebFH5mD"
);
class AssessmentCheckout extends Component {
	state = {
		Question: false,
		cart: [],
		redirect: false,
		quantity: 1,
		ass: null,
		assessmentId: "",
		assessmentName: "",
	};

	toggle = e => {
		// e.preventDefault();
		this.setState({ Question: !this.state.Question });
	};
	toggles = () => {
		// e.preventDefault();
		this.setState({ Question: false });
		this.props.history.push("/admin/buyassessments");
	};
	checkit() { }
	addToCart = ass => {
		var cart = this.state.cart;
		cart.push(ass);
		this.setState({ cart });
	};
	removeFromCart = index => {
		var cart = this.state.cart;
		cart.splice(index, 1);
		this.setState({ cart });
	};

	render() {
		const { assessments, consultants, uid } = this.props;
		const thisConsultant =
			consultants && consultants.find(con => con.id === uid);

		return (
			<>
				<Header />
				{/* Page content */}
				<Container className="mt--9" fluid>
					{/* forms */}
					<Row>
						<Col>
							<Card className=" shadow ">
								<CardHeader className=" border-0 ">
									<div style={{ display: "flex" }}>
										<Link to="/admin/buyassessments" className="text-default ">
											<i
												className="fas fa-long-arrow-alt-left "
												style={{ fontSize: "25px" }}
											/>
										</Link>
										<h3 className="ml-3 mb-0 ">Checkout</h3>
									</div>
								</CardHeader>
								<CardBody>
									<Row className="mb-6">
										{assessments !== null && assessments &&
											assessments
												.map(ass => {
													return ass?.status !== "inactive" &&
														(


															<Col
																xs={{ size: 12 }}
																md={{ size: 6 }}
																lg={{ size: 4 }}
																className="mb-4"
																key={ass.id}
															>
																<Card className="shadow border-default">
																	<CardBody>
																		<h3
																			style={{ fontWeight: "bold" }}
																			className="mb-0 text-capitalize"
																		>
																			{ass.title}
																		</h3>
																		<h5 className="mb-0">{ass.description}</h5>
																		<h5 className="mb-0">
																			Price : {ass.price} {ass.currency}
																		</h5>
																		<Row className="mt-5">
																			<Col className="text-right">
																				<Button
																					style={{ borderRadius: "7rem" }}
																					color="default"
																					size="sm"
																					onClick={() => {
																						this.setState({
																							ass
																						});
																						this.toggle();
																					}}
																				>
																					Checkout
																				</Button>

																			</Col>
																		</Row>
																	</CardBody>
																</Card>
															</Col>

														)

												})}
									</Row>
									{this.state.ass &&
										<Modal
											isOpen={this.state.Question}
											toggle={this.toggle}
										>
											<ModalHeader toggle={this.toggle}>
												Checkout <span style={{ fontWeight: "bold", fontSize: "15px" }} >  {this.state.ass.title} </span>
											</ModalHeader>
											<ModalBody>
												<Label>Quantity</Label>
												<Input
													name="name"
													type="number"
													step={0.1}
													min="0.1"
													onChange={e => {
														this.setState({
															quantity: e.target.value,
														});
													}}
												// valid={
												//   !this.state.client_name_error && this.state.client_name
												// }
												// invalid={this.state.client_name_error}
												/>

												<CheckoutForm
													amount={
														this.state.ass.price * this.state.quantity
													}
													assessmentName={
														this.state.ass.title
													}
													assessmentId={
														this.state.ass.id
													}
													quantity={this.state.quantity}
													delTodo={this.toggles}
												/>

											</ModalBody>
										</Modal>}
									<Row className="mt-5 mb-2">
										<Col className="text-right"></Col>
									</Row>{" "}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		authError: state.auth_consultant.authError,
		consultants: state.firestore.ordered.consultants,
		assessments: state.firestore.ordered.assessments,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		// editProject: (project, previousP, clientId) =>
		//   dispatch(editProjects(project, previousP, clientId)),
		// addAssessment: (ass, projectId, clientId) =>
		//   dispatch(addAssessment(ass, projectId, clientId)),
		// deleteAssessment: (ass, projectId, clientId) =>
		//   dispatch(deleteAssessment(ass, projectId, clientId)),
	};
};

export default compose(
	firestoreConnect([
		{ collection: "consultants" },
		{ collection: "assessments" },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(AssessmentCheckout);
