import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import React, { Children } from "react";
import {
  answersComponents,
  PageFooter,
  pdfLogo,
  PdfReportData,
  pdfStyles,
  ThreeDots,
} from "./PdfDocument";

// Create Document Component
const FreeTextPdf = ({ pdfData }: { pdfData: PdfReportData }) => {
  return (
    <Document>
      <Page size="A4" style={pdfStyles.page} orientation="portrait">
        <View
          fixed
          style={{ width: "100%", height: "5px", backgroundColor: "#069BEC" }}
        ></View>
        <View>
          <View fixed style={pdfStyles.logoDiv}>
            <Image style={pdfStyles.logo} src={pdfLogo} />
          </View>
          <View style={{ marginHorizontal: "70px" }}>
            {/* leader name  */}
            <View fixed style={pdfStyles.leaderDiv}>
              <Text style={{ ...pdfStyles.leader, marginLeft: "8px" }}>
                {pdfData?.pageHeader?.leader}
              </Text>
              <Text style={pdfStyles.assessmentTitle}>
                {pdfData?.pageHeader?.title}
              </Text>
              <Text style={{ ...pdfStyles.assessmentTitle, fontSize: "20px" }}>Free text questions report</Text>
              <Text style={pdfStyles.subtitleStyle}>
                {pdfData?.pageHeader?.subtitle}
              </Text>
              <View
                style={{
                  width: "8%",
                  height: "5px",
                  backgroundColor: "#069BEC",
                  marginTop: "7px",
                  marginLeft: "6px",
                }}
              />
            </View>
            <View fixed style={{ height: "30px" }} />
            {/* answer box */}

            {answersComponents({ questions: pdfData.questions })}
            <View fixed style={{ height: "40px" }}></View>

            {/* 3 dots */}
            <ThreeDots />
          </View>
        </View>
        <PageFooter pageHeader={pdfData?.pageHeader} />
      </Page>
    </Document>
  );
};

export default FreeTextPdf;
