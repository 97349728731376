import Header from "components/Headers/Header";
import React, { Component } from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
// reactstrap components
import { Button, Card, CardHeader, Container, Row, Spinner } from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import { compose } from "redux";
class AssessmentReport extends Component {
	// state = { Question: false, invite: false };
	toggle = e => {
		// e.preventDefault();
		// this.setState({ Question: !this.state.Question });
	};
	componentDidMount() { }
	state = {
		series: [
			{
				data: [],
			},
		],
		options: {
			chart: {
				type: "bar",
				animations: {
					enabled: false,
				},
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
					dataLabels: {
						position: "center",
					},
				},
			},
			stroke: {
				show: true,
				width: 0,
				colors: ["#fff"],
			},
			xaxis: {
				categories: [],
			},
			yaxis: {
				max: 10,
				labels: {
					show: true,
					trim: false,
				},
			},
		},
	};

	getChartDimCat = (invitations, questions) => {
		let { options, series } = JSON.parse(JSON.stringify(this.state));
		// Removing Free text type questions
		questions =
			questions &&
			questions.filter(question => question.type.toLowerCase() !== "free text");
		let dimensions = [];
		if (questions)
			questions = questions.map(question => {
				let obj = dimensions.find(dim => {
					return dim === question.dimension;
				});
				if (obj === null) dimensions.push(question.dimension);
				return obj
			});

		options.xaxis.categories = dimensions;
		let categories = [];
		questions &&
			questions.map(question => {
				let obj = categories.find(cat => {
					return cat === question.category;
				});
				if (obj === null) categories.push(question.category);
			});
		let obj = [];
		invitations &&
			invitations.map(invitaion => {
				let answers = invitaion.answers;
				answers.map((answer, idx) => {
					obj[idx] = obj[idx] === undefined ? answer : obj[idx] + answer;
				});
			});
		series = [];
		categories.forEach(cat => {
			series.push({ name: cat, data: new Array(dimensions.length) });
		});
		dimensions &&
			dimensions.forEach((dim, didx) => {
				categories &&
					categories.forEach((cat, cidx) => {
						let innerQuestions = questions.map((question, idx) => {
							let q = { ...question };
							if (question.category === cat && question.dimension === dim) {
								q.answer = obj[idx];
								return q;
							}
						});
						innerQuestions = innerQuestions.filter(ques => ques !== undefined);
						if (innerQuestions !== "") {
							let temp = 0;
							innerQuestions = innerQuestions.map((it, idx) => {
								temp = temp + it.answer;
								if (idx === innerQuestions.length - 1)
									temp = temp / innerQuestions.length;
							});
							series[cidx].data[didx] = temp;
						}
					});
			});
		return { options, series };
	};

	getChartCatComp = (invitations, questions) => {
		let { options, series } = JSON.parse(JSON.stringify(this.state));
		// Removing Free text type questions
		questions =
			questions &&
			questions.filter(question => question.type.toLowerCase() !== "free text");
		let categories = [];
		questions &&
			questions.map(question => {
				let obj = categories.find(dim => {
					return dim === question.category;
				});
				if (obj === null) categories.push(question.category);
			});

		options.xaxis.categories = categories;
		let competencies = [];
		questions &&
			questions.map(question => {
				let obj = competencies.find(cat => {
					return cat === question.competency;
				});
				if (obj === null) competencies.push(question.competency);
			});
		let obj = [];
		invitations &&
			invitations.map(invitaion => {
				let answers = invitaion.answers;
				answers.map((answer, idx) => {
					obj[idx] = obj[idx] === undefined ? answer : obj[idx] + answer;
				});
			});
		series = [];
		competencies.forEach(cat => {
			series.push({ name: cat, data: new Array(categories.length) });
		});
		categories &&
			categories.forEach((dim, didx) => {
				competencies &&
					competencies.forEach((cat, cidx) => {
						let innerQuestions = questions.map((question, idx) => {
							let q = { ...question };
							if (question.competency === cat && question.category === dim) {
								q.answer = obj[idx];
								return q;
							}
						});
						innerQuestions = innerQuestions.filter(ques => ques !== undefined);
						if (innerQuestions !== "") {
							let temp = 0;
							innerQuestions = innerQuestions.map((it, idx) => {
								temp = temp + it.answer;
								if (idx === innerQuestions.length - 1)
									temp = temp / innerQuestions.length;
							});
							series[cidx].data[didx] = temp;
						}
					});
			});
		return { options, series };
	};

	print = () => {
		var divToPrint = document.getElementById("DivIdToPrint");
		var newWin = window.open("", "Print-Window");
		newWin.document.open();
		newWin.document.write(
			"<html>" +
			"<head>" +
			"<style>" +
			"table, td, th {" +
			" border: 1px solid black;" +
			"  text-align:center;" +
			"}" +
			"table {" +
			"width: 100%;" +
			"border-collapse: collapse;" +
			"}" +
			"</style>" +
			"</head>" +
			'<body onload="window.print()">' +
			divToPrint.innerHTML +
			"</body></html>"
		);
		newWin.document.close();
		setTimeout(function () {
			newWin.close();
		}, 10);
	};
	getRaters = invitations => {
		let answers = 0;
		invitations.forEach(invitation => {
			if (invitation.answers.length > 0) answers++;
		});
		return answers;
	};

	render() {
		let assessmentId = this.props.match.params.id;
		let invitations =
			this.props.invitations &&
			this.props.invitations.filter(
				invitaion => invitaion.assessmentId === assessmentId
			);
		let questions =
			this.props.assessments &&
			this.props.assessments.find(assessment => assessment.id === assessmentId)
				.questions;
		// let isAllRatersAnswered =
		// 	invitations &&
		// 	invitations.filter(
		// 		invitation => invitation.answers.length !== questions.length
		// 	);

		// isAllRatersAnswered =
		// 	isAllRatersAnswered && isAllRatersAnswered.length === 0;
		// console.log(isAllRatersAnswered);
		let { options: options1, series: series1 } = this.getChartDimCat(
			invitations,
			questions
		);
		let { options: options2, series: series2 } = this.getChartCatComp(
			invitations,
			questions
		);

		return (
			<>
				<Header />
				{/* Page content */}
				<Container className="mt--9" fluid>
					{/* forms */}
					<Row>
						<div className="col">
							<Card className=" shadow ">
								<CardHeader className=" border-0 ">
									<div style={{ display: "flex" }}>
										{/* <Link
											to={"/admin/clients/viewClient/" + clientId}
											className="text-default "
										>
											<i
												className="fas fa-long-arrow-alt-left "
												style={{ fontSize: "25px" }}
											/>
										</Link> */}
										<i
											className="fas fa-long-arrow-alt-left "
											style={{ fontSize: "25px" }}
											onClick={() => this.props.history.goBack()}
										/>

										<h3 className="ml-3  ">Assessment Report</h3>
										{invitations &&
											invitations.length > 0 &&
											questions &&
											questions.length > 0 && (
												<div className="ml-auto">
													<Button onClick={this.print}>Print</Button>
												</div>
											)}
									</div>{" "}
								</CardHeader>
								<CardBody id="DivIdToPrint">
									{this.props.invitations ? (
										// isAllRatersAnswered ? (
										<>
											<p className="font-weight-bold">
												The stats shown below are result of{" "}
												{this.getRaters(invitations)} raters that completed the
												assessment
											</p>
											<h3 className="mt-4 mb-0">Dimensions - Categories</h3>
											<Chart
												options={options1}
												series={series1}
												type="bar"
												height={430}
											/>
											<h3 className="mt-4 mb-0">Categories - Compentencies</h3>
											<Chart options={options2} series={series2} type="bar" />
										</>
									) : (
										// ) : (
										// 	<div className="text-center">
										// 		<h2>All Raters Didn't Answer.</h2>
										// 		<Button onClick={() => this.props.history.goBack()}>
										// 			Go Back
										// 		</Button>
										// 	</div>
										// )
										<div className="text-center p-3">
											<Spinner color="dark" />
										</div>
									)}
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		invitations: state.firestore.ordered.invitations,
		assessments: state.firestore.ordered.assessments,
	};
};
const mapDispatchToProps = dispatch => {
	return {};
};

export default compose(
	firestoreConnect([
		{ collection: "clients" },
		{ collection: "consultants" },
		{ collection: "assessments" },
		{ collection: "invitations" },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(AssessmentReport);
