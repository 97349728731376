import { deleteInvitation, editInvitationAction, invite, resetRater, updateRaters, editRaterAnswers } from 'actions/clientActions';
import AlertDialog from 'components/AlertDialog';
import DeleteDialog from 'components/DeleteDialog';
import Header from 'components/Headers/Header';
import ResetAnswerDialog from 'components/ResetAnswerDialog';
import EditRaterAnswerDialog from 'components/EditRaterAnswerDialog'
import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import React, { Component } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
// reactstrap components
import {
	Alert, Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table, UncontrolledTooltip
} from 'reactstrap';
import { compose } from 'redux';
import { BsPencilFill, } from "react-icons/bs";
class viewAssessment extends Component {
	state = {
		Question: false,
		invite: false,
		alertInvitation: false,
		editInvitation: false,
		invitationData: {},
		resendEmailData: {},
		deleteInvitationId: '',
		possibleVariables: [
			'*|EvaluatorFullName|*',
			'*|PlannedCompletionDate|*',
			'*|SignUp|*',
			'*|Login|*',
			'*|PendingAssessments|*',
			'*|NeedHelpEmail|*',
			'*|ParticipantFullName|*',
			'*|ParticipantEmail|*',
		],
		resend: false,
		deleteRater: false,
		showAlert: false,
		showAlertKey: '',
		deleteKey: '',
		editorState: EditorState.createEmpty(),
		isResetAnswers: false,
		resetInvitationEmail: '',
		isEditRaterAnswers: false,
		editRaterAnswerInfo: ''

	};
	toggleResend = () => {
		this.setState({
			resend: !this.state.resend,
		});
	};

	toggleResetAnswers = () => {
		this.setState({ isResetAnswers: !this.state.isResetAnswers })
	}
	toggleEditRaterAnswer = () => {
		this.setState({ isEditRaterAnswers: !this.state.isEditRaterAnswers })
	}


	toggleDeleteRater = () => {
		this.setState({
			deleteRater: !this.state.deleteRater,
		})
	}
	toggle = e => {
		e.preventDefault();
		this.setState({
			Question: !this.state.Question,

		});
	};
	toggleShowAlert = () => {
		this.setState({
			showAlert: !this.state.showAlert,
		})
	}
	inviteToggle = e => {
		e.preventDefault();
		this.setState({ invite: !this.state.invite, email_error: "" });
	};

	inviteEditToggle = e => {
		// e.preventDefault();
		this.setState({ editInvitation: !this.state.editInvitation, email_error: "" });
	}


	getRater = (raterEmail) => {
		let { raters } = this.props;
		let obj = raters && raters.find(rater => rater.email === raterEmail);
		if (obj !== null) {
			return obj
		}
		return null;
	};
	getText(html) {
		var divContainer = document.createElement('div');
		divContainer.innerHTML = html;
		return divContainer.textContent || divContainer.innerText || '';
	}
	getTemplateLanguages = template_id => {
		let { rawTemplates } = this.props;
		if (isLoaded(rawTemplates)) {
			let obj = rawTemplates[template_id];
			if (obj !== null) return Object.keys(obj.template);
		}
		return [];
	};
	onEditorStateChange = editorState => {
		this.setState({
			editorState,
		});
	};
	typeChange = async (e, template_id) => {
		let { rawTemplates } = this.props;
		let type = e.target.value;
		if (isLoaded(rawTemplates)) {
			let obj = rawTemplates[template_id];
			let template = obj.template[type];
			const blocksFromHtml = htmlToDraft(template['message']);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentState = ContentState.createFromBlockArray(
				contentBlocks,
				entityMap
			);
			const editorState = EditorState.createWithContent(contentState);
			this.setState({
				editorState,
				subject: template['subject'],
			});
		}
		this.setState({
			type,
		});
	};

	getVariables = (type, template_id) => {
		let { rawTemplates } = this.props;
		let variables = [];
		if (isLoaded(rawTemplates)) {
			let obj = rawTemplates[template_id];
			if (obj !== null) {
				let { message, subject } = obj.template[type];
				let text = this.getText(message);
				let subjectvariables = subject.match(/\*\|[a-zA-z0-9]+\|\*/g);
				let messagevariables = text.match(/\*\|[a-zA-z0-9]+\|\*/g);
				if (subjectvariables !== null) {
					for (let sv of subjectvariables) {
						variables.push(sv);
					}
				}
				if (messagevariables !== null) {
					for (let mv of messagevariables) {
						if (!variables.includes(mv)) variables.push(mv);
					}
				}
			}
		}
		return variables;
	};
	handleResendAllInvitations = async (assessmentID, projectId, thisClient, thisProject, thisConsultant) => {
		const allInvitations = this.props.invitations;
		const invitationData = allInvitations?.filter((invitation) => {
			return invitation.assessmentId === assessmentID && invitation.projectId === projectId && invitation.status !== 'completed';
		})

		for (const invitation of invitationData) {
			const template = thisProject?.template;
			let lang = invitation?.language ?? "english"
			let email = invitation?.email
			let type = invitation?.type ?? "Rater"
			let firstname = invitation?.firstname ?? this.getRater(email)?.firstname
			let lastname = invitation?.lastname ?? this.getRater(email)?.lastname
			let assessmentName = invitation?.assessmentName ?? ""
			let consultantName = invitation?.consultantName ?? ""

			const leader = thisProject.owners[invitation?.assessmentId]


			let subject = template[lang] && template[lang].subject;
			let message = template[lang] && template[lang].message;
			const emailTemplateData = {
				assessmentId: invitation?.assessmentId,
				projectId: invitation?.projectId,
				clientId: invitation?.clientId,
				lang,
				email,
				type,
				position: invitation?.position ?? "",
				relationship: invitation?.relationship ?? "",

				invitationBody: {
					firstname,
					lastname,
					type,
					lang,
					assessmentName,
					consultantName,
				},
				emailContent: {
					subject: subject,
					message: message,
				},
				emailData: {
					RaterFirstname: firstname,
					RaterLastname: lastname,
					RaterEmail: email,
					ConsultantName: consultantName,
					ConsultantCompany: thisConsultant?.companyName,
					ClientName: thisClient?.name,
					AssessmentName: assessmentName,
					ProjectName: thisProject?.name,
					LeaderName: `${leader?.firstName ?? ""} ${leader?.lastName ?? ""}`.trim(),
					LeaderEmail: leader?.email ?? null,
					RaterRole: invitation?.position ?? "",
					RaterRelationship: invitation?.relationship ?? "",
					type,
				}
			}

			this.props.invite(emailTemplateData);

			this.setState({
				showAlert: true,
				showAlertKey: "resendAllInvitations",
			})
		}
	}

	sendInvitaion = async (
		email,
		firstname,
		lastname,
		lang,
		template,
		thisConsultant,
		thisClient,
		thisProject,
		thisAssessment,
		position,
		relationship

	) => {
		let subject = template[lang] && template[lang].subject;
		let message = template[lang] && template[lang].message;
		let consultantName =
			thisConsultant?.first_name + ' ' + thisConsultant?.last_name;
		let assessmentName = thisAssessment.title;
		let clientName = thisClient.name;
		let projectName = thisProject.name;
		const assessmentId = this.props.match.params.id;
		const clientId = this.props.match.params.clientId;
		const projectId = this.props.match.params.projectId;
		const leader = thisProject.owners[assessmentId]

		const type = "Rater"


		const emailTemplateData = {
			assessmentId,
			projectId,
			clientId,
			lang,
			email,
			type,
			position,
			relationship,
			// This will be saved inside inviation document
			invitationBody: {
				firstname,
				lastname,
				type,
				lang,
				assessmentName,
				consultantName,
			},
			emailContent: {
				subject,
				message,
			},
			emailData: {
				RaterFirstname: firstname,
				RaterLastname: lastname,
				RaterEmail: email,
				ConsultantName: consultantName,
				ConsultantCompany: thisConsultant?.companyName,
				ClientName: clientName,
				AssessmentName: assessmentName,
				ProjectName: projectName,
				ProjectStartDate: thisProject?.start_date,
				ProjectEndDate: thisProject?.end_date,
				RaterRole: position ?? "",
				RaterRelationship: relationship ?? "",
				LeaderName: `${leader?.firstName ?? ""} ${leader?.lastName ?? ""}`.trim(),
				LeaderEmail: leader?.email ?? "",

				type,
			}
		}
		this.setState({
			showAlert: true,
			showAlertKey: "invitationSent",
		})
		await this.props.invite(emailTemplateData);

	};

	render() {
		const {
			uid,
			assessments,
			invitations,
			consultants,
			rawClients,
			raters
		} = this.props;

		const assessmentId = this.props.match.params.id;
		const clientId = this.props.match.params.clientId;
		const projectId = this.props.match.params.projectId;
		const thisAssessment =
			assessments && assessments.find(ass => ass.id === assessmentId);
		const thisConsultant =
			consultants && consultants.find(con => con.id === uid);
		let thisClient = rawClients && rawClients[clientId];
		let thisProject =
			thisClient &&
			thisClient.projects.find(project => project.id === projectId);
		let template = thisProject && thisProject.template;
		let variables =
			thisProject &&
			this.state.type &&
			this.getVariables(this.state.type, thisProject.template_id);


		const invitationData = this.state.invitationData

		const invitation = this.state.invite
		const resendData = this.state.resendEmailData
		const components = this.props.components && this.props.components;
		const positions = components?.find((component) => component.id === "rater_roles")
		const relationships = components?.find((com) => com.id === "rater_relationships")
		const assessmentInvitations = invitations
			?.filter(
				inv =>
					inv.assessmentId ===
					assessmentId &&
					inv.projectId ===
					projectId &&
					inv.clientId ===
					clientId
			)?.sort((a, b) => a?.type.localeCompare(b?.type))
		const invitationsThatCanBeResent = assessmentInvitations?.filter((inv) => inv.status === "invited")

		const getLeaderName = () => {
			const leader = thisProject?.owners?.[assessmentId]
			return `${leader?.firstName ?? ""} ${leader?.lastName ?? ""}`.trim()
		}



		return (
			<>
				<Header />
				{/* Page content */}
				<Container className='mt--9' fluid>
					<div style={{ width: "300px", heigth: "200px" }} >

					</div>
					{/* forms */}
					<Row>
						<div className='col'>
							<Card className=' shadow '>

								<CardHeader className=' border-0 '>
									<div style={{ display: 'flex' }}>
										<Link
											to={
												'/admin/clients/' +
												clientId +
												'/viewProject/' +
												projectId
											}
											className='text-default '
										>
											<i
												className='fas fa-long-arrow-alt-left '
												style={{ fontSize: '25px' }}
											/>
										</Link>
										<h3 className='ml-3 mb-0 '>
											Assessment
										</h3>
									</div>
								</CardHeader>

								<CardBody className=' mb-0 '>
									<Row>
										<Col className='text-right'>
											<Button
												className='mb-2 mt-2'
												type='button'
												color='default'
												size='md'
												onClick={async e => {
													this.setState({
														type: '',
														invite: true,
														invitationData: {}

													});
													this.inviteToggle(e);
												}}

											>
												<i className='fas fa-plus' style={{ paddingRight: "6px" }} />
												Invite Rater
											</Button>

											<Col  >
												<Link to={{
													pathname: '/admin/clients/' + clientId + '/viewProject/' + projectId + '/viewAssessment/' + assessmentId + '/viewReport', state: assessmentId,
												}} className='' >
													<Button color="white" >
														View Report
													</Button>

												</Link>
											</Col>

											{(this.state.editInvitation || this.state.invite) && (
												<Modal
													isOpen={this.state.editInvitation || this.state.invite}
													toggle={this.state.editInvitation ? this.inviteEditToggle : this.inviteToggle}
													size='lg'
												>
													<ModalHeader
														toggle={this.state.editInvitation ? this.inviteEditToggle : this.inviteToggle}
													>
														{this.state.editInvitation ? "Edit Rater" : "Invite Rater"}
													</ModalHeader>
													<ModalBody>
														<Form
															onSubmit={async e => {
																e.preventDefault();
																let email =
																	e.target.email
																		.value;
																let firstname =
																	e.target
																		.firstname
																		.value;
																let lastname =
																	e.target
																		.lastname
																		.value;
																let lang =
																	e.target
																		.language
																		.value;
																let position = e.target.position.value;
																let relationship = e.target.relationship.value;
																if (this.state.editInvitation) {
																	let thisRater = raters && raters.find(rat => rat.email === email);
																	let data = this.state.invitationData
																	data = {
																		...data,
																		firstname: firstname,
																		lastname: lastname,
																		language: lang,
																		position: position,
																		relationship: relationship
																	}
																	const ratersToUpdate = {
																		email: email,
																		firstname: firstname,
																		lastname: lastname,
																		positionId: position,
																		relationship: relationship
																	}
																	this.props.editInvitationAction(data)
																	this.props.updateRaters(ratersToUpdate, thisRater.id)
																	this.inviteEditToggle();
																} else {

																	this.sendInvitaion(
																		email,
																		firstname,
																		lastname,
																		lang,
																		template,
																		thisConsultant,
																		thisClient,
																		thisProject,
																		thisAssessment,
																		position,
																		relationship

																	);
																	this.inviteToggle(e)
																}
															}}
														>
															<Label>Email</Label>
															<Input
																name='email'
																type='email'
																disabled={this.state.editInvitation}
																value={invitationData?.email}
																onChange={(e) => {
																	this.setState({
																		invitationData: {
																			...this.state.invitationData,
																			email: e.target.value.toLowerCase()
																		}
																	})
																	const filteredInvitation = invitations?.filter((inv) => {
																		let email = e.target.value
																		return inv.email === email && inv.assessmentId === assessmentId && inv.projectId === projectId
																	})
																	const populateInvData = invitations?.filter((inv) => {
																		let email = e.target.value
																		return inv.email === email
																	})
																	if (!populateInvData) return
																	this.setState({
																		invitationData: populateInvData?.[0],
																	})
																	if (filteredInvitation?.length !== 0) {
																		this.setState({
																			email_error: "Email has already been invited"
																		});
																	} else {
																		this.setState({
																			email_error: ""
																		})
																	}
																}}
																valid={
																	!this.state
																		.email_error &&
																	this.state.email
																}
																invalid={
																	this.state
																		.email_error
																}
																required
															/>
															<FormFeedback invalid > {this.state.email_error} </FormFeedback>
															<Label>Firstname</Label>
															<Input
																name='firstname'
																type='text'
																onChange={(e) => {
																	this.setState({
																		invitationData: {
																			...this.state.invitationData,
																			firstname: e.target.value
																		}
																	})
																}}
																value={invitationData?.firstname ?? this.getRater(invitationData?.email)?.firstname}
																required
															/>
															<Label>Lastname</Label>
															<Input
																name='lastname'
																type='text'
																onChange={(e) => {
																	this.setState({
																		invitationData: {
																			...this.state.invitationData,
																			lastname: e.target.value
																		}
																	})
																}}
																value={invitationData?.lastname ?? this.getRater(invitationData?.email)?.lastname}
																required
															/>
															<Label>Language</Label>
															<Input
																name='language'
																type='select'
																onChange={(e) => {
																	this.setState({
																		invitationData: {
																			...this.state.invitationData,
																			language: e.target.value
																		}
																	})
																}}
																value={invitationData?.language ?? "english"}
																className='text-capitalize'
																required
															>
																<option
																	value=''
																	hidden
																>
																	SELECT LANGUAGE
																</option>
																{template &&
																	Object.keys(
																		template
																	).map(key => (
																		<option
																			value={
																				key
																			}
																			selected={this.state.editInvitation}
																			className='text-capitalize'
																		>
																			{key}
																		</option>
																	))}
															</Input>

															{/* ROLE */}
															<Label>Role</Label>
															<Input name='position'
																type='select'
																onChange={(e) => {
																	this.setState({
																		invitationData: {
																			...this.state.invitationData,
																			position: e.target.value
																		}
																	})
																}}
																value={invitationData?.position}
																className='text-capitalize'
																required >
																<option value=''  >
																	SELECT ROLE
																</option>
																{positions?.role.map(
																	role => (
																		<option value={role} selected={invitationData?.position === role} >
																			{role}
																		</option>
																	)
																)}
															</Input>
															{/* RELATIONSHIP */}
															<Label>Relationship</Label>
															<Input name='relationship'
																type='select'
																onChange={(e) => {
																	this.setState({
																		invitationData: {
																			...this.state.invitationData,
																			relationship: e.target.value
																		}
																	})
																}}
																value={invitationData?.relationship}
																className='text-capitalize'
																required >
																<option value=''  >
																	SELECT RELATIONSHIP
																</option>
																{relationships?.relationship.map(
																	relationship => (
																		<option value={relationship} selected={invitationData?.relationship === relationship} >
																			{relationship}
																		</option>
																	)
																)}
															</Input>




															<Row className='mt-2'>
																<Col className='text-right'>
																	<Button
																		color='default'
																		size='md'
																		type="submit"
																	>
																		{this.state.editInvitation ? "Update" : "Invite"}
																	</Button>


																</Col>
															</Row>
														</Form>
													</ModalBody>
												</Modal>
											)}

										</Col>
									</Row>
									<Row className='mb-2'>
										<Col>
											<h3 className=' mb-0 '>
												Title:{' '}
												{
													thisAssessment?.title}
											</h3>
											<h3 className=' mb-0 '>
												Type:{' '}
												{
													thisAssessment?.type}
											</h3>
											{thisAssessment?.type === "Leader" &&
												<h3 className=' mb-0 '>
													Leader Name:{' '}
													{getLeaderName()}
												</h3>}
											<h3 className=' mb-0 '>
												Description:{' '}
												{
													thisAssessment?.description}
											</h3>
										</Col>
										<Col>
											<h3 className=' mb-0 '>
												Version:{' '}
												{
													thisAssessment?.version}
											</h3>
											<h3 className=' mb-0 '>
											</h3>
											<h3 className=' mb-0 '>
												Start Date:{" "}
												{thisProject?.start_date}
											</h3>
											<h3 className=' mb-0 '>
											</h3>
											<h3 className=' mb-0 '>
												End Date:{" "}
												{thisProject?.end_date}
											</h3>
											<h3 className=' mb-0 '>
											</h3>
										</Col>
									</Row>
									<div className='mb-0' style={{ display: "flex", justifyContent: "space-between" }} >
										<h3 className='ml-3 mt-4 mb-2'>Raters</h3>
										{((invitationsThatCanBeResent?.length ?? 0) > 0) && (<Button className='mb-2 mt-2'
											type='button'
											color='default'
											size='md'
											onClick={async e => {
												this.handleResendAllInvitations(thisAssessment.id, projectId, thisClient, thisProject, thisConsultant);
											}}
										>
											<i style={{ pointerEvents: 'none', paddingRight: "6px" }} className='fas fa-paper-plane ' />
											Resend All
										</Button>)}
									</div>

									<Table
										className='align-items-center  table-flush '
										responsive
									>
										<thead className='thead-light'>
											<tr>
												{/* <th scope="col"> Rater Id</th> */}
												<th scope='col'>First Name</th>
												<th scope='col'>Last Name</th>
												<th scope='col'>Email</th>
												<th scope='col' >Type</th>
												<th scope='col'>Status</th>
												<th scope='col'>Actions</th>
											</tr>
										</thead>

										<tbody>
											{assessmentInvitations?.map((inv, id) => {
												return (
													<tr style={{ background: `${inv?.type === "Owner" ? "#13CCEFAF" : ""}` }} key={inv.id}>
														<td>
															{this.getRater(inv.email)?.firstname ?? inv.firstname}
														</td>
														<td>
															{this.getRater(inv.email)?.lastname ?? inv.lastname}
														</td>
														<td>
															{inv.email}
														</td>
														<td>
															{inv.type ? inv.type : "Rater"}
														</td>
														<td className='text-capitalize'>

															{inv.status}
														</td>

														<td>
															{/* edit button */}
															{inv.type !== "Owner" && (
																<>
																	<Button
																		type="button"
																		onClick={(e) => {
																			e.preventDefault();


																			this.setState({
																				editInvitation: true,
																				invitationData: inv
																			})
																			this.inviteEditToggle(e);
																		}}
																		size="sm"
																		color="white"
																		id={`btnEdit${id}`}
																	>
																		<i className="ni ni-settings text-primary " />
																	</Button>
																	<UncontrolledTooltip
																		placement="top"
																		target={`btnEdit${id}`}
																	>
																		Edit Rater
																	</UncontrolledTooltip>
																</>
															)}
															{/* delete button */}
															<Button
																type="button"
																onClick={(e) => {
																	e.persist();
																	this.setState({
																		deleteInvitationId: inv.id,
																		deleteKey: inv.status !== "completed" ? "deleteInvited" : "deleteCompleted"
																	})
																	this.toggleDeleteRater()
																}}
																size="sm"
																consultantor="white"
																id={`btnDel${id}`}
															>
																<i className="fas fa-trash text-red " />
															</Button>
															<UncontrolledTooltip
																placement="top"
																target={`btnDel${id}`}
															>
																Delete Rater
															</UncontrolledTooltip>

															{/* resend email */}
															<Button
																size='sm'
																disabled={
																	inv.status ===
																	'completed'
																}
																data-disabled={
																	inv.status ===
																	'completed'
																}
																color={
																	inv.status !==
																		'completed'
																		? 'white'
																		: 'default'
																}
																title={
																	'Resend Email'
																}
																id={`btnResend${id}`}
																onClick={async e => {
																	e.preventDefault();
																	this.setState({
																		resendEmailData: inv
																	})
																	let disabled =
																		e.target.getAttribute(
																			'data-disabled'
																		);
																	if (
																		disabled === "false"
																	) {
																		this.toggleResend();
																	}

																}}
															>
																<i
																	style={{
																		pointerEvents:
																			'none',
																	}}
																	className='fas fa-paper-plane  '
																/>
															</Button>
															<UncontrolledTooltip
																placement="top"
																target={`btnResend${id}`}
															>
																Resend Email
															</UncontrolledTooltip>
															{/* reset answers */}
															<Button
																size='sm'
																color='danger'

																disabled={
																	inv.status !=
																	'completed'
																}
																title={
																	'Reset Answers'
																}

																onClick={e => {
																	e.preventDefault();
																	this.setState({
																		resetInvitationEmail: inv.email
																	})
																	this.toggleResetAnswers()
																}}
																id={`btnReset${id}`}
															>
																<i className='far fa-hourglass' />
															</Button>
															<UncontrolledTooltip
																placement="top"
																target={`btnReset${id}`}
															>
																Reset Answers
															</UncontrolledTooltip>
															{/* edit Answer */}
															{inv.status === "completed" && (
																<>
																	<Button
																		size='sm'
																		color='default'
																		id={`btnEditAnswer${id}`}
																		onClick={() => {
																			this.setState({
																				editRaterAnswerInfo: inv
																			})
																			this.toggleEditRaterAnswer()
																		}}
																	>
																		<BsPencilFill />
																	</Button>
																	<UncontrolledTooltip
																		placement="top"
																		target={`btnEditAnswer${id}`}
																	>
																		Edit Answers
																	</UncontrolledTooltip>
																</>

															)}

														</td>

													</tr>

												);
											})}
										</tbody>
										{this.state.showAlert && (
											<AlertDialog
												showId={this.state.showAlertKey}
												toggle={this.toggleShowAlert}
												showAlert={this.state.showAlert}
											/>
										)}
										{this.state.deleteRater && (
											<DeleteDialog
												toggle={this.toggleDeleteRater}
												deleteKey={this.state.deleteKey}
												deleteData={this.state.deleteRater}
												onDelete={async () => {
													await this.props.deleteInvitation(this.state.deleteInvitationId);
												}}
											/>
										)}
										{this.state.isResetAnswers && (
											<ResetAnswerDialog
												toggle={this.toggleResetAnswers}
												isReset={this.state.isResetAnswers}
												onReset={async () => {
													await this.props.resetRater(this.state.resetInvitationEmail, projectId, clientId, assessmentId)
													this.setState({
														showAlert: true,
														showAlertKey: "resetAnswers"
													})
												}}
											/>
										)}
										{this.state.isEditRaterAnswers && (
											<EditRaterAnswerDialog
												toggle={this.toggleEditRaterAnswer}
												isEditAnswer={this.state.isEditRaterAnswers}
												editRaterAnswerInfo={this.state.editRaterAnswerInfo}
												onEditRaterAnswer={async () => {
													await this.props.editRaterAnswers(this.state.editRaterAnswerInfo.email)
													this.setState({
														showAlert: true,
														showAlertKey: "editRaterAnswer"
													})
												}}

											/>
										)}
										{this.state.resend && (
											<Modal
												isOpen={
													this
														.state
														.resend
												}
												toggle={
													this
														.toggleResend
												}
												size='sm'


											>
												<ModalHeader
													toggle={
														this
															.toggleResend
													}
												>
													Resend Email
												</ModalHeader>
												<ModalBody>

													{`Are you sure you want to resend invitation to ${resendData.firstname ?? this.getRater(resendData?.email).firstname} ${resendData.lastname ?? this.getRater(resendData?.email).lastname} ?`}
												</ModalBody>
												<ModalFooter>
													<Button
														color='default'
														size='md'
														onClick={() => {
															let email = this.state.resendEmailData.email;
															let firstname = this.state.resendEmailData.firstname ?? this.getRater(resendData?.email).firstname;
															let lastname = this.state.resendEmailData.lastname ?? this.getRater(resendData?.email).lastname;
															let lang = this.state.resendEmailData.language ?? "english";
															let position = this.state.resendEmailData.position ?? "";
															let relationship = this.state.resendEmailData.relationship ?? ""
															this.sendInvitaion(
																email,
																firstname,
																lastname,
																lang,
																template,
																thisConsultant,
																thisClient,
																thisProject,
																thisAssessment,
																position,
																relationship

															);
															this.setState({
																alertInvitation: true,
																showAlert: true,
																showAlertKey: 'invitationSent',

															})
															this.toggleResend();
														}}
													>
														Yes
													</Button>
													<Button
														color=''
														size='md'
														onClick={this.toggleResend}
													>
														No
													</Button>

												</ModalFooter>
											</Modal>
										)}
									</Table>
								</CardBody>

							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}



const mapStateToProps = state => {
	return {
		isLoading: state.assessment.isLoading,
		uid: state.firebase.auth.uid,
		authError: state.auth_consultant.authError,
		clients: state.firestore.ordered.clients,
		rawClients: state.firestore.data.clients,
		consultants: state.firestore.ordered.consultants,
		invitations: state.firestore.ordered.invitations,
		assessments: state.firestore.ordered.assessments,
		components: state.firestore.ordered.components,
		raters: state.firestore.ordered.raters,
		templates: state.firestore.ordered.templates,
		rawTemplates: state.firestore.data.templates,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		invite: (
			invitationObject
		) =>

			dispatch(
				invite(
					invitationObject

				)
			),

		resetRater: (email, projectId, clientId, assessmentId) => dispatch(resetRater(email, projectId, clientId, assessmentId)),
		deleteInvitation: (invitationId) => dispatch(deleteInvitation(invitationId)),
		editInvitationAction: (invitation) => dispatch(editInvitationAction(invitation)),
		updateRaters: (rater, raterId) => dispatch(updateRaters(rater, raterId)),
		editRaterAnswers: email => dispatch(editRaterAnswers(email)),


	};
};

export default compose(
	firestoreConnect([
		{ collection: 'clients' },
		{ collection: 'consultants' },
		{ collection: 'invitations' },
		{ collection: 'assessments' },
		{ collection: 'components' },
		{ collection: 'raters' },
		{ collection: 'templates' },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(viewAssessment);
