import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';


const EditRaterAnswerDialog = ({ toggle, isEditAnswer, onEditRaterAnswer, editRaterAnswerInfo }) => {

  return (
    <Modal
      isOpen={isEditAnswer}
      toggle={toggle}
      size='sm'

    >
      <ModalHeader
        toggle={toggle}
      >
        Confirm Edit Answers
      </ModalHeader>
      <ModalBody>
        Are you sure you want to allow {editRaterAnswerInfo?.email} to edit answers? This action will allow {`${editRaterAnswerInfo?.firstname} ${editRaterAnswerInfo?.lastname}`} to resumbit Answers.
      </ModalBody>
      <ModalFooter>
        <Button
          color='default'
          size='md'
          onClick={async () => {
            await onEditRaterAnswer()

            toggle()
          }}
        >
          Yes
        </Button>
        <Button
          color=''
          size='md'
          onClick={toggle}
        >
          No
        </Button>

      </ModalFooter>
    </Modal>
  )
}

export default EditRaterAnswerDialog
