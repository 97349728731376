import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';


const ResetAnswerDialog = ({ toggle, isReset, onReset }) => {

  return (
    <Modal
      isOpen={isReset}
      toggle={toggle}
      size='sm'

    >
      <ModalHeader
        toggle={toggle}
      >
        Confirm Reset Answers
      </ModalHeader>
      <ModalBody>
        Are you sure you want to reset answers ?
      </ModalBody>
      <ModalFooter>
        <Button
          color='default'
          size='md'
          onClick={async () => {
            await onReset()

            toggle()
          }}
        >
          Yes
        </Button>
        <Button
          color=''
          size='md'
          onClick={toggle}
        >
          No
        </Button>

      </ModalFooter>
    </Modal>
  )
}

export default ResetAnswerDialog
