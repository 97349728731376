import React, { useEffect, useState } from 'react'
import Header from 'components/Headers/Header';
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { PDFViewer, View, Text, PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
import { Progress, Row, Card, CardHeader, Container, Col, CardBody, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Table } from "antd"
import { Link } from 'react-router-dom';
import PdfDocument, { getFreeTextAnswers, getOwnerProgress, getRatersProgress, getRaterViewData, getLeaderViewData, getRelationshipTableData } from 'components/PdfDocument';
import { ExportToCsv } from 'export-to-csv';

import { getPdfData } from 'components/PdfDocument';
import FreeTextPdf from 'components/FreeTextPdf';


import moment from 'moment';
import { addPdfLog } from 'actions/clientActions';




const NewAssessmentReport = (props) => {
  let assessmentId = props.match.params.id;
  const { clientId, projectId } = props.match.params
  const [assessment, setAssessment] = useState()
  const [invitations, setInvitations] = useState()
  const [showPdf, setShowPdf] = useState(false)
  const [tableData, setTableData] = useState(null)




  useEffect(() => {
    const assessment = props.assessments?.find((ass) => {
      return ass.id === assessmentId
    })
    setAssessment(assessment)

    const validInvitations = props.invitations?.filter((inv) => {
      return inv.assessmentId === assessmentId && inv.clientId === clientId && inv.projectId === projectId
    })
    setInvitations(validInvitations)

  }, [props.assessments, props.invitations])

  useEffect(() => {
    const filteredAssessment = assessment?.questions?.filter((que) => que.type !== "SECTION")
    setTableData(filteredAssessment)

  }, [assessment])





  const storeReportDownloadLogs = () => {
    let logData = {
      uid: props.uid,
      leaderName: `${getLeader()?.firstName} ${getLeader()?.lastName}`,
      assessmentName: assessment?.title,
      assessmentId: assessment?.id,
      projectId: projectId,
      clientId: clientId,
      invitations: invitations?.length,
      completedRaters: invitations?.filter((inv) => inv.status === "completed").length,
      exportedAt: moment().format('MMMM Do YYYY, h:mm:ss a'),
      exportedBy: `${props.auth.displayName}`,
      type: "Single Report"
    }
    props.addPdfLog(logData)
  }


  const showPdfToggle = () => {
    return setShowPdf(!showPdf)
  }

  const getDimension = () => {
    return tableData?.map((data) => {
      return data?.dimension
    })
  }

  const getCompetency = () => {
    return tableData?.map((data) => {
      return data?.competency
    })
  }
  const getCategory = () => {
    return tableData?.map((data) => {
      return data?.category
    })
  }



  const getLeader = () => {
    if (!props.clients) return null
    const client = props.clients.find((c) => c.id === clientId);
    if (!client) return null
    const project = client.projects.find((p) => p.id === projectId)
    if (!project) return null;
    const leader = project.owners && project.owners[assessment?.id]
    return leader
  }
  const exportCSVReport = () => {
    const exportData = invitations.filter((inv) => inv.status === "completed").map((i, index) => {
      const exportRow = {
        "Assessment": assessment.title,
        "Rater": `Rater ${index + 1}`,
        "Position": i.position,

      }
      assessment.questions.filter((q) => q.type === "SCALE").forEach((question) => {
        const questionKey = `${question.dimension}-${question.category}-${question.competency}`
        exportRow[questionKey] = i.answers[question.id] ?? "-"
      });
      return exportRow
    })

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Excel Report',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `${getLeader()?.firstName}_${getLeader()?.lastName}_${assessment?.title}`
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(exportData);
  }


  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9" fluid>
        <Row>
          <Col className="text-right">

          </Col>
        </Row>
        {/* table */}
        <Row>
          <div className="col" >
            <Card className=" shadow">
              <CardHeader className=" border-0">
                <div style={{ display: 'flex' }}>
                  <Link to={"/admin/reporting"} >
                    <i
                      className='fas fa-long-arrow-alt-left '
                      style={{ fontSize: '25px' }}
                    />
                  </Link>
                  <h3 className='ml-3  '>Report</h3>
                </div>
                {getLeader() && (
                  <h4> Leader Name: {getLeader()?.firstName} {getLeader()?.lastName} </h4>

                )}

              </CardHeader>
              <CardBody>
                <Row className="mb-2">
                  <Col className="text-right " >
                    {assessment && invitations && <PDFDownloadLink
                      fileName={`${getLeader()?.firstName}_${getLeader()?.lastName}_${assessment?.title}`}
                      document={<PdfDocument pdfData={getPdfData(assessment, invitations, getLeader())}
                        pdfRaterViewData={getRaterViewData(assessment, invitations)}
                        pdfLeaderViewData={getLeaderViewData(assessment, invitations)}
                        pdfRelationshipData={getRelationshipTableData(assessment, invitations)}
                      />} >
                      <Button style={{ marginRight: "10px" }} color="default" onClick={() => storeReportDownloadLogs()} size="sm">
                        Export Full Report
                      </Button>
                    </PDFDownloadLink>}
                    {assessment && invitations &&
                      <Button style={{ marginRight: "10px" }} color="default" onClick={() => exportCSVReport()} size="sm">
                        Export Report In CSV
                      </Button>}

                    {assessment && invitations && <PDFDownloadLink
                      fileName={`${getLeader()?.firstName}_${getLeader()?.lastName}_${assessment?.title} - Text only`}
                      document={<FreeTextPdf pdfData={getPdfData(assessment, invitations, getLeader())}
                      />} >
                      <Button color="default" onClick={() => storeReportDownloadLogs()} size="sm">
                        Export Free Text Report
                      </Button>
                    </PDFDownloadLink>}

                    {/* <Button color="default" size="sm" onClick={() => {
                      setShowPdf(true)
                      // storeReportDownloadLogs()

                    }} >
                      Export Full Report
                    </Button> */}

                  </Col>
                </Row>
                {/* table starts here */}

                <Table
                  scroll={{ x: "max-content" }}
                  size="small"
                  dataSource={tableData}

                >
                  <Table.Column
                    title="Dimension"
                    width="90px"
                    filters={
                      [...new Set(getDimension())]?.map((i) => ({
                        text: i,
                        value: i,
                      }))
                    }
                    onFilter={(value, record) => record?.dimension === value}
                    sorter={(a, b) => a.dimension.localeCompare(b.dimension)}
                    render={(report) => report.dimension || "---"}
                  />
                  <Table.Column
                    title="Competency"
                    width="90px"
                    filters={
                      [...new Set(getCompetency())]?.map((i) => ({
                        text: i,
                        value: i,
                      }))
                    }
                    onFilter={(value, record) => record?.competency === value}
                    sorter={(a, b) => a.competency.localeCompare(b.competency)}
                    render={(report) => report.competency || "---"}
                  />
                  <Table.Column
                    title="Category"
                    width="90px"
                    filters={
                      [...new Set(getCategory())].map((i) => ({
                        text: i,
                        value: i,
                      }))
                    }
                    onFilter={(value, record) => record?.category === value}
                    sorter={(a, b) => a.category.localeCompare(b.category)}
                    render={(report) => report.category || "---"}
                  />
                  <Table.Column
                    title="Progress"
                    width="200px"
                    filters={["FREE_TEXT", "SCALE"].map((i) => ({
                      text: i,
                      value: i,
                    }))}
                    onFilter={(value, record) => record?.type === value}
                    render={(report) => (
                      report.type === "SCALE" ?
                        <>
                          <Progress
                            color="success"
                            value={getRatersProgress(invitations, report.id)}
                            style={{ height: "13px", marginBottom: "5px", width: "100%" }}
                          >
                            Raters

                          </Progress>
                          {getOwnerProgress(invitations, report.id) &&
                            <Progress style={{ height: "13px", width: "100%" }}
                              color="danger"
                              value={getOwnerProgress(invitations, report.id)}
                            >
                              Owners
                            </Progress>

                          }
                        </>
                        :
                        <div style={{ overflowX: "hidden" }}>
                          {getFreeTextAnswers(invitations, report.id)?.join(", ")}
                        </div>

                    )}
                  />
                </Table>

                {/* table ends here */}
              </CardBody>
              {/* {showPdf && assessment && (
                <Modal isOpen={showPdf} toggle={showPdfToggle} >
                  <PDFViewer style={{ width: "900px", height: "800px" }} >
                    <PdfDocument pdfData={getPdfData(assessment, invitations, getLeader())}
                      pdfRaterViewData={getRaterViewData(assessment, invitations)}
                      pdfLeaderViewData={getLeaderViewData(assessment, invitations)}
                      pdfRelationshipData={getRelationshipTableData(assessment, invitations)}

                    />
                  </PDFViewer>
                </Modal>
              )} */}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}





const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    auth: state.firebase.auth,
    invitations: state.firestore.ordered.invitations,
    assessments: state.firestore.ordered.assessments,
    clients: state.firestore.ordered.clients
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addPdfLog: logDataObject => dispatch(addPdfLog(logDataObject))
  };
};

export default compose(
  firestoreConnect([
    { collection: "clients" },
    { collection: "consultants" },
    { collection: "assessments" },
    { collection: "invitations" },
  ]),
  connect(mapStateToProps, mapDispatchToProps)
)(NewAssessmentReport);