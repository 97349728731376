import authReducer from "./authReducer";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { combineReducers } from "redux";
import { assessmentReducer } from "./assessmentReducer";

const rootReducer = combineReducers({
	auth_consultant: authReducer,
	firebase: firebaseReducer,
	firestore: firestoreReducer,
	assessment: assessmentReducer,
});
export default rootReducer;
