import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';


const DeleteDialog = ({ id, toggle, deleteData, onDelete, deleteKey }) => {
  const [deleteContent, setDeleteContent] = useState("")

  useEffect(() => {
    if (deleteKey === "deleteInvited") {
      setDeleteContent("This rater has not rated assessment yet.")
    } else if (deleteKey === "deleteCompleted") {
      setDeleteContent("Data entered by the rater on this project will also be deleted.")
    } else if (deleteKey === "deleteAssessment") {
      setDeleteContent("This assessment will be deleted.")
    } else if (deleteKey === "deleteProject") {
      setDeleteContent("This project will be deleted.")
    } else if (deleteKey === "deleteClient") {
      setDeleteContent("This client will be deleted.")
    } else if (deleteKey === "deleteGroupReport") {
      setDeleteContent("This Group report will be deleted permanently.")
    }


  }, [deleteData])
  return (
    <Modal
      isOpen={deleteData}
      toggle={toggle}
      size='sm'

    >
      <ModalHeader
        toggle={toggle}
      >
        Confirm Delete
      </ModalHeader>
      <ModalBody>
        {deleteContent} Are you sure you want to delete ?
      </ModalBody>
      <ModalFooter>
        <Button
          color='default'
          size='md'
          onClick={async () => {
            await onDelete()
            toggle()
          }}
        >
          Yes
        </Button>
        <Button
          color=''
          size='md'
          onClick={toggle}
        >
          No
        </Button>

      </ModalFooter>
    </Modal>
  )
}

export default DeleteDialog
