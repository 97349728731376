import { addProjects, deleteProjects } from 'actions/clientActions';
import AddProjectForm from 'components/AddProjectForm';
import DeleteDialog from 'components/DeleteDialog';
import Header from 'components/Headers/Header';
import React, { Component } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
// reactstrap components
import { convertToRaw } from 'draft-js';
import draftjstohtml from 'draftjs-to-html';
import {
	Button, Card, CardBody, CardHeader, Col, Container,
	Row, Table, UncontrolledTooltip
} from 'reactstrap';
import { compose } from 'redux';
import { timezones } from 'variables/constants';
class viewClient extends Component {
	state = {
		Question: false,
		assessmentList: [],
		projects: [],
		redirect: false,
		isDeleteProject: false,
		deleteKey: '',
		deleteProjectData: {},
		isOpenAddProjectForm: false,
		possibleVariables: [
			'*|RaterFirstname|*',
			'*|RaterLastname|*',
			'*|RaterEmail|*',
			'*|ConsultantName|*',
			'*|ConsultantCompany|*',
			'*|ClientName|*',
			'*|ProjectName|*',
			'*|ProjectStartDate|*',
			'*|ProjectEndDate|*',
			'*|AssessmentName|*',
			'*|LeaderName|*',
			'*|LeaderEmail|*',
			'*|RaterRole|*',
			'*|RaterRelationship|*',
		],
	};
	onEditorStateChange = editorState => {
		let { template } = JSON.parse(JSON.stringify(this.state));
		template[this.state.template_lang].message = draftjstohtml(
			convertToRaw(editorState.getCurrentContent())
		);
		this.setState({
			editorState,
			template,
		});
	};
	getEditorState = lang => {
		// let {template} = this.state;
		// let message = template[lang].message
		// const blocksFromHtml = htmlToDraft(message);
		// const { contentBlocks, entityMap } = blocksFromHtml;
		// const contentState = ContentState.createFromBlockArray(
		// 	contentBlocks,
		// 	entityMap
		// );
		// const editorState = EditorState.createWithContent(contentState);
		let { editorState } = this.state;
		return editorState;
	};
	deleteProject = project => {
		this.props.deleteProject(project, this.props.match.params.id);
	};
	toggleAssessmentList = value => {
		var all = this.state.assessmentList;

		if (!all.includes(value)) {
			all.push(value);
		} else {
			all = all.filter(a => a !== value);
		}
		this.setState({ assessmentList: all });
	};
	getTimezone(val) {
		return timezones.find(item => item.value === val);
	}
	toggleDeleteProject = () => {
		this.setState({
			isDeleteProject: !this.state.isDeleteProject,
		})
	}
	toggleAddProjectForm = () => {
		this.setState({
			isOpenAddProjectForm: !this.state.isOpenAddProjectForm,
		})
	}
	addProject = e => {
		e.preventDefault();
		var projects = this.state.projects;
		var error = false;
		if (!e.target.name.value) {
			this.setState({ name_error: 'Please Enter a Name' });
			error = true;
		} else {
			this.setState({
				name_error: '',
				name: e.target.name.value,
			});
		}
		if (!e.target.start.value) {
			this.setState({ start_error: 'Please Enter a Starting date' });
			error = true;
		} else {
			this.setState({
				start_error: '',
				start: e.target.start.value,
			});
		}

		if (!e.target.end.value) {
			this.setState({ end_error: 'Please Enter a Ending Date' });
			error = true;
		} else {
			this.setState({
				end_error: '',
				end: e.target.end.value,
			});
		}
		if (!e.target.description.value) {
			this.setState({ description_error: 'Please Enter Project Description' });
			error = true;
		} else {
			this.setState({
				description_error: '',
				description: e.target.description.value,
			});
		}
		if (!e.target.time_zone.value === 'Time Zone') {
			this.setState({ time_zone_error: 'Please Select a Time Zone' });
			error = true;
		} else {
			this.setState({
				time_zone_error: '',
				time_zone: e.target.time_zone.value,
			});
		}
		let { template } = this.state;
		let keys = Object.keys(template);
		for (let key of keys) {
			let subject = template[key].subject;
			let message = template[key].message;
			let isFirstname = false;
			let isLastname = false;
			let subjectvariables = subject.match(/\*\|[a-zA-z0-9]+\|\*/g);
			let messagevariables = message.match(/\*\|[a-zA-z0-9]+\|\*/g);
			if (subjectvariables !== null) {
				let check0 = subjectvariables.includes('*|RaterFirstname|*');
				if (check0) isFirstname = true;
				let check1 = subjectvariables.includes('*|RaterLastname|*');
				if (check1) isLastname = true;
			}
			if (messagevariables !== null) {
				let check0 = messagevariables.includes('*|RaterFirstname|*');
				if (check0) isFirstname = true;
				let check1 = messagevariables.includes('*|RaterLastname|*');
				if (check1) isLastname = true;
			}
			if (!isFirstname || !isLastname) {
				error = true;
				this.setState({
					variable_error:
						'*|RaterFirstname|* and *|RaterLastname|* are manadatory.',
				});
			}
		}
		if (!error) {
			this.props.addProject(
				{
					name: e.target.name.value,
					start_date: e.target.start.value,
					end_date: e.target.end.value,
					time_zone: e.target.time_zone.value,
					status: 'Open',
					assessments: this.state.assessmentList,
					template: this.state.template,
					template_id: this.state.template_id,
					description: e.target.description.value
				},
				this.props.match.params.id
			);


			this.toggle(e);
		}
	};
	toggle = e => {
		e.preventDefault();
		this.setState({ Question: !this.state.Question, template: null });
	};
	render() {
		const { clients, assessments, consultants, uid } = this.props;
		const thisConsultant =
			consultants && consultants.find(con => con.id === uid);
		const clientId = this.props.match.params.id;
		const thisClient =
			clients && clients.filter(client => client.id === clientId)[0];


		return (
			<>
				<Header />
				{/* Page content */}
				<Container className='mt--9' fluid>
					{/* forms */}
					<Row>
						<div className='col'>
							<Card className=' shadow '>
								<CardHeader className=' border-0 '>
									<div style={{ display: 'flex' }}>
										<Link
											to='/admin/clients'
											className='text-default '
										>
											<i
												className='fas fa-long-arrow-alt-left '
												style={{ fontSize: '25px' }}
											/>
										</Link>
										<h3 className='ml-3 mb-0 '> Client</h3>
									</div>
								</CardHeader>

								<CardBody className=' mb-0 '>
									<Row className='mb-2'>
										<Col>
											<h3 className=' mb-0 '>
												Name :{' '}
												{thisClient && thisClient.name}
											</h3>
										</Col>
										<Col>
											<h3 className=' mb-0 '>
												Industry :{' '}
												{thisClient &&
													thisClient.industry}
											</h3>
										</Col>
									</Row>
									<Row>
										<Col className='text-right'>
											<Button
												className=' mb-2'
												color='default'
												href='#pablo'
												size='md'
												onClick={() => {
													this.setState({
														isOpenAddProjectForm: true
													})
												}}
											>
												Add Project
											</Button>

										</Col>
									</Row>
									<Table
										className='align-items-center  table-flush'
										responsive
									>
										<thead className='thead-light'>
											<tr>
												{/* <th scope="col">Project Id</th> */}
												<th scope='col'>
													Project Name
												</th>
												<th scope='col'>Start Date</th>
												<th scope='col'>End Date</th>
												<th scope='col'>Time Zone</th>
												<th scope='col'>Status</th>
												<th scope='col'>Actions</th>
											</tr>
										</thead>
										<tbody>
											{thisClient &&
												thisClient.projects.length > 0
												? thisClient.projects.map(
													(pro, id) => {
														return (
															<tr>
																<td>
																	<Link
																		style={{
																			padding:
																				'0.25rem 0.5rem',
																		}}
																		to={{
																			pathname:
																				'/admin/clients/' +
																				clientId +
																				'/viewProject/' +
																				pro.id,
																			state: {
																				client:
																					thisClient &&
																					thisClient.name,
																			},
																		}}
																	>
																		{
																			pro.name
																		}

																	</Link>
																</td>
																<td>{pro.start_date}</td>
																<td>

																	{
																		pro.end_date
																	}
																</td>
																<td>

																	{
																		this.getTimezone(
																			pro.time_zone
																		)
																			.name
																	}
																</td>
																<td className='text-capitalize'>
																	{String(
																		pro.status
																			? pro.status
																			: ''
																	).replace(
																		'_',
																		' '
																	)}
																</td>
																<td>
																	<Link
																		style={{
																			padding:
																				'0.25rem 0.5rem',
																		}}
																		to={
																			'/admin/clients/' +
																			clientId +
																			'/editProject/' +
																			pro.id
																		}
																		id={`btnSet${id}`}
																	>
																		<Button
																			size='sm'
																			color='white'
																		>
																			<i className='ni ni-settings  ' />
																		</Button>
																	</Link>
																	<UncontrolledTooltip
																		placement='top'
																		target={`btnSet${id}`}
																	>
																		Edit
																		Project
																	</UncontrolledTooltip>

																	<Button
																		onClick={e => {
																			e.preventDefault();
																			this.setState({
																				deleteProjectData: pro,
																				deleteKey: "deleteProject"
																			})
																			this.toggleDeleteProject()
																		}}

																		size='sm'
																		color='white'
																		id={`btnDel${id}`}
																	>
																		<i className='fas fa-trash text-red ' />
																	</Button>
																	<UncontrolledTooltip
																		placement='top'
																		target={`btnDel${id}`}
																	>
																		Delete
																		Project
																	</UncontrolledTooltip>
																	<Link
																		style={{
																			padding:
																				'0.25rem 0.5rem',
																		}}
																		to={{
																			pathname:
																				'/admin/clients/' +
																				clientId +
																				'/viewProject/' +
																				pro.id,
																			state: {
																				client:
																					thisClient &&
																					thisClient.name,
																			},
																		}}
																		id={`btnView${id}`}
																	>
																		<Button
																			size='sm'
																			color='white'
																		>
																			<i className='fas fa-eye  ' />
																		</Button>
																	</Link>
																	<UncontrolledTooltip
																		placement='top'
																		target={`btnView${id}`}
																	>
																		View
																		Project
																		Detail
																	</UncontrolledTooltip>
																</td>
															</tr>
														);
													}
												)
												: null}
											{this.state.isOpenAddProjectForm && (
												<AddProjectForm
													toggle={this.toggleAddProjectForm}
													isOpenForm={this.state.isOpenAddProjectForm}
													clientId={this.props.match.params.id}
													props={this.props}
												/>
											)}
											{this.state.isDeleteProject && (
												<DeleteDialog
													toggle={this.toggleDeleteProject}
													deleteKey={this.state.deleteKey}
													deleteData={this.state.isDeleteProject}
													onDelete={async () => {
														await this.props.deleteProject(this.state.deleteProjectData, this.props.match.params.id);
													}}
												/>
											)}
										</tbody>
									</Table>
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		authError: state.auth_consultant.authError,
		clients: state.firestore.ordered.clients,
		consultants: state.firestore.ordered.consultants,
		assessments: state.firestore.ordered.assessments,
		templates: state.firestore.ordered.templates,
		rawTemplates: state.firestore.data.templates,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		addProject: (project, clientId) =>
			dispatch(addProjects(project, clientId)),
		deleteProject: (project, clientId) =>
			dispatch(deleteProjects(project, clientId)),
		// editClient: (client, clientId) => dispatch(editClients(client, clientId)),
	};
};

export default compose(
	firestoreConnect([
		{ collection: 'clients' },
		{ collection: 'consultants' },
		{ collection: 'assessments' },
		{ collection: 'templates' },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(viewClient);
