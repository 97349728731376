import { getExportData } from 'actions/buyAssessment';
import { addPdfLog, fetchClients, addPdfGroupData, editPdfGroupData } from 'actions/clientActions';
import Header from 'components/Headers/Header';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactExport from 'react-export-excel';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link, useLocation } from 'react-router-dom';
import {
	Button, Card, CardBody, CardHeader, Col, Table, Container, FormGroup, Input, Label, Modal,
	ModalBody,
	ModalHeader, Row, Spinner, UncontrolledTooltip, Form
} from 'reactstrap';
import { compose } from 'redux';
import { PDFDownloadLink, PDFViewer, View, Text, Page, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import PdfDocument, { getPdfData, getLeaderViewData, getRaterViewData, getRelationshipTableData } from 'components/PdfDocument';
import GroupPdfDocument, { getGroupPdfData, getLeaderViewGroupData, getRaterViewGroupData } from 'components/GroupPdfDocument'
import FreeTextPdf from 'components/FreeTextPdf';
import Multiselect from 'multiselect-react-dropdown';
import { saveAs } from 'file-saver'

const Reporting = (props) => {
	const [clientId, setClientId] = useState('')
	//
	const [isEdit, setIsEdit] = useState(false)
	const [populateReportData, setPopulateReportData] = useState()
	const [selectedProjectValues, setSelectedProjectValues] = useState([]);
	const [selectedAssessmentsValues, setSelectedAssessmentsValues] = useState([]);
	const [clientData, setClientData] = useState()
	const [selectedClient, setSelectedClient] = useState()
	const [projectData, setProjectData] = useState()
	const [assessmentData, setAssessmentData] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [optionsInProjects, setOptionsInProjects] = useState()

	const [selectedInvitations, setAllSelectedInvitations] = useState([]);

	const { clients, invitations, assessments, uid } = props;


	const [groupReportData, setGroupReportData] = useState({
		groupTitle: null,
		uid: props.uid,
		assessmentNames: [],
		assessmentIds: [],
		projectIds: [],
		projectNames: [],
		clientId: clientId,
		createdAt: moment().format('MMMM Do YYYY, h:mm:ss a'),
		createdBy: `${props.displayName}`,
		excludeLeaders: false,
		type: "Group Report"
	})
	let data = useLocation();



	const getExportData = async (client_id, project_id, assessnent_id) => {
		await props.getExportData(client_id, project_id, assessnent_id);
		return props.exportData[assessnent_id];
	};



	// SETTING UP CLIENT DATA
	useEffect(() => {
		let id = JSON.parse(localStorage.getItem('auth_consultant'));
		props.fetchClients(id);
		let clientData;
		if (clients) {
			clientData = clients?.filter((client) => {
				return client.consultantId === uid
			})
		}
		setClientData(clientData)
	}, [clients])


	// SETTING UP PROJECT DATA
	useEffect(() => {
		let selectedClient;
		if (clientId && clientData) {
			selectedClient = clientData?.filter((client) => {
				return client.id === clientId
			})
		}
		setSelectedClient(selectedClient)
		const projectData = selectedClient?.[0]?.projects
		setProjectData(projectData)
	}, [clientId, clientData])


	// TODO: FIND ALL ASSESSMENTS OF ALL SELECTED PRPJECTS
	useEffect(() => {
		let allAssessmentIds = [];

		const selectedProjects = selectedProjectValues?.map((project) => {
			for (var id of project?.assessments) {
				allAssessmentIds.push(id)
			}
		})

		const filteredAssessments = assessments?.filter((ass) => {
			return allAssessmentIds.includes(ass?.id)
		})
		setAssessmentData(filteredAssessments)

	}, [clientId, assessments, selectedProjectValues])


	useEffect(() => {
		let selectedProjectIds = [];
		let selectedAssessmentIds = [];
		for (let project of selectedProjectValues) {
			selectedProjectIds.push(project?.id)
		}
		const assessmentsValue = selectedAssessmentsValues?.map((ass) => {
			selectedAssessmentIds.push(ass?.id)
		})
		const validInvitations = invitations?.filter((inv) => {
			return inv.clientId === clientId && inv.status === "completed" && selectedProjectIds.includes(inv.projectId) && selectedAssessmentIds.includes(inv.assessmentId)
		})
		setAllSelectedInvitations(validInvitations)

	}, [clientId, selectedProjectValues, invitations, selectedAssessmentsValues])


	// GETTING VALUES FOR GROUP REPORT
	useEffect(() => {
		let assessmentIds = [];
		let projectIds = [];
		let assessmentsNames = [];
		let projectNames = [];
		const projectsValue = selectedProjectValues?.map((proj) => {
			projectIds.push(proj?.id)
			projectNames.push(proj?.name)
		})
		const assessmentsValue = selectedAssessmentsValues?.map((ass) => {
			assessmentIds.push(ass?.id)
			assessmentsNames.push(ass?.title)
		})
		setGroupReportData({
			...groupReportData,
			projectNames: projectNames,
			assessmentIds: assessmentIds,
			assessmentNames: assessmentsNames,
			projectIds: projectIds,
		})

	}, [clientId, assessments, selectedProjectValues, selectedAssessmentsValues, selectedInvitations, clientData])


	useEffect(() => {
		if (projectData && selectedClient) {
			setOptionsInProjects([{ name: "Select All", value: "*" }, ...projectData])
		}

	}, [projectData, selectedClient])


	useEffect(() => {
		if (data?.state?.reportData && assessments && clients) {
			setIsEdit(true)

			const reportData = data?.state?.reportData
			setClientId(reportData?.clientId)

			// geting all values
			const clientData = clients?.find((client) => client.id === reportData?.clientId)
			const assessmentsData = assessments?.filter((ass) => reportData?.assessmentIds.includes(ass?.id))
			const includedProjectData = clientData?.projects?.filter((project) => reportData?.projectIds.includes(project?.id))


			setSelectedAssessmentsValues(assessmentsData)
			setSelectedProjectValues(includedProjectData)
			setSelectedClient(clientData)
			setGroupReportData({
				...reportData,
				groupTitle: reportData?.groupTitle,
				clientId: reportData?.clientId,
				excludeLeaders: reportData?.excludeLeaders,
			})

			setPopulateReportData(reportData)


		}

	}, [data?.state?.reportData, assessments, clients])







	const getValidInvitations = (assessmentId, projectId) => {

		const validInvitations = invitations?.filter((inv) => {
			return inv.assessmentId === assessmentId && inv.clientId === clientId && inv.projectId === projectId
		})
		return validInvitations

	}


	const getLeader = (assessmentId, projectId) => {
		if (!props.clients) return null
		const client = props.clients.find((c) => c.id === clientId);
		if (!client) return null
		const project = client.projects.find((p) => p.id === projectId)
		if (!project) return null;
		const leader = project.owners && project.owners[assessmentId]
		return leader

	}

	const getPdfLogData = (assessment, invitations, projectId) => {
		let logData = {
			uid: props.uid,
			leaderName: `${getLeader(assessment?.id, projectId)?.firstName} ${getLeader(assessment?.id, projectId)?.lastName}`,
			assessmentName: assessment.title,
			assessmentId: assessment.id,
			projectId: projectId,
			clientId: clientId,
			invitations: invitations?.filter((inv) => inv.assessmentId === assessment?.id && inv.projectId === projectId && inv.clientId === clientId).length,
			completedRaters: invitations?.filter((inv) => inv.assessmentId === assessment?.id && inv.projectId === projectId && inv.clientId === clientId && inv.status === "completed").length,
			exportedAt: moment().format('MMMM Do YYYY, h:mm:ss a'),
			exportedBy: `${props.displayName}`,
			type: "Single Report"

		}
		props.addPdfLog(logData)
		// return logData
	}



	// getselectedProjects
	const handleSelectMultipleProjects = (selectedList, selectedItem) => {
		if (selectedItem.value === "*") {
			setSelectedProjectValues(projectData)
		} else if (selectedItem.value !== "*" && selectedProjectValues?.length > 0) {
			setSelectedProjectValues(selectedList)

		} else {
			setSelectedProjectValues(selectedList)

		}
	}
	const handleRemoveProjects = (selectedList, selectedItem) => {

		setSelectedProjectValues(selectedList)


	}

	// getSelectedAssessments
	const handleSelectMultipleAssessments = (selectedList, selectedItem) => {
		setSelectedAssessmentsValues(selectedList)
	}

	const handleRemoveAssessments = (selectedList, selectedItem) => {
		setSelectedAssessmentsValues(selectedList)
	}

	// handling submitting form
	const handleSubmitGroupReport = async (event) => {
		setIsLoading(true)
		event.preventDefault();
		if (isEdit) {
			await props.editPdfGroupData(groupReportData)
			setIsLoading(false)
		} else {
			await props.addPdfGroupData(groupReportData)
			setIsLoading(false)
		}
		props.history.push("/admin/groupReports")

	}


	const exportFullReport = (ass, project) => {
		pdf(<PdfDocument pdfData={getPdfData(ass, getValidInvitations(ass?.id, project?.id), getLeader(ass?.id, project?.id))}
			pdfRaterViewData={getRaterViewData(ass, getValidInvitations(ass?.id, project?.id))}
			pdfLeaderViewData={getLeaderViewData(ass, getValidInvitations(ass?.id, project?.id))}
			pdfRelationshipData={getRelationshipTableData(ass, getValidInvitations(ass?.id, project?.id))}
		/>).toBlob().then((blob) => saveAs(blob, `${getLeader(ass?.id, project?.id)?.firstName}_${getLeader(ass?.id, project?.id)?.lastName}_${ass?.title}`))


	}

	const exportFreeTextReport = (ass, project) => {
		pdf(<FreeTextPdf pdfData={getPdfData(ass, getValidInvitations(ass?.id, project?.id), getLeader(ass?.id, project?.id))} />).toBlob().then((blob) => saveAs(blob, `${getLeader(ass?.id, project?.id)?.firstName}_${getLeader(ass?.id, project?.id)?.lastName}_${ass?.title} - Text only`))

	}




	return (

		<>
			<Header />
			{/* Page content */}
			<Container className='mt--9' fluid>

				<Row>
					<div className='col'>
						<Card className=' shadow'>
							<CardHeader className=' border-0'>
								<div style={{ display: "flex" }} >
									<Link to="/admin/groupReports"
									>
										<i
											className='fas fa-long-arrow-alt-left '
											style={{ fontSize: '25px' }}
										/>
									</Link>
									<h3 className=' mb-0 ml-3 '> {isEdit ? 'Edit' : 'Create'} Group Report</h3>
								</div>
							</CardHeader>
							<CardBody>
								{!clients ? (
									<div className='d-flex align-items-center justify-content-center py-5'>
										<Spinner />
									</div>
								) : (
									<>
										<Form onSubmit={handleSubmitGroupReport} >
											<FormGroup>
												<Label>Group Title</Label>
												<Input
													placeholder="Enter Group Title"
													name='groupTitle'
													type='text'
													onChange={(e) => {
														setGroupReportData({
															...groupReportData,
															groupTitle: e.target.value
														})
													}}
													value={groupReportData?.groupTitle ?? populateReportData?.groupTitle}
													required
												/>
											</FormGroup>
											<FormGroup>
												<Label>Clients</Label>
												<Multiselect
													options={clientData}
													singleSelect
													displayValue='name'
													showArrow
													placeholder='-- CLIENTS --'
													onSelect={(selectedList, selectedItem) => {
														setClientId(selectedItem?.id)
														setGroupReportData({
															...groupReportData,
															clientId: selectedItem?.id
														})
													}}

													selectedValues={selectedClient}
												/>
											</FormGroup>


											<FormGroup>

												<Label>Projects</Label>
												<Multiselect
													placeholder='-- PROJECTS -- '
													options={optionsInProjects}
													displayValue="name"
													showArrow
													onSelect={handleSelectMultipleProjects}
													onRemove={handleRemoveProjects}
													selectedValues={selectedProjectValues}
												/>


											</FormGroup>


											<FormGroup>
												<Label>Assessments</Label>
												<Multiselect
													placeholder='-- ASSESSMENTS -- '
													options={assessmentData}
													displayValue="title"
													showArrow
													onSelect={handleSelectMultipleAssessments}
													onRemove={handleRemoveAssessments}
													selectedValues={selectedAssessmentsValues}
												/>
											</FormGroup>
											<FormGroup check inline>
												<Input type="checkbox" name="excludeLeaders" checked={groupReportData?.excludeLeaders ?? populateReportData?.excludeLeaders} onChange={(e) => {
													setGroupReportData({
														...groupReportData,
														excludeLeaders: e.target.checked
													})
												}} />
												<Label check>
													Exclude Leaders
												</Label>
											</FormGroup>


											{clientId && selectedProjectValues?.length > 0 && selectedAssessmentsValues?.length > 0 && (

												<Row className='mt-2'>
													<Col className='text-right'>

														<Button
															color='default'
															size='md'
															type="submit"
															disabled={isLoading}
														>
															{isEdit ? "Update" : "Save"}	Report
														</Button>


													</Col>
												</Row>


											)}

										</Form>
										{clientId && selectedProjectValues?.length > 0 && selectedAssessmentsValues?.length > 0 &&
											(
												<>
													<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
														<Label>
															Projects Table
														</Label>


													</div>


													{/* old table starts here */}
													<Table
														className='align-items-center  table-flush '
														responsive
													>
														<thead className='thead-light'>
															<tr>
																<th scope='col'>
																	Title
																</th>
																<th scope='col'>
																	Type
																</th>
																<th scope='col'>
																	Project
																</th>
																<th scope='col'>
																	Start Date
																</th>
																<th scope='col'>
																	End Date
																</th>
																<th scope='col'>
																	Version
																</th>
																<th scope='col'>
																	Actions
																</th>
															</tr>
														</thead>
														<tbody>
															{selectedProjectValues?.map(project => {
																return selectedAssessmentsValues?.map(ass => {
																	return (
																		<tr  >
																			<td>
																				{ass?.title}
																			</td>
																			<td>
																				{ass?.type}
																			</td>
																			<td>
																				{project?.name}
																			</td>
																			<td>
																				{project?.start_date}
																			</td>
																			<td>
																				{project?.end_date}
																			</td>
																			<td>
																				{ass?.version}
																			</td>
																			<td>
																				<Link
																					style={{
																						padding:
																							'0.25rem 0.5rem',
																					}}
																					to={
																						'/admin/clients/' +
																						clientId +
																						'/viewProject/' +
																						project?.id +
																						'/viewAssessment/' +
																						ass?.id
																					}
																					title='View Assessment'
																					id={`btnView`}
																				>
																					<Button
																						size='sm'
																						color='white'
																					>
																						<i className='fas fa-eye  ' />
																					</Button>
																				</Link>
																				<UncontrolledTooltip
																					placement='top'
																					target={`btnView`}
																				>
																					View Assessment
																				</UncontrolledTooltip>

																				<Link
																					style={{
																						padding:
																							'0.25rem 0.5rem',
																					}}
																					to={{
																						pathname:
																							'/admin/clients/' +
																							clientId +
																							'/viewProject/' +
																							project?.id +
																							'/viewAssessment/' +
																							ass?.id +
																							'/viewReport',
																						state: ass?.id,
																					}}
																					title='View Report'
																					id={`btnViewReport`}
																				>
																					<Button
																						size='sm'
																						color='white'
																					>
																						<i className='fas fa-chart-bar  ' />
																					</Button>
																				</Link>
																				<UncontrolledTooltip
																					placement='top'
																					target={`btnViewReport`}
																				>
																					View Assessment Report
																				</UncontrolledTooltip>


																				<Button
																					size='sm'
																					color='white'
																					style={{
																						padding:
																							'0.25rem 0.5rem',
																					}}

																					title='Export Assessment Report'
																					id={`btnExport`}
																					onClick={() => {
																						getPdfLogData(ass, invitations, project?.id)
																						exportFullReport(ass, project)
																					}}
																				>
																					<i className='fas fa-download' />
																				</Button>

																				<UncontrolledTooltip
																					placement='top'
																					target={`btnExport`}
																				>
																					Export Full Report
																				</UncontrolledTooltip>


																				<Button
																					size='sm'
																					color='white'
																					style={{
																						padding:
																							'0.25rem 0.5rem',
																						marginLeft: "10px"
																					}}

																					title='Export Free Text Report'
																					id={`btnExportFreeText`}
																					onClick={() => {
																						getPdfLogData(ass, invitations, project?.id)
																						exportFreeTextReport(ass, project)
																					}}
																				>
																					<i className='fas fa-download' />
																				</Button>

																				<UncontrolledTooltip
																					placement='top'
																					target={`btnExportFreeText`}
																				>
																					Export Free Text Report
																				</UncontrolledTooltip>
																			</td>
																		</tr>
																	)
																})
															}

															)}
														</tbody>
													</Table>

													{/* old table ends here */}
												</>
											)}


									</>
								)}
							</CardBody>
							{/* {showPdf && (
								<Modal isOpen={showPdf} toggle={showPdfToggle} >
									<PDFViewer style={{ width: "900px", height: "800px" }} >
										<GroupPdfDocument
											groupPdfData={getGroupPdfData(selectedAssessmentsValues, selectedInvitations, selectedClient)}
											groupPdfRaterViewData={getRaterViewGroupData(selectedAssessmentsValues, selectedInvitations)}
											groupPdfLeaderViewData={getLeaderViewGroupData(selectedAssessmentsValues, selectedInvitations)}
										/>

									</PDFViewer>
								</Modal>
							)} */}



						</Card>
					</div>
				</Row>
			</Container>

		</>
	);

}

const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		displayName: state.firebase.auth.displayName,
		auth: state.auth_consultant,
		exportData: state.assessment.exportData,
		assessments: state.firestore.ordered.assessments,
		invitations: state.firestore.ordered.invitations,
		clients: state.firestore.ordered.clients,
		consultants: state.firestore.ordered.consultants,
		reportDownloadLogs: state.firestore.ordered.reportDownloadLogs
	};
};
const mapDispatchToProps = dispatch => {
	return {
		fetchClients: id => dispatch(fetchClients(id)),
		getExportData: (client_id, project_id, assessment_id) =>
			dispatch(getExportData(client_id, project_id, assessment_id)),
		addPdfLog: logDataObject => dispatch(addPdfLog(logDataObject)),
		addPdfGroupData: (groupData) => dispatch(addPdfGroupData(groupData)),
		editPdfGroupData: (newGroupPdfData) => dispatch(editPdfGroupData(newGroupPdfData)),

	};
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firestoreConnect([
		{ collection: 'assessments' },
		{ collection: 'invitations' },
		{ collection: "clients" },
		{ collection: "consultants" },
		{ collection: "reportDownloadLogs" }

	])
)(Reporting);


