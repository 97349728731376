import { addClients } from 'actions/clientActions';
import Header from 'components/Headers/Header';
import { convertToRaw } from 'draft-js';
import draftjstohtml from 'draftjs-to-html';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link, Redirect } from 'react-router-dom';
// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import { compose } from 'redux';
import { v4 as uuidv4 } from 'uuid';

class addClient extends Component {
	state = {
		Question: false,
		assessmentList: [],
		projects: [],
		redirect: false,
		possibleVariables: [
			'*|RaterFirstname|*',
			'*|RaterLastname|*',
			'*|RaterEmail|*',
			'*|ConsultantName|*',
			'*|ConsultantCompany|*',
			'*|ClientName|*',
			'*|ProjectName|*',
			'*|ProjectStartDate|*',
			'*|ProjectEndDate|*',
			'*|AssessmentName|*',
			'*|LeaderName|*',
			'*|LeaderEmail|*',
		],
	};
	onEditorStateChange = editorState => {
		let { template } = JSON.parse(JSON.stringify(this.state));
		template[this.state.template_lang].message = draftjstohtml(
			convertToRaw(editorState.getCurrentContent())
		);
		this.setState({
			editorState,
			template,
		});
	};
	getEditorState = lang => {
		// let {template} = this.state;
		// let message = template[lang].message
		// const blocksFromHtml = htmlToDraft(message);
		// const { contentBlocks, entityMap } = blocksFromHtml;
		// const contentState = ContentState.createFromBlockArray(
		// 	contentBlocks,
		// 	entityMap
		// );
		// const editorState = EditorState.createWithContent(contentState);
		let { editorState } = this.state;
		return editorState;
	};
	toggle = e => {
		e.preventDefault();
		this.setState({ Question: !this.state.Question, template: null });
	};
	toggleAssessmentList = value => {
		var all = this.state.assessmentList;

		if (!all.includes(value)) {
			all.push(value);
		} else {
			all = all.filter(a => a !== value);
		}
		this.setState({ assessmentList: all });
	};
	deleteProject = project => {
		var projects = this.state.projects;
		projects = projects.filter(pro => pro !== project);
		this.setState({ projects });
	};
	addProject = e => {
		e.preventDefault();
		var projects = this.state.projects;
		var error = false;
		if (!e.target.name.value) {
			this.setState({ name_error: 'Please Enter a Name' });
			error = true;
		} else {
			this.setState({
				name_error: '',
				name: e.target.name.value,
			});
		}
		if (!e.target.start.value) {
			this.setState({ start_error: 'Please Enter a Starting date' });
			error = true;
		} else {
			this.setState({
				start_error: '',
				start: e.target.start.value,
			});
		}
		if (!e.target.description.value) {
			this.setState({ description_error: 'Please Enter Project Description' });
			error = true;
		} else {
			this.setState({
				description_error: '',
				description: e.target.description.value,
			});
		}
		if (!e.target.end.value) {
			this.setState({ end_error: 'Please Enter a Ending Date' });
			error = true;
		} else {
			this.setState({
				end_error: '',
				end: e.target.end.value,
			});
		}
		if (e.target.time_zone.value === 'Time Zone') {
			this.setState({ time_zone_error: 'Please Select a Time Zone' });
			error = true;
		} else {
			this.setState({
				time_zone_error: '',
				time_zone: e.target.time_zone.value,
			});
		}
		let { template } = this.state;
		let keys = Object.keys(template);
		for (let key of keys) {
			let subject = template[key].subject;
			let message = template[key].message;
			let isFirstname = false;
			let isLastname = false;
			let subjectvariables = subject.match(/\*\|[a-zA-z0-9]+\|\*/g);
			let messagevariables = message.match(/\*\|[a-zA-z0-9]+\|\*/g);
			if (subjectvariables !== null) {
				isFirstname = subjectvariables.includes('*|RaterFirstname|*');
				isLastname = subjectvariables.includes('*|RaterLastname|*');
			}
			if (messagevariables !== null) {
				isFirstname = messagevariables.includes('*|RaterFirstname|*');
				isLastname = messagevariables.includes('*|RaterLastname|*');
			}
			if (!isFirstname || !isLastname) {
				error = true;
				this.setState({
					variable_error:
						'*|RaterFirstname|* and *|RaterLastname|* are manadatory.',
				});
			}
		}
		if (!error) {
			projects.push({
				id: uuidv4(),
				name: e.target.name.value,
				start_date: e.target.start.value,
				end_date: e.target.end.value,
				status: 'Open',
				time_zone: e.target.time_zone.value,
				assessments: this.state.assessmentList,
				template: this.state.template,
				template_id: this.state.template_id,
				description: e.target.description.value,
			});
			this.setState({ projects });
			this.toggle(e);
		}
	};


	addClient = e => {
		e.preventDefault();
		var error = false;
		if (!e.target.name.value) {
			this.setState({ client_name_error: 'Please Enter a Name' });
			error = true;
		} else {
			this.setState({
				client_name_error: '',
				client_name: e.target.name.value,
			});
		}

		if (e.target.industry.value === 'Industry') {
			this.setState({ industry_error: 'Please Enter a industry' });
			error = true;
		} else {
			this.setState({
				industry_error: '',
				industry: e.target.industry.value,
			});
		}
		if (!error) {
			this.props.addClient({
				name: e.target.name.value,
				industry: e.target.industry.value,
				projects: this.state.projects,
				consultantId: this.props.uid,
			});
			this.setState({ redirect: true });
		}
	};
	render() {
		const { components, assessments, consultants, uid } = this.props;
		const thisConsultant =
			consultants && consultants.find(con => con.id === uid);
		if (this.state.redirect) {
			return <Redirect to='/admin/clients' />;
		}
		return (
			<>
				<Header />
				{/* Page content */}
				<Container className='mt--9' fluid>
					{/* forms */}
					<Row>
						<div className='col'>
							<Card className=' shadow '>
								<CardHeader className=' border-0 '>
									<div style={{ display: 'flex' }}>
										<Link
											to='/admin/clients'
											className='text-default '
										>
											<i
												className='fas fa-long-arrow-alt-left '
												style={{ fontSize: '25px' }}
											/>
										</Link>
										<h3 className='ml-3 mb-0 '>
											Add Client
										</h3>
									</div>
								</CardHeader>
								<CardBody className=' mb-0 '>
									<Form onSubmit={this.addClient}>
										<Label>Client Name</Label>
										<Input
											name='name'
											valid={
												!this.state.client_name_error &&
												this.state.client_name
											}
											invalid={
												this.state.client_name_error
											}
										/>
										<FormFeedback>
											{this.state.client_name_error}
										</FormFeedback>
										<Label>Client Industry</Label>
										<Input
											name='industry'
											type='select'
											valid={
												!this.state.industry_error &&
												this.state.industry
											}
											invalid={this.state.industry_error}
										>
											<option hidden>Industry</option>
											{components &&
												components
													.filter(
														comp =>
															comp.id ===
															'client_industry'
													)[0]
													.industries.map(ind => {
														return (
															<option>
																{ind}
															</option>
														);
													})}
										</Input>

										<FormFeedback>
											{this.state.industry_error}
										</FormFeedback>
										<Row>
											<Col className='text-right'>
												<Button
													className='mt-2'
													type='submit'
													color='default'
													size='md'
												>
													Save Client
												</Button>
											</Col>
										</Row>
									</Form>

									{this.state.projects.length > 0
										? this.state.projects.map(pro => {
											return (
												<Row className='mt-4'>
													<Col xs={{ size: 8 }}>
														<h3 className=' mb-0 '>
															{pro.name}
														</h3>
														<p className='mb-0'>
															{pro.industry}
														</p>
													</Col>
													<Col
														xs={{ size: 4 }}
														className='text-right'
													>
														<Button
															className='mt-2'
															type='button'
															color='default'
															size='md'
															onClick={e => {
																e.preventDefault();
																this.deleteProject(
																	pro
																);
															}}
														>
															Delete
														</Button>
													</Col>
												</Row>
											);
										})
										: null}
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		authError: state.auth_consultant.authError,
		assessments: state.firestore.ordered.assessments,
		consultants: state.firestore.ordered.consultants,
		components: state.firestore.ordered.components,
		templates: state.firestore.ordered.templates,
		rawTemplates: state.firestore.data.templates,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		addClient: (client) => dispatch(addClients(client)),

	};
};

export default compose(
	firestoreConnect([
		{ collection: 'assessments' },
		{ collection: 'consultants' },
		{ collection: 'components' },
		{ collection: 'templates' },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(addClient);
