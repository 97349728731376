import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Clients from "views/examples/Clients.js";
import BuyAssessments from "views/examples/buyAssessments";
import Projects from "views/examples/Projects";
import viewClient from "views/examples/viewClient";
import editClient from "views/examples/editClient";
import addClient from "views/examples/addClient";
import editProject from "views/examples/editProject";
import viewProject from "views/examples/viewProject";
import viewAssessment from "views/examples/viewAssessment";
import AssessmentReport from "views/examples/AssessmentReport";
import NewAssessmentReport from "views/examples/NewAssessmentReport";
import AssessmentCheckout from "views/examples/AssessmentCheckout";
import Reporting from "views/examples/Reporting";
import GroupReportTable from "views/examples/GroupReportTable"

var routes = [
	{
		path: '/index',
		name: 'Dashboard',
		icon: 'ni ni-tv-2 text-primary',
		component: Index,
		layout: '/admin',
	},
	{
		path: '/editprofile',
		name: 'Edit Profile',
		icon: 'fas fa-user-edit text-blue',
		component: Profile,
		layout: '/admin',
	},
	{
		path: '/clients',
		name: 'Clients',
		icon: 'fas fa-users text-primary',
		component: Clients,
		layout: '/admin',
	},
	{
		path: '/clients/addClient',
		name: 'Add Clients',
		icon: 'ni ni-pin-3 text-orange',
		component: addClient,
		layout: '/admin',
		display: false,
	},
	{
		path: '/clients/viewClient/:id',
		name: 'View Clients',
		icon: 'ni ni-pin-3 text-orange',
		component: viewClient,
		layout: '/admin',
		display: false,
	},
	{
		path: '/clients/editClient/:id',
		name: 'Edit Clients',
		icon: 'ni ni-pin-3 text-orange',
		component: editClient,
		layout: '/admin',
		display: false,
	},
	{
		path: '/clients/:clientId/editProject/:id',
		name: 'Edit Project',
		icon: 'ni ni-pin-3 text-orange',
		component: editProject,
		layout: '/admin',
		display: false,
	},
	{
		path: '/clients/:clientId/viewProject/:id',
		name: 'View Project',
		icon: 'ni ni-pin-3 text-orange',
		component: viewProject,
		layout: '/admin',
		display: false,
	},
	{
		path: '/clients/:clientId/viewProject/:projectId/ViewAssessment/:id',
		name: 'View Assessment',
		icon: 'fa fa-tasks text-green',
		component: viewAssessment,
		layout: '/admin',
		display: false,
	},
	{
		path: '/clients/:clientId/viewProject/:projectId/ViewAssessment/:id/viewReport',
		name: 'View Report',
		icon: 'fa fa-tasks text-green',
		component: NewAssessmentReport,
		layout: '/admin',
		display: false,
	},
	{
		path: '/buyassessments/assessmentCheckout',
		name: 'View Assessment',
		icon: 'fa fa-tasks text-green',
		component: AssessmentCheckout,
		layout: '/admin',
		display: false,
	},
	{
		path: '/projects',
		name: 'Projects',
		icon: 'fas fa-bars text-primary',
		component: Projects,
		layout: '/admin',
	},
	{
		path: '/buyassessments',
		name: 'My Assessments',
		icon: 'fa fa-tasks text-primary',
		component: BuyAssessments,
		layout: '/admin',
	},
	{
		path: '/reporting',
		name: 'Reporting',
		icon: 'fa fa-edit text-primary',
		component: Reporting,
		layout: '/admin',
		display: false
	},
	{
		path: '/groupReports',
		name: 'Reports',
		icon: 'far  fa-chart-bar text-primary',
		component: GroupReportTable,
		layout: '/admin',
	},

];
export default routes;
