import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";

import Login from "views/examples/Login";
import { connect } from "react-redux";
import ForgetPassword from "views/examples/ForgetPassword";

class Auth extends React.Component {
	componentDidMount() {
		document.body.classList.add("bg-default");
	}
	componentWillUnmount() {
		document.body.classList.remove("bg-default");
	}
	getRoutes = routes => {
		return routes.map((prop, key) => {
			if (prop.layout === "/auth") {
				return (
					<Route
						exact
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};
	render() {
		let { uid } = this.props;

		if (uid) {
			return <Redirect to="/admin/" />;
		}
		return (
			<>
				<div className="main-content">
					<AuthNavbar />
					<div className="header bg-gradient-info py-7 py-lg-8">
						<Container>
							<div className="header-body text-center mb-7">
								<Row className="justify-content-center">
									<Col lg="5" md="6">
										<h1 className="text-white">
											Welcome to Integral Assessment!
											<br />
											<b>Consultants</b>
										</h1>
									</Col>
								</Row>
							</div>
						</Container>
						<div className="separator separator-bottom separator-skew zindex-100">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								preserveAspectRatio="none"
								version="1.1"
								viewBox="0 0 2560 100"
								x="0"
								y="0"
							>
								<polygon
									className="fill-default"
									points="2560 0 2560 100 0 100"
								/>
							</svg>
						</div>
					</div>
					{/* Page content */}
					<Container className="mt--8 pb-5">
						<Row className="justify-content-center">
							<Switch>
								<Route exact path="/auth/login" component={Login} />
								<Route
									exact
									path="/auth/forget-password"
									component={ForgetPassword}
								/>
								<Redirect from="*" to="/auth/login" />
							</Switch>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default connect(state => ({ uid: state.firebase.auth.uid }))(Auth);
