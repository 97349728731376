import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const AlertDialog = ({ showId, showAlert, toggle }) => {
  const [alertContent, setAlertContent] = useState({
    backgroundColor: "",
    content: ""
  })

  useEffect(() => {
    if (showId === "invitationSent") {
      setAlertContent({
        backgroundColor: "#6cc070",
        content: "Invitation sent to Rater!"
      })
    }
    else if (showId === "invitationSentOwner") {
      setAlertContent({
        backgroundColor: "#6cc070",
        content: "Invitation sent to Owner!"
      })
    } else if (showId === "resendAllInvitations") {
      setAlertContent({
        backgroundColor: "#6cc070",
        content: "Invitations sent to all!"
      })
    } else if (showId === "resetAnswers") {
      setAlertContent({
        backgroundColor: "#6cc070",
        content: "Answers are reset!"
      })
    } else if (showId === "editRaterAnswer") {
      setAlertContent({
        backgroundColor: "#6cc070",
        content: "Rater is now able to edit his/her answer!"
      })
    }
  }, [showAlert])


  return (
    <Modal
      isOpen={showAlert}
      toggle={toggle}
      size='sm'
    >
      <div style={{ backgroundColor: alertContent.backgroundColor }} >
        <ModalHeader toggle={toggle} >
          <p style={{ color: "white", margin: 0, padding: 0 }} > {alertContent.content} </p>
        </ModalHeader>
      </div>


    </Modal>
  )
}

export default AlertDialog
