import axios from 'axios';
import { getFirestore } from 'redux-firestore';
import { v4 as uuidv4 } from 'uuid';
export const addClients = (client) => {
	const { projects } = client
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		const ref = firestore.collection('clients').doc()
		const clientId = ref.id;
		await ref
			.set({
				...client,
				id: clientId,
				projects: projects.map((p) => {
					return {
						...p,
						clientId: clientId,
					}
				})
			})
			.then(data => {
				dispatch({
					type: 'ADD_CLIENT_SUCCESS',
				});
			})
			.catch(error => {
				dispatch({
					type: 'ADD_CLIENT_FAIL',
				});
			});
	};
};

export const addPdfLog = (pdfLog) => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		const ref = firestore.collection('reportDownloadLogs').doc()
		const pdfLogId = ref.id;
		await ref.set({
			id: pdfLogId,
			...pdfLog
		})
			.then(data => {
				console.log("success")
				dispatch({
					type: 'ADD_PDF_LOG_SUCCESS',
				});

			}).catch(error => {
				dispatch({
					type: 'ADD_PDF_LOG_FAIL',
				});
			})
	}
}

export const addPdfGroupData = (groupData) => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		const ref = firestore.collection("groupPdfData").doc()
		const groupPdfId = ref.id;
		await ref.set({
			id: groupPdfId,
			...groupData
		})
			.then(data => {
				console.log("success")
				dispatch({
					type: 'ADD_GROUP_PDF_SUCCESS',
				});

			}).catch(error => {
				dispatch({
					type: 'ADD_GROUP_PDF_FAIL',
				});
			})
	}
}


export const deletePdfGroupData = (groupPdfId) => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		await firestore.collection("groupPdfData")
			.doc(groupPdfId)
			.delete()
			.then((data) => {

				dispatch({
					type: "DELETE_GROUP_PDF_SUCCESS",
				});
			})
			.catch((error) => {
				dispatch({
					type: "DELETE_GROUP_PDF_FAIL",
				});
			})
	}
}

export const editPdfGroupData = (newGroupPdfData) => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		const ref = firestore.collection("groupPdfData").doc()
		await firestore.collection("groupPdfData")
			.doc(newGroupPdfData.id)
			.update(newGroupPdfData)
			.then((data) => {
				console.log("success")
				dispatch({
					type: "DELETE_GROUP_PDF_SUCCESS",
				});
			})
			.catch((error) => {
				dispatch({
					type: "DELETE_GROUP_PDF_FAIL",
				});
			})
	}
}




// export const migrateClients = () => {
// 	return async (dispatch, getState, { getFirebase, getFirestore }) => {
// 		const firestore = getFirestore();
// 		const clients = await firestore.collection('clients').get()
// 		await Promise.all(clients.docs.map(async (docSnap) => {
// 			let client = docSnap.data()
// 			client = {
// 				...client,
// 				id: docSnap.id,
// 				projects: client.projects.map((p) => {
// 					return {
// 						...p,
// 						clientId: docSnap.id,
// 					}
// 				})
// 			}
// 			await docSnap.ref.update(client)
// 		}))
// 	}
// }


// export const lowerCase = () => {
// 	return async (dispatch, getState, { getFirebase, getFirestore }) => {
// 		const firestore = getFirestore();
// 		const invitationsSnap = await firestore.collection("invitations").get();
// 		invitationsSnap.docs.forEach(async (doc) => {
// 			const invitation = doc.data()
// 			if (invitation.email !== invitation.email.toLowerCase()) {
// 				console.log(invitation.email)
// 				console.log(invitation.email.toLowerCase())
// 				// await doc.ref.update({
// 				//   email: invitation.email.toLowerCase()
// 				// })
// 			}
// 		})
// 	}
// }

// export const chnageSpelling = () => {
// 	return async (dispatch, getState, { getFirebase, getFirestore }) => {
// 		const firestore = getFirestore();
// 		const assessmentSnap = await firestore.collection("assessments").where("title", "==", "Leadership Maturity Scan").get();
// 		assessmentSnap.docs.forEach(async (doc) => {
// 			const questions = doc.data().questions;
// 			questions.forEach((que) => {
// 				if (que?.competency) {
// 					que.competency = que.competency.replace("Continues", "Continuous")

// 				}
// 			})
// 			await doc.ref.update({
// 				questions: questions
// 			})
// 		})
// 	}
// }





export const fetchClients = (id) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		firestore
			.collection('clients')
			.where('consultantId', '==', id)
			.onSnapshot(snapshot => {
				let data = [];
				for (let doc of snapshot.docs) {
					data.push({ id: doc.id, ...doc.data() });
				}
				dispatch({
					type: 'FETCH_CLIENTS',
					payload: data,
				});
			});
	};
};
export const editClients = (client, clientId) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		firestore
			.collection('clients')
			.doc(clientId)
			.update({
				name: client.name,
				industry: client.industry,
			})
			.then(data => {
				dispatch({
					type: 'ADD_CLIENT_SUCCESS',
				});
			})
			.catch(error => {
				dispatch({
					type: 'ADD_CLIENT_FAIL',
				});
			});
	};
};
export const deleteClient = client => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		firestore
			.collection('clients')
			.doc(client.id)
			.delete()
			.then(async data => {
				const invitationsSnap = await firestore.collection("invitations").where("clientId", "==", client.id).get()
				await Promise.all(invitationsSnap.docs.map((d) => d.ref.delete()))
				dispatch({
					type: 'DELETE_CLIENT_SUCCESS',
				});
			})
			.catch(error => {
				dispatch({
					type: 'DELETE_CLIENT_FAIL',
				});
			});
	};
};
export const addProjects = (project, clientId) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		firestore
			.collection('clients')
			.doc(clientId)
			.update({
				projects: firestore.FieldValue.arrayUnion({
					...project,
					clientId: clientId,
					id: uuidv4(),
				}),
			})
			.then(data => {
				dispatch({
					type: 'ADD_PROJECT_SUCCESS',
				});
			})
			.catch(error => {
				dispatch({
					type: 'ADD_PROJECT_FAIL',
				});
			});
	};
};
export const deleteProjects = (project, clientId) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		firestore
			.collection('clients')
			.doc(clientId)
			.update({
				projects: firestore.FieldValue.arrayRemove(project),
			})
			.then(async data => {
				const invitationsSnap = await firestore.collection("invitations").where("projectId", "==", project.id).get()
				await Promise.all(invitationsSnap.docs.map((d) => d.ref.delete()))
				dispatch({
					type: 'DELETE_PROJECT_SUCCESS',
				});
			})
			.catch(error => {
				dispatch({
					type: 'DELETE_PROJECT_FAIL',
				});
			});
	};
};


export const editProjects = (project, previousP, clientId) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		firestore
			.collection('clients')
			.doc(clientId)
			.update({
				projects: firestore.FieldValue.arrayRemove(previousP),
			})
			.then(data => {
				firestore
					.collection('clients')
					.doc(clientId)
					.update({
						projects: firestore.FieldValue.arrayUnion({
							...previousP,
							...project,
						}),
					});
			})

			.then(data => {
				dispatch({
					type: 'EDIT_PROJECT_SUCCESS',
				});
			})
			.catch(error => {
				dispatch({
					type: 'EDIT_PROJECT_FAIL',
				});
			});
	};
};
export const updateExistingTemplate =
	(clientId, projectId, template) =>
		async (dispatch, getState, { getFirebase, getFirestore }) => {
			let firestore = getFirestore();
			let client = await firestore.collection('clients').doc(clientId).get();
			let clientData = client.data();
			let { projects } = clientData;
			for (let project of projects) {
				if (project.id === projectId) {
					project['template'] = template;
				}
			}
			await firestore.collection('clients').doc(clientId).update(clientData);
		};
export const changeTemplate =
	(clientId, projectId, templateObj) =>
		async (dispatch, getState, { getFirebase, getFirestore }) => {
			let firestore = getFirestore();
			let client = await firestore.collection('clients').doc(clientId).get();
			let clientData = client.data();
			let { projects } = clientData;
			let idx = -1;
			for (let project of projects) {
				idx++;
				if (project.id === projectId) {
					project = { ...project, ...templateObj };
					break;
				}
			}
			clientData.projects[idx].template_id = templateObj.template_id;
			clientData.projects[idx].template = templateObj.template;
			await firestore.collection('clients').doc(clientId).update(clientData);
		};
export const fetchTemplates =
	() =>
		(dispatch, getState, { getFirebase, getFirestore }) => {
			let firestore = getFirestore();
			let templates = [];
			firestore.collection('templates').onSnapshot(snapshot => {
				snapshot.docs.forEach(doc => {
					templates.push({ id: doc.id, data: doc.data });
				});
				dispatch({
					type: 'FETCH_TEMPLATE',
					payload: templates,
				});
			});
		};

export const addAssessment = (
	// buyass,
	// assessment,
	// projectId,
	// clientId,
	// consultantId
	payloads
) => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();

		// This is to copy the object to deattach the reference

		var boughtAssesments = JSON.parse(JSON.stringify(payloads.buyAssessment));

		let boughtAssessmentIndex = boughtAssesments.findIndex(
			item => item.assessmentId === payloads.assessment
		);
		let boughtAssesment = boughtAssesments.find(
			item => item.assessmentId === payloads.assessment
		);
		boughtAssesment.quantity = boughtAssesment.quantity - 1;
		boughtAssesments[boughtAssessmentIndex] = boughtAssesment;

		boughtAssesments = boughtAssesments.filter(it => it.quantity !== 0);



		const client = (await firestore
			.collection('clients')
			.doc(payloads.clientId)
			.get()).data()

		const { projects } = client

		const project = projects.find(pro => pro.id === payloads.projectId);

		let projectIndex = projects.indexOf(project)


		if (!project.assessments.includes(payloads.assessment)) {
			if (!project.owners) {
				project.owners = {}
			}

			if (payloads.assessmentType === "Leader") {
				project.owners[payloads.assessment] = payloads.owners
			}

			project.assessments.push(payloads.assessment);

		}

		projects[projectIndex] = project;


		return firestore
			.collection('clients')
			.doc(payloads.clientId)
			.update({ projects: projects })
			.then(data => {

				dispatch({
					type: 'ADD_ASSESSMENT_SUCCESS',
				});
				return firestore
					.collection('consultants')
					.doc(payloads.consultantId)
					.update({
						buyAssessment: boughtAssesments,
					})
			})
			.catch(error => {
				dispatch({
					type: 'ADD_ASSESSMENT_FAIL',
				});
			});
	};
};

export const editAssessment = (assessmentId, owners, clientId, projectId) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore()
		firestore.collection('clients').doc(clientId).get().then((data) => {
			const projects = data.data()?.projects;
			let project = projects.find((pro) => pro.id === projectId)
			let index = projects.findIndex(pro => pro.id === projectId);
			project.owners[assessmentId] = owners
			projects[index] = project;
			firestore
				.collection('clients')
				.doc(clientId)
				.update({ projects: projects });
		})
	}
}

export const deleteAssessment = (assessment, projectId, clientId) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		firestore
			.collection('clients')
			.doc(clientId)
			.get()
			.then(async data => {
				var projects = data.data().projects;
				let obj = projects.find(pro => pro.id === projectId);
				let index = projects.findIndex(pro => pro.id === projectId);
				if (obj.assessments.includes(assessment)) {
					obj.assessments = obj.assessments.filter(
						ass => ass !== assessment
					);
					if (obj.owners) {
						delete obj.owners[assessment]
					}
				}
				projects[index] = obj;
				await firestore
					.collection('clients')
					.doc(clientId)
					.update({ projects: projects });
				const invitations = await firestore.collection("invitations").where("assessmentId", "==", assessment).where("projectId", "==", projectId).where("clientId", "==", clientId).get()
				await Promise.allSettled(invitations.docs.map((i) => i.ref.delete()))

			})

			.then(data => {
				dispatch({
					type: 'DELETE_ASSESSMENT_SUCCESS',
				});
			})
			.catch(error => {
				dispatch({
					type: 'DELETE_ASSESSMENT_FAIL',
				});
			});
	};
};



export const deleteInvitation = (invitationId) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		firestore
			.collection("invitations")
			.doc(invitationId)
			.delete()
			.then((data) => {
				dispatch({
					type: "DELETE_INVITATION_SUCCESS",
				});
			})
			.catch((error) => {
				dispatch({
					type: "DELETE_INVITATION_FAIL",
				});
			})
	}
}

export const editInvitationAction = (invitation) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		firestore
			.collection("invitations")
			.doc(invitation.id)
			.update(invitation)
			.then((data) => {
				dispatch({
					type: "EDIT_INVITATION_SUCCESS",
				});
			})
			.catch((error) => {
				dispatch({
					type: "EDIT_INVITATION_FAIL",
				});
			})
	}
}
export const updateRaters = (rater, raterId) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		firestore
			.collection("raters")
			.doc(raterId)
			.update(rater)
			.then((data) => {
				dispatch({
					type: "UPDATE_RATER_SUCCESS",
				});
			})
			.catch((error) => {
				dispatch({
					type: "UPDATE_RATER_FAIL",
				});
			})
	}
}


export const invite = (
	emailData
) => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		dispatch({
			type: 'SENDING_INVITATION',
			isLoading: true
		});
		const { lang, assessmentId, projectId, clientId, email, invitationBody, type, position, relationship } = emailData;
		const invitationSnap = await firestore.collection("invitations")
			.where("assessmentId", "==", assessmentId)
			.where("projectId", "==", projectId)
			.where("clientId", "==", clientId)
			.where("email", "==", email).get()
		if (invitationSnap.size === 0) {
			await firestore.collection('invitations').add({
				...invitationBody,
				assessmentId,
				projectId,
				clientId,
				email: email.toLowerCase(),
				lang,
				answers: {},
				questionIndex: 0,
				status: 'invited',
				position: position,
				relationship: relationship
			});
		}

		getFirebase().functions().httpsCallable("sendRaterInvitation")({
			email,
			lang,
			emailContent: emailData.emailContent,
			emailData: emailData.emailData,
			type,
			redirectUrl: process.env.REACT_APP_RATER_REDIRECT_URL,
			position,
			relationship
		}
		).then(data => {
			dispatch({
				type: 'INVITATION_SUCCESS',
				isLoading: false
			});
		}).catch(error => {
			dispatch({
				type: 'INVITATION_FAIL',
				isLoading: false

			});
		});

	};
};

export const reset = id => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		let firestore = getFirestore();
		let snapshotInvitaion = await firestore.collection('invitations').get();
		for (let doc of snapshotInvitaion.docs) {
			if (doc.data().assessmentId === id) {
				await firestore
					.collection('invitations')
					.doc(doc.id)
					.update({ answers: [] });
				if (doc.data().status === 'completed') {
					await firestore
						.collection('invitations')
						.doc(doc.id)
						.update({ status: 'accepted' });
				}
			}
		}
	};
};

export const resetRater = (email, projectId, clientId, assessmentId) => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		let firestore = getFirestore();
		let snapshotInvitaion = await firestore.collection('invitations').where("email", "==", email).where("projectId", "==", projectId).where("clientId", "==", clientId).where("assessmentId", "==", assessmentId).get();
		for (let doc of snapshotInvitaion.docs) {
			await firestore
				.collection('invitations')
				.doc(doc.id)
				.update({ answers: {}, questionIndex: 0, status: 'accepted' });

		}
	};
};

export const editRaterAnswers = email => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		let firestore = getFirestore();
		let snapshotInvitaion = await firestore.collection('invitations').get();
		for (let doc of snapshotInvitaion.docs) {
			if (doc.data().email === email) {
				await firestore
					.collection('invitations')
					.doc(doc.id)
					.update({ status: "accepted" })
			}
		}

	}
}
