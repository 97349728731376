import axios from 'axios';

export const loginConsultant = credentials => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		const firestore = getFirestore();
		dispatch({
			type: "IS_LOADING",
		});
		firebase
			.auth()
			.signInWithEmailAndPassword(credentials.email, credentials.password)
			.then(data => {
				return data.user.uid;
			})
			.then(data => {
				return firestore.collection("consultants").doc(data).get();
			})
			.then(data => {
				if (data.data() === null) {
					dispatch({
						type: "LOGIN_FAIL",
						id_type: "",
						uid: "",
						error: { message: "Your are not authorized to access this panel." },
					});
					dispatch({
						type: "IS_LOADED",
					});
				} else {
					if (data.data().status === "deactivated") {
						dispatch({
							type: "LOGIN_FAIL",
							id_type: "",
							uid: "",
							error: { message: "Your account have been deactivated by admin" },
						});
						dispatch({
							type: "IS_LOADED",
						});
					} else {
						dispatch({
							type: "LOGIN_SUCCESS",
							id_type: "consultant",
							uid: data.id,
							error: "",
						});
						dispatch({
							type: "IS_LOADED",
						});
					}
				}
			})
			.catch(error => {
				dispatch({
					type: "LOGIN_FAIL",
					id_type: "",
					uid: "",
					error: error,
				});
				dispatch({
					type: "IS_LOADED",
				});
			});
	};
};
export const emailLogin = (email) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		firebase.auth().signInWithEmailLink(email, window.location.href).then((data) => {
			dispatch({
				type: "LOGIN_SUCCESS",
				id_type: "consultant",
				uid: data.user.uid,
				error: "",
			});
			dispatch({
				type: "IS_LOADED",
			});
		}).catch((e) => {
			dispatch({
				type: "LOGIN_FAIL",
				id_type: "",
				uid: "",
				error: { message: "Invalid login email" },
			});
		})
	}
}

export const logout = () => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		firebase
			.auth()
			.signOut()
			.then(data => {
				localStorage.clear();
				dispatch({
					type: "LOGOUT_SUCCESS",
					id_type: "",
					uid: "",
					signup: false,
					error: "",
				});
			});
	};
};


export const resetPassword = (email) => {
	return (dispatch, getState, { getFirebase, getFirestore, getFunctions }) => {
		const link = window.location.origin;
		// Validate get functions

		getFirebase().functions().httpsCallable("sendForgotPasswordLink")({
			email,
			continueUrl: link,
		})
			.then((data) => {
				dispatch({
					type: "SEND_FORGOT_PASSWORD_LINK_SUCCESS",
				});
			})
			.catch((error) => {
				console.log(error)
				dispatch({
					type: "SEND_FORGOT_PASSWORD_LINK_FAIL",
				});
			});
	};
};