export const updateProfile = (profile, uid) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		const firebase = getFirebase();
		let user = firebase.auth().currentUser;
		user
			.updateEmail(profile.email)
			.then(() => {
				firestore
					.collection("consultants")
					.doc(uid)
					.update({
						...profile,
					})
					.then(data => {
						alert("Updated Profile");
						dispatch({
							type: "UPDATE_PROFILE_SUCCESS",
						});
					})
					.catch(error => {
						dispatch({
							type: "UPDATE_PROFILE_FAIL",
						});
					});
			})
			.catch(error => {
				dispatch({
					type: "UPDATE_PROFILE_FAIL",
				});
			});
	};
};

export const updatePassword = credentials => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		firebase
			.auth()
			.currentUser.updatePassword(credentials.password)
			.then(data => {
				alert("Password updated");
			})
			.catch(error => {
				alert(error.message);
				// dispatch({
				//   type: "UPDATE_PASSWORD_FAIL",
				//   error: error,
				// });
			});
	};
};
