import React, { Component, useState, useEffect } from "react";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { Spinner } from "reactstrap"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import firebase from "firebase"
import "./app.css";
import { emailLogin } from "actions/authActions";
function App(props) {
	const [ready, setReady] = useState(false)
	useEffect(() => {
		firebase.auth().onAuthStateChanged(async (user) => {
			const urlParams = new URLSearchParams(window.location.search);
			const email = decodeURIComponent(urlParams.get('email'));
			const oobCode = urlParams.get('oobCode')
			if (email && oobCode && email !== user?.email) {
				if (user) {
					localStorage.clear()
					await firebase.auth().signOut()
				}
				props.emailLogin(email)
			} else {
				setReady(true);
			}
		})
	}, [])

	if (!ready) {
		return (
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh" }} >
				<Spinner color="primary" />
			</div>
		)
	}
	return (
		<BrowserRouter>
			<Switch>
				<Route
					path="/admin"
					render={props => (
						<div>
							<AdminLayout {...props} />
						</div>
					)}
				/>
				<Route
					path="/auth"
					render={props => (
						<div>
							<AuthLayout {...props} />
						</div>
					)}
				/>
				<Redirect from="/" to="/auth/login" />
				<Redirect from="*" to="/admin/index" />
			</Switch>
		</BrowserRouter>
	);
}

const mapStateToProps = state => {
	return {

	};
};
const mapDispatchToProps = dispatch => {
	return {
		emailLogin: (email) => dispatch(emailLogin(email)),
	};
};

export default compose(
	firestoreConnect([
		{ collection: "users" },
		{ collection: "products" },
		{ collection: "reviews" },
		{ collection: "announcements" },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(App);
