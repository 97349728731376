export const buyAssessment = purchaseData => {
	// assessmentId, consultantId,quantity
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		dispatch({
			isLoad: false,
			type: 'BUY_ASSESSMENT_SUCCESS',
		})
	};
};

export function getExportData(client_id, project_id, assessment_id) {
	return async function (dispatch, getState, { getFirebase, getFirestore }) {
		let firestore = getFirestore();
		let doc = await firestore
			.collection('assessments')
			.doc(assessment_id)
			.get();
		let docs_invitaion = await firestore
			.collection('invitations')
			.where('assessmentId', '==', assessment_id)
			.where('status', '==', 'completed')
			.get();
		let doc_client = await firestore
			.collection('clients')
			.doc(client_id)
			.get();
		let docs_rater = await firestore
			.collection('raters')
			.get();
		let raters = []
		for (let rater of docs_rater.docs) {
			raters.push({ id: rater.id, ...rater.data() })
		}
		let client = doc_client.data();
		let projects = doc_client.data()['projects'];
		let thisProject = '';
		for (let project of projects) {
			if (project['id'] === project_id) {
				thisProject = project;
			}
			break;
		}

		let data = doc.data();
		let { questions } = data;
		let question_no = 0;

		let exportData = [];

		for (let question of questions) {
			let singleQuestion = {};
			singleQuestion['client'] = client['name'];
			singleQuestion['project'] = thisProject['name'];
			singleQuestion['assessment'] = doc.data()['description'];
			singleQuestion['project_start_date'] = thisProject['start_date'];
			singleQuestion['project_end_date'] = thisProject['end_date'];
			singleQuestion['category'] = question['category'];
			singleQuestion['competency'] = question['competency'];
			singleQuestion['dimension'] = question['dimension'];
			singleQuestion['type'] = question['type'];
			singleQuestion['text'] = question['text'];
			for (let doc of docs_invitaion.docs) {
				let { email } = doc.data();
				let thisRater = raters.find(rater => rater.email === email);

				if (thisRater) {
					singleQuestion['rater_id'] = thisRater.id;
					singleQuestion['rater_name'] = thisRater.firstname + " " + thisRater.lastname;
				}
				else {
					singleQuestion['rater_id'] = "";
					singleQuestion['rater_name'] = "";
				}
				if (question.type.toLowerCase() !== 'free text') {
					let { answers } = doc.data();
					singleQuestion['answer'] = answers[question_no];
				} else {
					let { freeTexts } = doc.data();
					singleQuestion['answer'] = freeTexts[question_no];
				}
				exportData.push(JSON.parse(JSON.stringify(singleQuestion)));
			}
			question_no++;
		}
		dispatch({
			type: 'EXPORT_DATA',
			payload: exportData,
			id: assessment_id,
		});
	};
}
