import Header from 'components/Headers/Header';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link, Redirect } from 'react-router-dom';
// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	Col,
	Button,
	Form,
	Label,
	Input,
	CardBody,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Alert,
} from 'reactstrap';
import { compose } from 'redux';
import { editProjects } from 'actions/clientActions';
import { addAssessment } from 'actions/clientActions';
import { deleteAssessment } from 'actions/clientActions';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftjstohtml from 'draftjs-to-html';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Editor } from 'react-draft-wysiwyg';
import { updateExistingTemplate } from 'actions/clientActions';
import { changeTemplate } from 'actions/clientActions';
import { timezones } from 'variables/constants';
class editProject extends Component {
	state = {
		Question: false,
		possibleVariables: [
			'*|RaterFirstname|*',
			'*|RaterLastname|*',
			'*|RaterEmail|*',
			'*|ConsultantName|*',
			'*|ConsultantCompany|*',
			'*|ClientName|*',
			'*|ProjectName|*',
			'*|ProjectStartDate|*',
			'*|ProjectEndDate|*',
			'*|AssessmentName|*',
			'*|LeaderName|*',
			'*|LeaderEmail|*',
			'*|RaterRole|*',
			'*|RaterRelationship|*',

		],
		template: '',
		template_lang: '',
		updateExisting: false,
		changeTemplate: false,
	};
	toggleModal = name => {
		this.setState({
			[name]: !this.state[name],
			error: false,
		});
	};
	onEditorStateChange = editorState => {
		let { template } = JSON.parse(JSON.stringify(this.state));
		template[this.state.template_lang].message = draftjstohtml(
			convertToRaw(editorState.getCurrentContent())
		);
		this.setState({
			editorState,
			template,
		});
	};
	toggle = e => {
		e.preventDefault();
		this.setState({ Question: !this.state.Question });
	};
	editProject = (project, thisProject, clientId) => {
		this.props.editProject(project, thisProject, clientId);
		this.setState({ redirect: true });
	};
	render() {
		const { clients, assessments, consultants, uid } = this.props;
		const thisConsultant =
			consultants && consultants.find(con => con.id === uid);
		const clientId = this.props.match.params.clientId;
		const projectId = this.props.match.params.id;
		const thisClient = clients && clients.filter(c => c.id === clientId)[0];
		const thisProject =
			thisClient &&
			thisClient.projects.filter(pro => pro.id === projectId)[0];
		if (this.state.redirect) {
			return <Redirect to={'/admin/clients/viewClient/' + clientId} />;
		}
		return (
			<>
				<Header />
				{/* Page content */}
				<Container className='mt--9' fluid>
					{/* forms */}
					<Row>
						<div className='col'>
							<Card className=' shadow '>
								<CardHeader className=' border-0 '>
									<div style={{ display: 'flex' }}>
										<Link
											to=''
											className='text-default '
											onClick={e => {
												e.preventDefault();
												this.props.history.goBack();
											}}
										>
											<i
												className='fas fa-long-arrow-alt-left '
												style={{ fontSize: '25px' }}
											/>
										</Link>
										<h3 className='ml-3 mb-0 '>
											<i className='ni ni-settings  ' />
											&nbsp; Edit Project
										</h3>
									</div>
								</CardHeader>
								<CardBody className=' mb-0 '>
									<Form
										onSubmit={e => {
											e.preventDefault();
											var error = false;
											if (!e.target.name.value) {
												this.setState({
													name_error:
														'Please Enter a Name',
												});
												error = true;
											} else {
												this.setState({
													name_error: '',
													name: e.target.name.value,
												});
											}
											if (!e.target.description.value) {
												this.setState({
													description_error:
														'Please enter project description',
												});
												error = true;
											} else {
												this.setState({
													description_error: '',
													description: e.target.description.value,
												});
											}
											if (!e.target.start_date.value) {
												this.setState({
													start_date_error:
														'Please Enter a Starting date',
												});
												error = true;
											} else {
												this.setState({
													start_date_error: '',
													start_date:
														e.target.start_date
															.value,
												});
											}

											if (!e.target.end_date.value) {
												this.setState({
													end_date_error:
														'Please Enter a Ending Date',
												});
												error = true;
											} else {
												this.setState({
													end_date_error: '',
													end_date:
														e.target.end_date.value,
												});
											}
											if (
												e.target.time_zone.value ===
												'Time Zone'
											) {
												this.setState({
													time_zone_error:
														'Please Select a Time Zone',
												});
												error = true;
											} else {
												this.setState({
													time_zone_error: '',
													time_zone:
														e.target.time_zone
															.value,
												});
											}
											if (!error) {
												this.editProject(
													{
														name: e.target.name
															.value,
														start_date:
															e.target.start_date
																.value,
														end_date:
															e.target.end_date
																.value,
														time_zone:
															e.target.time_zone
																.value,
														description: e.target.description.value,
														status: e.target.status
															.value,
													},
													thisProject,
													clientId
												);
											}
										}}
									>
										<Label>Project Name</Label>
										<Input
											name='name'
											defaultValue={
												thisProject && thisProject.name
											}
											valid={
												!this.state.name_error &&
												this.state.name
											}
											invalid={this.state.name_error}
										/>
										<FormFeedback>
											{this.state.name_error}
										</FormFeedback>
										<Label>Start Date </Label>
										<Input
											name='start_date'
											defaultValue={
												thisProject &&
												thisProject.start_date
											}
											type='date'
											valid={
												!this.state.start_error &&
												this.state.start
											}
											invalid={this.state.start_error}
										/>
										<FormFeedback>
											{this.state.start_error}
										</FormFeedback>
										<Label>End Date </Label>
										<Input
											name='end_date'
											defaultValue={
												thisProject &&
												thisProject.end_date
											}
											type='date'
											valid={
												!this.state.end_error &&
												this.state.end
											}
											invalid={this.state.end_error}
										/>
										<FormFeedback>
											{this.state.end_error}
										</FormFeedback>

										<Label>Time Zone</Label>
										<Input
											name='time_zone'
											type='select'
											defaultValue={thisProject?.time_zone ?? '+00:00'}
											valid={
												!this.state.time_zone_error &&
												this.state.time_zone
											}
											invalid={this.state.time_zone_error}
										>
											<option hidden>Time Zone</option>
											{timezones.map(time => {
												return (
													<option
														value={time.value}
														selected={
															thisProject &&
															thisProject.time_zone ===
															time.value
														}
													>
														{time.name}
													</option>
												);
											})}
										</Input>
										<FormFeedback>
											{this.state.time_zone_error}
										</FormFeedback>
										<Label>Status</Label>
										<Input
											name='status'
											required
											defaultValue={
												thisProject &&
												thisProject.status
											}
											type='select'
											valid={
												!this.state.status_error &&
												this.state.status
											}
											invalid={this.state.statu_error}
										>

											<option value='open'>

												Open
											</option>
											<option value='completed'>
												Completed
											</option>
										</Input>
										<FormFeedback>
											{this.state.status_error}
										</FormFeedback>
										<Label> Description </Label>
										<Input
											name='description'
											type="textarea"
											required
											defaultValue={
												thisProject &&
												thisProject.description
											}

											valid={
												!this.state.description_error &&
												this.state.description
											}
											invalid={this.state.description_error}
										>

										</Input>
										<FormFeedback invalid >
											{this.state.description_error}
										</FormFeedback>

										{thisProject && (
											<>
												<Label>Template</Label>
												<br />
												<Button
													className='mr-3'
													color='primary'
													onClick={e => {
														this.setState({
															template:
																thisProject.template,
														});
														this.toggleModal(
															'updateExisting'
														);
													}}
												>
													Update Existing Template
												</Button>
												<Button
													color='primary'
													onClick={e => {
														this.setState({
															template: "",
															template_id: "",
															template_lang: "",
														});
														this.toggleModal(
															'changeTemplate'
														);
													}}
												>
													Change Template
												</Button>
											</>
										)}
										{/* <Label>Template</Label>
										<Input
											type='select'
											required
											onChange={e =>
												this.setState({
													template_id: e.target.value,
													template_lang: '',
												})
											}
											defaultValue={
												thisProject &&
												thisProject.template_id
											}
											name='template_id'
										>
											<option hidden value=''>
												Select Template
											</option>
											{this.props.templates &&
												this.props.templates.map(
													template => {
														return (
															<option
																selected={
																	thisProject &&
																	thisProject.template_id ==
																		template.id
																}
																value={
																	template.id
																}
															>
																{template.title}
															</option>
														);
													}
												)}
										</Input>
										{(thisProject &&
											thisProject.template) !=
											undefined && (
											<>
												<Label>Language</Label>
												<Input
													type='select'
													required
													className='text-capitalize'
													onChange={e =>
														this.setState({
															template_lang:
																e.target.value,
														})
													}
													defaultValue={
														thisProject &&
														Object.keys(
															thisProject.template
														)[0]
													}
													name='template_lang'
												>
													{this.props.rawTemplates &&
														Object.keys(
															this.props
																.rawTemplates[
																this.state
																	.template_id
																	? this.state
																			.template_id
																	: thisProject.template_id
															].template
														).map(templateLang => (
															<option
																value={
																	templateLang
																}
																selected={
																	thisProject &&
																	Object.keys(
																		thisProject.template
																	)[0] ==
																		templateLang
																}
																className='text-capitalize'
															>
																{templateLang}
															</option>
														))}
												</Input>

												<Label>
													<b>Possible Variables:</b>
												</Label>
												<ul>
													{this.state.possibleVariables.map(
														(variable, idx) => (
															<CopyToClipboard
																text={variable}
																onCopy={() => {
																	this.setState(
																		{
																			copied: idx,
																		}
																	);
																	window.setTimeout(
																		() => {
																			this.setState(
																				{
																					copied: -1,
																				}
																			);
																		},
																		1500
																	);
																}}
															>
																<li className='pointer'>
																	{variable}
																	<span
																		className={`ml-3 font-weight-bold text-success ${
																			this
																				.state
																				.copied ==
																			idx
																				? 'd-inline-block'
																				: 'd-none'
																		}`}
																	>
																		Copied
																	</span>
																</li>
															</CopyToClipboard>
														)
													)}
												</ul>
												<b>
													Note: *|Variable|*, Kindly
													use variable as mentioned
												</b>
												<br />
												{this.state.variable_error && (
													<Alert color='danger'>
														{
															this.state
																.variable_error
														}
													</Alert>
												)}
												<Label>Subject</Label>
												<Input
													type='text'
													defaultValue={
														thisProject &&
														thisProject.template
													}
												></Input>
												<Label>Message</Label>
												<Editor
													editorState={
														this.state.editorState
													}
													onEditorStateChange={
														this.onEditorStateChange
													}
													wrapperClassName='demo-wrapper'
													editorClassName='demo-editor'
													toolbarClassName='toolbar-wrapper'
												/>
											</>
										)} */}

										{/* 
										{this.state.template_id ||
											(thisProject &&
												thisProject.template_id && (
													<>
														<Label>Language</Label>
														<Input
															type='select'
															required
															onChange={e =>
																this.setState({
																	template_lang:
																		e.target
																			.value,
																})
															}
														>
															<option
																hidden
																value=''
															>
																Select Template
																Language
															</option>
															{Object.keys(
																this.props
																	.rawTemplates[
																	thisProject
																		.template_id
																].template
															).map(language => {
																return (
																	<option
																		value={
																			language
																		}
																	>
																		{
																			language
																		}
																	</option>
																);
															})}
														</Input>
													</>
												))} */}
										{/* 
                    <Button
                      className="mt-2"
                      type="button"
                      color="default"
                      size="md"
                      onClick={this.toggle}
                    >
                      Add Assessment
                    </Button> */}
										<Row>
											<Col className='text-right'>
												<Button
													className='mt-2'
													type='submit'
													color='default'
													size='md'
												>
													Save Project
												</Button>
											</Col>
										</Row>
									</Form>
									<Modal
										isOpen={this.state.Question}
										toggle={this.toggle}
									>
										<ModalHeader toggle={this.toggle}>
											Add Assessment
										</ModalHeader>
										<ModalBody>
											<Form
												onSubmit={e => {
													e.preventDefault();
													var error = false;
													if (
														e.target.assessment
															.value ===
														'Assessment'
													) {
														this.setState({
															assessment_error:
																'Please Select an Assessment',
														});
														error = true;
													} else {
														this.setState({
															assessment_error:
																'',
															assessment:
																e.target
																	.assessment
																	.value,
														});
													}
													if (!error) {
														this.props.addAssessment(
															e.target.assessment
																.value,
															projectId,
															clientId
														);
														this.toggle(e);
													}
												}}
											>
												<Label>Assessments</Label>

												<Input
													name='assessment'
													type='select'
													valid={
														!this.state
															.assessment_error &&
														this.state.assessment
													}
													invalid={
														this.state
															.assessment_error
													}
												>
													<option hidden>
														Assessment
													</option>
													{assessments &&
														assessments
															.filter(ass =>
																thisConsultant &&
																	thisConsultant.buyAssessment
																	? thisConsultant.buyAssessment.includes(
																		ass.id
																	)
																	: true
															)
															.map(ass => {
																return (
																	<option
																		value={
																			ass.id
																		}
																	>
																		{
																			ass.title
																		}
																	</option>
																);
															})}
												</Input>
												<FormFeedback>
													{
														this.state
															.assessment_error
													}
												</FormFeedback>
												<Row className='mt-2'>
													<Col className='text-right'>
														<Button
															color='default'
															size='md'
														>
															Add Assessment
														</Button>
													</Col>
												</Row>
											</Form>
										</ModalBody>
									</Modal>
									{/* {thisProject &&
                    thisProject.assessments.map((ass) => {
                      return (
                        <Row className="mt-4">
                          <Col xs={{ size: 8 }}>
                            <h3 className=" mb-0 ">{ass}</h3>
                          </Col>
                          <Col xs={{ size: 4 }} className="text-right">
                            <Button
                              className="mt-2"
                              type="button"
                              color="default"
                              size="md"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.deleteAssessment(
                                  ass,
                                  projectId,
                                  clientId,
                                  thisConsultant.id
                                );
                              }}
                            >
                              Delete
                            </Button>
                          </Col>
                        </Row>
                      );
                    })} */}
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container>

				<Modal
					isOpen={this.state.updateExisting}
					toggle={e => this.toggleModal('updateExisting')}
					size='lg'
				>
					<ModalHeader
						toggle={e => this.toggleModal('updateExisting')}
					>
						Update Existing Template
					</ModalHeader>
					<ModalBody>
						<Label>Language</Label>
						<Input
							type='select'
							className='text-capitalize'
							name='template_lang'
							onChange={e => {
								let { template } = this.state;
								let message = template[e.target.value].message;
								const blocksFromHtml = htmlToDraft(message);
								const { contentBlocks, entityMap } =
									blocksFromHtml;
								const contentState =
									ContentState.createFromBlockArray(
										contentBlocks,
										entityMap
									);
								const editorState =
									EditorState.createWithContent(contentState);
								this.setState({
									template_lang: e.target.value,
									editorState,
								});
							}}
						>
							<option value='' hidden selected>
								Select Language
							</option>
							{thisProject &&
								Object.keys(thisProject.template).map(key => (
									<option
										value={key}
										className='text-capitalize'
									>
										{key}
									</option>
								))}
						</Input>
						{this.state.template_lang && (
							<>
								<Label>
									<b>Possible Variables:</b>
								</Label>
								<ul>
									{this.state.possibleVariables.map(
										(variable, idx) => (
											<CopyToClipboard
												options={{
													format: "text/plain"
												}}
												text={variable}
												onCopy={() => {
													this.setState({
														copied: idx,
													});
													window.setTimeout(() => {
														this.setState({
															copied: -1,
														});
													}, 1500);
												}}
											>
												<li className='pointer'>
													{variable}
													<span
														className={`ml-3 font-weight-bold text-success ${this.state.copied ==
															idx
															? 'd-inline-block'
															: 'd-none'
															}`}
													>
														Copied
													</span>
												</li>
											</CopyToClipboard>
										)
									)}
								</ul>
								<b>
									Note: *|Variable|*, Kindly use variable as
									mentioned
								</b>
								<br />
								<Form
									onSubmit={e => {
										e.preventDefault();
										let error = false;
										let { template } = this.state;
										let keys = Object.keys(template);
										for (let key of keys) {
											let subject = template[key].subject;
											let message = template[key].message;
											let isFirstname = false;
											let isLastname = false;
											let subjectvariables =
												subject.match(
													/\*\|[a-zA-z0-9]+\|\*/g
												);
											let messagevariables =
												message.match(
													/\*\|[a-zA-z0-9]+\|\*/g
												);
											if (subjectvariables !== null) {
												let check0 =
													subjectvariables.includes(
														'*|RaterFirstname|*'
													);
												if (check0) isFirstname = true;
												let check1 =
													subjectvariables.includes(
														'*|RaterLastname|*'
													);
												if (check1) isLastname = true;
											}
											if (messagevariables !== null) {
												let check0 =
													messagevariables.includes(
														'*|RaterFirstname|*'
													);
												if (check0) isFirstname = true;
												let check1 =
													messagevariables.includes(
														'*|RaterLastname|*'
													);
												if (check1) isLastname = true;
											}
											if (!isFirstname || !isLastname) {
												error = true;
												this.setState({
													variable_error:
														'*|RaterFirstname|* and *|RaterLastname|* are manadatory.',
												});
											}
										}
										if (!error) {

											this.props.updateExistingTemplate(
												clientId,
												projectId,
												template
											);
											this.toggleModal('updateExisting');
										}
									}}
								>
									{this.state.variable_error && (
										<Alert color='danger'>
											{this.state.variable_error}
										</Alert>
									)}
									<Label>Subject</Label>
									<Input
										type='text'
										onChange={e => {
											let { template } = JSON.parse(
												JSON.stringify(this.state)
											);
											template[
												this.state.template_lang
											].subject = e.target.value;
											this.setState({
												template,
											});
										}}
										value={
											this.state.template[
												this.state.template_lang
											].subject
										}
										name='subject'
										required
									></Input>
									<Label>Message</Label>
									<Editor
										stripPastedStyles={true}
										editorState={this.state.editorState}
										onEditorStateChange={
											this.onEditorStateChange
										}
										wrapperClassName='demo-wrapper'
										editorClassName='demo-editor'
										toolbarClassName='toolbar-wrapper'
									/>
									<div className='d-flex mt-3'>
										<Button
											type='submit'
											className='mr-3'
											color='primary'
										>
											Save
										</Button>
										<Button
											color='dark'
											onClick={e =>
												this.toggleModal(
													'updateExisting'
												)
											}
										>
											Close
										</Button>
									</div>
								</Form>
							</>
						)}
					</ModalBody>
				</Modal>
				<Modal
					isOpen={this.state.changeTemplate}
					toggle={e => this.toggleModal('changeTemplate')}
					size='lg'
				>
					<ModalHeader
						toggle={e => this.toggleModal('changeTemplate')}
					>
						Change Template
					</ModalHeader>
					<ModalBody>
						<Form
							onSubmit={e => {
								e.preventDefault();
								let error = false;
								let { template } = this.state;
								let keys = Object.keys(template);
								for (let key of keys) {
									let subject = template[key].subject;
									let message = template[key].message;
									let isFirstname = false;
									let isLastname = false;
									let subjectvariables =
										subject.match(/\*\|[a-zA-z0-9]+\|\*/g);
									let messagevariables =
										message.match(/\*\|[a-zA-z0-9]+\|\*/g);
									if (subjectvariables !== null) {
										let check0 =
											subjectvariables.includes(
												'*|RaterFirstname|*'
											);
										if (check0) isFirstname = true;
										let check1 =
											subjectvariables.includes(
												'*|RaterLastname|*'
											);
										if (check1) isLastname = true;
									}
									if (messagevariables !== null) {
										let check0 =
											messagevariables.includes(
												'*|RaterFirstname|*'
											);
										if (check0) isFirstname = true;
										let check1 =
											messagevariables.includes(
												'*|RaterLastname|*'
											);
										if (check1) isLastname = true;
									}
									if (!isFirstname || !isLastname) {
										error = true;
										this.setState({
											variable_error:
												'*|RaterFirstname|* and *|RaterLastname|* are manadatory.',
										});
									}
								}
								if (!error) {
									let obj = {
										template_id: this.state.template_id,
										template: template,
									};
									this.props.changeTemplate(
										clientId,
										projectId,
										obj
									);
									// this.props.updateExistingTemplate(
									// 	clientId,
									// 	projectId,
									// 	template
									// );
									this.toggleModal('changeTemplate');
								}
							}}
						>
							<Label>Template</Label>
							<Input
								type='select'
								name='template_id'
								onChange={e => {
									let thisTemplate =
										this.props.templates.find(
											template =>
												template.id === e.target.value
										);
									this.setState({
										template: thisTemplate.template,
										template_id: e.target.value,
										template_lang: '',
									});
								}}
							>
								<option value='' hidden selected>
									Select Template
								</option>
								{this.props.templates &&
									this.props.templates.map(template => (
										<option value={template.id}>
											{template.title}
										</option>
									))}
							</Input>
							{this.state.template !== '' && (
								<>
									<Label>Language</Label>
									<Input
										type='select'
										value={this.state.template_lang}
										name='template_lang'
										className='text-capitalize'
										onChange={e => {
											let { template } = this.state;
											let message =
												template[e.target.value]
													.message;
											const blocksFromHtml =
												htmlToDraft(message);
											const { contentBlocks, entityMap } =
												blocksFromHtml;
											const contentState =
												ContentState.createFromBlockArray(
													contentBlocks,
													entityMap
												);
											const editorState =
												EditorState.createWithContent(
													contentState
												);
											this.setState({
												template_lang: e.target.value,
												editorState,
											});
										}}
									>
										<option selected hidden value=''>
											Select Language
										</option>
										{this.state.template &&
											Object.keys(
												this.state.template
											).map(key => {
												return (
													<option
														value={key}
														className='text-capitalize'
													>
														{key}
													</option>
												);
											})}
									</Input>
									{this.state.template !== '' &&
										this.state.template_lang !== '' && (
											<>
												<Label>
													<b>Possible Variables:</b>
												</Label>
												<ul>
													{this.state.possibleVariables.map(
														(variable, idx) => (
															<CopyToClipboard
																options={{
																	format: "text/plain"
																}}
																text={variable}
																onCopy={() => {
																	this.setState(
																		{
																			copied: idx,
																		}
																	);
																	window.setTimeout(
																		() => {
																			this.setState(
																				{
																					copied: -1,
																				}
																			);
																		},
																		1500
																	);
																}}
															>
																<li className='pointer'>
																	{variable}
																	<span
																		className={`ml-3 font-weight-bold text-success ${this
																			.state
																			.copied ==
																			idx
																			? 'd-inline-block'
																			: 'd-none'
																			}`}
																	>
																		Copied
																	</span>
																</li>
															</CopyToClipboard>
														)
													)}
												</ul>
												<b>
													Note: *|Variable|*, Kindly
													use variable as mentioned
												</b>
												<br />
												{this.state.variable_error && (
													<Alert color='danger'>
														{
															this.state
																.variable_error
														}
													</Alert>
												)}
												<Label>Subject</Label>
												<Input
													type='text'
													value={
														this.state.template[
															this.state
																.template_lang
														].subject
													}
													onChange={e => {
														let { template } =
															JSON.parse(
																JSON.stringify(
																	this.state
																)
															);
														template[
															this.state.template_lang
														].subject =
															e.target.value;
														this.setState({
															template,
														});
													}}
													name='subject'
													required
												></Input>
												<Label>Message</Label>
												<Editor
													stripPastedStyles={true}
													editorState={
														this.state.editorState
													}
													onEditorStateChange={
														this.onEditorStateChange
													}
													wrapperClassName='demo-wrapper'
													editorClassName='demo-editor'
													toolbarClassName='toolbar-wrapper'
												/>
											</>
										)}
								</>
							)}
							<div className='d-flex mt-3'>
								<Button
									type='submit'
									className='mr-3'
									color='primary'
								>
									Save
								</Button>
								<Button
									color='dark'
									onClick={e =>
										this.toggleModal('changeTemplate')
									}
								>
									Close
								</Button>
							</div>
						</Form>
					</ModalBody>
				</Modal>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		authError: state.auth_consultant.authError,
		clients: state.firestore.ordered.clients,
		consultants: state.firestore.ordered.consultants,
		assessments: state.firestore.ordered.assessments,
		templates: state.firestore.ordered.templates,
		rawTemplates: state.firestore.data.templates,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		editProject: (project, previousP, clientId) =>
			dispatch(editProjects(project, previousP, clientId)),
		addAssessment: (ass, projectId, clientId, consultantId) =>
			dispatch(addAssessment(ass, projectId, clientId, consultantId)),
		deleteAssessment: (ass, projectId, clientId) =>
			dispatch(deleteAssessment(ass, projectId, clientId)),
		updateExistingTemplate: (clientId, projectId, template) =>
			dispatch(updateExistingTemplate(clientId, projectId, template)),
		changeTemplate: (clientId, projectId, templateObj) =>
			dispatch(changeTemplate(clientId, projectId, templateObj)),
	};
};

export default compose(
	firestoreConnect([
		{ collection: 'clients' },
		{ collection: 'assessments' },
		{ collection: 'consultants' },
		{ collection: 'templates' },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(editProject);
