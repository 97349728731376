import React, { useState, useEffect } from "react"
import Header from "components/Headers/Header"
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { deletePdfGroupData } from "actions/clientActions";
import {
  Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label,
  Row, Spinner, UncontrolledTooltip, Form, Button, Modal
} from 'reactstrap';
import { Table } from "antd"
import { DownloadOutlined } from '@ant-design/icons';
import DeleteDialog from "components/DeleteDialog";
import { pdf, PDFDownloadLink, usePDF, PDFViewer } from "@react-pdf/renderer";
import GroupPdFDocument, { getAllLeaders, getGroupPdfData, getLeaderViewGroupData, getRaterViewGroupData, getAllIncludedRaters } from "components/GroupPdfDocument";
import { saveAs } from 'file-saver'

const GroupReportTable = (props) => {
  const { clients, consultants, invitations, assessments, uid, reportDownloadLogs, groupPdfData } = props;

  const [tableData, setTableData] = useState(null)
  const [deleteReportId, setDeleteReportId] = useState('')
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  // For Testing purpose
  const [showPdf, setShowPdf] = useState(false)
  const [selectedGroupReport, setSelectedGroupReport] = useState()

  useEffect(() => {
    const filteredReports = groupPdfData?.filter((logs) => logs?.type === "Group Report" && logs?.uid === uid)
    const uniqueFilteredReports = [... new Set(filteredReports)]
    setTableData(uniqueFilteredReports)

  }, [groupPdfData])



  const toggleDeleteGroupReport = () => {
    setShowDeleteDialog(!showDeleteDialog)
  }


  // For testing
  const showPdfToggle = () => {
    setShowPdf(!showPdf)
  }

  const exportGroupReport = (report) => {
    pdf(getReportComponent(report)).toBlob().then((blob) => saveAs(blob, `${report?.groupTitle} - ${report?.client?.name} - group report`))

  }

  function getReportComponent(selectedReport) {
    // To test selectedGroupReport = {selectedGroupReport}
    return (
      <GroupPdFDocument
        selectedGroupReport={selectedReport}
      />
    )


  }

  function fullfillGroupReport(groupReport) {

    const { projectIds, clientId, assessmentIds } = groupReport;

    const client = clients.find((client) => client.id === clientId);

    const projects = client?.projects.filter((project) => projectIds.includes(project.id))

    const groupAssessments = assessments?.filter((assessment) => assessmentIds.includes(assessment.id))

    const groupInvitations = invitations?.filter((inv) => inv.clientId === clientId && projectIds.includes(inv?.projectId) && assessmentIds.includes(inv?.assessmentId) && inv?.status === "completed")

    groupReport = {
      ...groupReport,
      client,
      projects,
      assessments: groupAssessments,
      invitations: groupInvitations
    }

    return groupReport
  }

  const getClientName = (clientId) => {
    return clients.find((client) => client?.id === clientId)
  }

  return (
    <>
      <Header />
      <Container className='mt--9' fluid>
        <Row>
          <div className='col'>
            <Card className=' shadow'>
              <CardHeader className=' border-0'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                  <h3 className=' mb-0'>Group Reports</h3>
                  <Link className="text-center" to="/admin/reporting">
                    <Button color="default" size="sm" className=' mb-0'>Create New Group Report</Button>

                  </Link>
                </div>
              </CardHeader>
              <CardBody>


                {!groupPdfData ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <Spinner color="primary" />

                  </div>

                )
                  :
                  tableData && tableData?.length === 0 ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                      <h4 > There are no group reports created. In order to download  group report please create a new one. </h4>
                    </div>

                  )

                    :
                    <Table
                      scroll={{ x: "max-content" }}
                      size="small"
                      dataSource={tableData}
                    >
                      <Table.Column
                        title="Name"
                        width="100px"
                        render={(report) => (
                          report?.groupTitle ? (report?.groupTitle) : "---"
                        )}
                      />


                      <Table.Column
                        title="Client"
                        width="150px"
                        render={(report) => (
                          report?.clientId ? (getClientName(report?.clientId)?.name) : "---"
                        )}
                      />
                      <Table.Column
                        title="Assessments"
                        width="120px"
                        render={(report) => (
                          report?.assessmentNames ? (report?.assessmentNames).join(", ") : "---"
                        )}
                      />
                      <Table.Column
                        title=" Created By"
                        width="100px"
                        sorter={(a, b) => a.createdBy.localeCompare(b.createdBy)}
                        render={(report) => report?.createdBy || "---"}
                      />
                      <Table.Column
                        title="Created At"
                        width="100px"
                        sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
                        render={(report) => report?.createdAt || "---"}
                      />
                      <Table.Column
                        title="Actions"
                        width="130px"
                        align="center"
                        render={(report) => (
                          <>
                            {/* edit  */}
                            <Link to={{
                              pathname: "/admin/reporting",
                              state: { reportId: report?.id, reportData: report }
                            }}
                              style={{
                                padding:
                                  '0.25rem 0.5rem',
                              }}
                            >
                              <Button
                                size='sm'
                                color='white'
                                id={`btnEdit`}
                              >
                                <i className="ni ni-settings text-primary " />
                              </Button>
                            </Link>
                            <UncontrolledTooltip
                              placement='top'
                              target={`btnEdit`}
                            >
                              Edit Report
                            </UncontrolledTooltip>

                            {/* export */}
                            <Button
                              size='sm'
                              color='white'
                              id={`btnExport`}
                              onClick={() => {
                                // setSelectedGroupReport(fullfillGroupReport(report))
                                setShowPdf(true)
                                exportGroupReport(fullfillGroupReport(report))
                              }}
                            >
                              <i className="fas fa-download" />
                            </Button>

                            <UncontrolledTooltip
                              placement='top'
                              target={`btnExport`}
                            >
                              Export Report
                            </UncontrolledTooltip>
                            {/* delete */}
                            <Button
                              size='sm'
                              color='white'
                              id={`btnDelete`}
                              onClick={() => {
                                setDeleteReportId(report?.id)
                                setShowDeleteDialog(true)
                                toggleDeleteGroupReport()
                              }}

                            >
                              <i className="fas fa-trash text-red  " />
                            </Button>
                            <UncontrolledTooltip
                              placement='top'
                              target={`btnDelete`}
                            >
                              Delete Report
                            </UncontrolledTooltip>
                          </>


                        )}
                      />

                    </Table>
                }
                {showDeleteDialog && (
                  <DeleteDialog
                    toggle={toggleDeleteGroupReport}
                    deleteKey={"deleteGroupReport"}
                    deleteData={showDeleteDialog}
                    onDelete={async () => {
                      await props.deletePdfGroupData(deleteReportId)
                    }}
                  />

                )}
                {/* {showPdf && selectedGroupReport && (
                  <Modal isOpen={showPdf} toggle={showPdfToggle} >
                    <PDFViewer style={{ width: "900px", height: "800px" }} >
                      {getReportComponent()}
                    </PDFViewer>
                  </Modal>
                )} */}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )
}



const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    displayName: state.firebase.auth.displayName,
    assessments: state.firestore.ordered.assessments,
    invitations: state.firestore.ordered.invitations,
    clients: state.firestore.ordered.clients,
    consultants: state.firestore.ordered.consultants,
    reportDownloadLogs: state.firestore.ordered.reportDownloadLogs,
    groupPdfData: state.firestore.ordered.groupPdfData
  };
};
const mapDispatchToProps = dispatch => {
  return {
    deletePdfGroupData: groupPdfId => dispatch(deletePdfGroupData(groupPdfId)),

  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'assessments' },
    { collection: 'invitations' },
    { collection: "clients" },
    { collection: "consultants" },
    { collection: "reportDownloadLogs" },
    { collection: "groupPdfData" }

  ])
)(GroupReportTable);


// export default GroupReportTable