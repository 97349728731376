import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/react";


// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { compose } from "redux";

class DashboardHeader extends React.Component {
	render() {
		const { uid, clients, raters, consultants } = this.props;
		const clientNo =
			clients && clients.filter(cl => cl.consultantId === uid).length;
		var ProjectNo = 0;
		const thisConsultant =
			consultants && consultants.find(con => con.id === uid);

		Sentry.setUser({
			email: thisConsultant?.email,
			username: thisConsultant?.first_name,
			id: thisConsultant?.id
		})

		return (
			<>
				<div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
					<Container fluid>
						<div className="header-body">
							{/* Card stats */}
							<Row>
								<Col lg="6" xl="4">
									<Link to="/admin/clients">
										<Card className="card-stats mb-4 mb-xl-0">
											<CardBody>
												<Row>
													<div className="col">
														<CardTitle
															tag="h5"
															className="text-uppercase text-muted mb-0"
														>
															Clients
														</CardTitle>
														<span className="h2 font-weight-bold mb-0">
															{clientNo}
														</span>
													</div>
													<Col className="col-auto">
														<div className="icon icon-shape bg-danger text-white rounded-circle shadow">
															<i className="fas fa-user" />
														</div>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Link>
								</Col>
								<Col lg="6" xl="4">
									<Link to="/admin/projects">
										<Card className="card-stats mb-4 mb-xl-0">
											<CardBody>
												<Row>
													<div className="col">
														<CardTitle
															tag="h5"
															className="text-uppercase text-muted mb-0"
														>
															Projects
														</CardTitle>
														<span className="h2 font-weight-bold mb-0">
															{clients &&
																clients
																	.filter(cl => cl.consultantId === uid)
																	.reduce(
																		(acc, element) =>
																			acc + element.projects.length,
																		0
																	)}
														</span>
													</div>
													<Col className="col-auto">
														<div className="icon icon-shape bg-warning text-white rounded-circle shadow">
															<i className="fas fa-chart-pie" />
														</div>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Link>
								</Col>
								<Col lg="6" xl="4">
									<Link to="/admin/buyassessments">
										<Card className="card-stats mb-4 mb-xl-0">
											<CardBody>
												<Row>
													<div className="col">
														<CardTitle
															tag="h5"
															className="text-uppercase text-muted mb-0"
														>
															Owned Assessments
														</CardTitle>
														<span className="h2 font-weight-bold mb-0">
															{thisConsultant && thisConsultant.buyAssessment
																? thisConsultant.buyAssessment.length
																: "0"}
														</span>
													</div>
													<Col className="col-auto">
														<div className="icon icon-shape bg-info text-white rounded-circle shadow">
															<i className="fas fa-users" />
														</div>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Link>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		uid: state.firebase.auth.uid,
		authError: state.auth_consultant.authError,
		clients: state.firestore.ordered.clients,
		consultants: state.firestore.ordered.consultants,
		raters: state.firestore.ordered.raters,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		// updateProfile: (profile, uid) => dispatch(updateProfile(profile, uid)),
	};
};

export default compose(
	firestoreConnect([
		{ collection: "users" },
		{ collection: "clients" },
		{ collection: "raters" },
		{ collection: "consultants" },
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(DashboardHeader);
