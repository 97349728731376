import { Document, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { getAverageOf, getOwnerProgress, getRaterAnswers, getRatersProgress, getScaleQuestions, getScaleQuestionsByLeadersSorting, getScaleQuestionsByRaterSorting, PageFooter, PageHeaderDiv, PDFColumn, PdfLogo, pdfStyles, ProgressDiv, renderPdfColumn, ThreeDots } from './PdfDocument';

export interface GroupReport {
  // These come from databse
  assessmentIds: string[]
  assessmentNames: string[]
  clientId: string
  excludeLeaders: boolean
  createdAt: Date
  createdBy: string
  groupName: string
  groupTitle: string
  id: string
  projectIds: string[]
  projectNames: string[]
  type: string
  uid: string

  // These are dynamic data
  client: any
  invitations: any[]
  assessments: any[]
  projects: any[]
}



const GroupPdFDocument = (
  {
    selectedGroupReport,
  }: {
    selectedGroupReport: GroupReport,
  }
) => {

  const leaderInvitations: any[] = getAllLeaderInvitations(selectedGroupReport?.invitations)

  const raterInvitations: any[] = getAllRatersInvitations(selectedGroupReport?.invitations, selectedGroupReport?.excludeLeaders)



  const groupPdfData = getGroupPdfData(selectedGroupReport, leaderInvitations, raterInvitations);

  const groupPdfRaterViewData = getRaterViewGroupData(selectedGroupReport?.assessments, leaderInvitations, raterInvitations);

  const groupPdfLeaderViewData = getLeaderViewGroupData(selectedGroupReport?.assessments, leaderInvitations, raterInvitations)


  const groupAllLeaders = getAllLeaders(leaderInvitations);
  const groupAllRatersInExcluded = getAllIncludedRaters(raterInvitations);

  const excludeLeaders = selectedGroupReport?.excludeLeaders;

  return (
    <Document>
      <Page size="A4" style={pdfStyles.page} orientation="portrait" wrap >
        <View fixed style={{ width: "100%", height: "5px", backgroundColor: "#069BEC" }} />
        <View wrap style={{ marginHorizontal: "70px" }}>
          {/* logo */}
          <PdfLogo />
          {/* main */}

          {/* Compane Name */}
          <PageHeaderDiv pageHeader={groupPdfData?.pageHeader} />

          <View style={{ marginTop: "10px" }}>
            <Text style={pdfStyles.subtitleStyle}>
              {groupPdfData?.pageHeader?.subtitle + "           " + groupPdfData?.pageHeader?.totalLeaders}
            </Text>

          </View>

          {/* content */}
          <View style={{ display: "flex", flexDirection: "column" }}>
            {/* table */}
            {groupPdfData?.cells?.map((column) => renderPdfColumn(0, column))}
          </View>
          {/* indicator*/}
          <View fixed style={{ marginVertical: "20px" }}>
            {groupPdfData?.footer}
          </View>

          {/* 3 dots */}
          <ThreeDots />
          <View fixed style={{ height: "70px" }} />
        </View>
        {/* page 2 - Rater View */}

        <View >
          {/* <SideLogo /> */}
          <View style={{ marginHorizontal: "70px", marginTop: "20px" }}>
            {/* LOGO */}
            <PdfLogo />
            <PageHeaderDiv pageHeader={groupPdfData?.pageHeader} />
            {/* total raters */}
            <View style={{ marginTop: "3px" }}>
              <Text style={{ ...pdfStyles.subtitleStyle }}>
                {groupPdfData?.pageHeader?.subtitle + "           " + groupPdfData?.pageHeader?.totalLeaders}
              </Text>

            </View>
            {/* text-rater view */}
            <Text fixed style={pdfStyles.raterView}>
              Rater View - Lowest to Highest
            </Text>
            {/* content */}
            <View wrap style={{ display: "flex", flexDirection: "column" }}>
              {/* table */}
              <View style={{ display: "flex", flexDirection: "column" }}>
                {groupPdfRaterViewData?.cells?.map((column) =>
                  renderPdfColumn(0, column)
                )}
              </View>
              {/* indicator*/}
              <View fixed style={{ marginTop: "20px" }}>
                {groupPdfData?.footer}
              </View>
              {/* aspect response */}
              <View fixed style={{ marginVertical: "15px" }}>
                <Text style={{ fontSize: "8px", marginLeft: "7px" }} >Aspect Response on a 10 point scale </Text>
              </View>
              {/* 3 dots */}
              <ThreeDots />
            </View>
          </View>
          <View fixed style={{ height: "70px" }} />
        </View>

        {/* page 3 => leader view */}

        <View break>
          {/* <SideLogo /> */}
          <View style={{ marginHorizontal: "70px" }}>
            {/* LOGO */}
            <PdfLogo />
            <PageHeaderDiv pageHeader={groupPdfData?.pageHeader} />
            {/* total raters */}
            <View style={{ marginTop: "3px" }}>
              <Text style={{ ...pdfStyles.subtitleStyle, marginLeft: "7px" }}>
                {groupPdfData?.pageHeader?.subtitle + "           " + groupPdfData?.pageHeader?.totalLeaders}

              </Text>

            </View>
            {/* text-leader view */}
            <Text fixed style={pdfStyles.raterView}>
              Leader View - Lowest to Highest
            </Text>

            {/* content */}
            <View wrap style={{ display: "flex", flexDirection: "column" }}>
              {/* table */}
              <View style={{ display: "flex", flexDirection: "column" }}>
                {groupPdfLeaderViewData?.cells?.map((column) =>
                  renderPdfColumn(0, column)
                )}
              </View>
              {/* indicator*/}
              <View fixed style={{ marginTop: "20px" }}>
                {groupPdfData?.footer}
              </View>
              {/* aspect response */}
              <View fixed style={{ marginVertical: "15px" }}>
                <Text style={{ fontSize: "8px", marginLeft: "7px" }} >Aspect Response on a 10 point scale </Text>
              </View>
              {/* 3 dots */}

              <ThreeDots />
            </View>
          </View>
          <View fixed style={{ height: "70px" }} />
        </View>

        {/* page 5 - List of all leaders included to calculate the the leader response */}
        <View break>
          {/* <SideLogo /> */}
          <View style={{ marginHorizontal: "70px" }}>
            {/* LOGO */}
            <PdfLogo />
            <PageHeaderDiv pageHeader={groupPdfData?.pageHeader} />
            {/* total raters */}
            <View style={{ marginTop: "3px" }}>
              <Text style={{ ...pdfStyles.subtitleStyle, marginLeft: "7px" }}>
                {groupPdfData?.pageHeader?.subtitle + "           " + groupPdfData?.pageHeader?.totalLeaders}

              </Text>

            </View>
            {/* text-leader view */}
            <Text fixed style={pdfStyles.raterView}>
              Included List of Leaders
            </Text>

            {/* <View fixed style={{ height: "5px" }} /> */}

            {/* content */}
            <View wrap style={{ display: "flex", flexDirection: "column" }}>
              {/* table */}
              <View style={{ display: "flex", flexDirection: "column" }}>
                {groupAllLeaders?.cells?.map((column) =>
                  renderPdfColumn(0, column)
                )}
              </View>

              {/* 3 dots */}
              <View fixed style={{ height: "10px" }} />
              <ThreeDots />
            </View>
          </View>
          <View fixed style={{ height: "70px" }} />
        </View>

        {excludeLeaders && (
          // page 6 - List of all raters included in the calculation
          <View break>
            {/* <SideLogo /> */}
            <View style={{ marginHorizontal: "70px" }}>
              {/* LOGO */}
              <PdfLogo />
              <PageHeaderDiv pageHeader={groupPdfData?.pageHeader} />
              {/* total raters */}
              <View style={{ marginTop: "3px" }}>
                <Text fixed style={{ ...pdfStyles.subtitleStyle, marginLeft: "7px" }}>
                  {groupPdfData?.pageHeader?.subtitle + "           " + groupPdfData?.pageHeader?.totalLeaders}

                </Text>

              </View>
              {/* text-leader view */}
              <Text fixed style={pdfStyles.raterView}>
                Included List of Raters
              </Text>

              {/* <View fixed style={{ height: "5px" }} /> */}

              {/* content */}
              <View wrap style={{ display: "flex", flexDirection: "column" }}>
                {/* table */}
                <View style={{ display: "flex", flexDirection: "column" }}>
                  {groupAllRatersInExcluded?.cells?.map((column) =>
                    renderPdfColumn(0, column)
                  )}
                </View>

                {/* 3 dots */}
                <View fixed style={{ height: "10px" }} />
                <ThreeDots />
              </View>
            </View>
            <View fixed style={{ height: "70px" }} />
          </View>
        )}

        <PageFooter pageHeader={groupPdfData?.pageHeader} />

      </Page>
    </Document>
  )
}

export default GroupPdFDocument


export const getAllQuestionsFromAssessments = (assessments: any[]) => {
  let allQuestions: any[] = [];
  const allAssessmentsQuestions = assessments?.map((ass: any) => {
    for (let questions of ass?.questions) {
      allQuestions.push(questions)

    }
  })
  return allQuestions;
}

export const getAllLeaderInvitations = (allInvitations: any[]): any[] => {
  return allInvitations?.filter((inv) => inv.type === "Owner")
}

export const getAllRatersInvitations = (allInvitations: any[], excludeLeaders: boolean): any[] => {
  let leaders: any[] = []
  let leadersEmail: string[] = []
  if (excludeLeaders) {
    leaders = getAllLeaderInvitations(allInvitations)
  }
  for (let leader of leaders) {
    leadersEmail.push(leader?.email)
  }
  return allInvitations?.filter((inv) => {
    if (inv?.type === "Owner") {
      return false
    }

    if (leaders?.length > 0 && leadersEmail.includes(inv?.email)) {
      return false
    }
    return true
  });

}


export function getGroupPdfData(selectedReport: GroupReport, leaderInvitations: any[], raterInvitations: any[]) {
  const { assessments, client, groupTitle, excludeLeaders } = selectedReport;
  let questions = getAllQuestionsFromAssessments(assessments)?.filter((que: any) => que?.type === "SCALE");

  const scaleQuestions = getScaleQuestions({ allQuestionsFromClient: questions });

  const cells: PDFColumn[] = [
    {
      flex: 2,
      totalFlex: 7,
      textColor: "#1C549A",
      value: "Dimension",
      cells: [
        {
          flex: 2,
          totalFlex: 5,
          textColor: "#1C549A",
          value: "Competency",
          cells: [
            {
              flex: 3,
              totalFlex: 3,
              value: (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    color: "#1C549A",
                  }}
                >
                  <Text>0</Text>
                  <Text>1</Text>
                  <Text>2</Text>
                  <Text>3</Text>
                  <Text>4</Text>
                  <Text>5</Text>
                  <Text>6</Text>
                  <Text>7</Text>
                  <Text>8</Text>
                  <Text>9</Text>
                  <Text>10</Text>
                </View>
              ),
            },
          ],
        },
      ],
    },
  ];
  Array.from(new Set<string>(scaleQuestions?.map((q: any) => q.dimension))).map(
    (dimension) => {
      const ratersAverage = getAverageOf({ invitations: raterInvitations, dimension, forLeader: false, allQuestionsFromAssessments: getAllQuestionsFromAssessments(assessments) })
      const leaderAverage = getAverageOf({ invitations: leaderInvitations, dimension, forLeader: true, allQuestionsFromAssessments: getAllQuestionsFromAssessments(assessments) })
      cells.push({
        value: `${dimension} \n\n  Raters (${(ratersAverage ? ratersAverage * 10 : 0)?.toFixed(0) ?? 0}) %  \n\n Leaders (${(leaderAverage ? leaderAverage * 10 : 0)?.toFixed(0) ?? 0}) %` as String,
        color: "#069BEC",
        textColor: "white",
        flex: 2,
        totalFlex: 7,
        cells: Array.from(
          new Set(
            scaleQuestions
              ?.filter((q: any) => q.dimension === dimension)
              .map((q: any) => q.competency)
          )
        ).map((competency) => {
          return {
            value: competency as String,
            color: "#F0EBEB",
            textColor: "black",
            flex: 2,
            totalFlex: 5,
            cells: scaleQuestions
              .filter(
                (q: any) =>
                  q.dimension === dimension && q.competency === competency
              )
              .map((q: any) => {
                return {
                  value: (
                    <ProgressDiv
                      leaderWidth={getOwnerProgress(leaderInvitations, q?.id)}
                      raterWidth={getRatersProgress(raterInvitations, q?.id)}
                    />
                  ),
                  flex: 3,
                  totalFlex: 3,
                };
              }),
          };
        }),
      });
    }
  );
  return {
    pageHeader: {
      title: client?.name,
      groupTitle: groupTitle ?? groupTitle,
      totalLeaders: "Total Leaders: " + leaderInvitations.length,
      // leader: leaderFullName,
      // version: "version",
      subtitle:
        "Total Raters: " + raterInvitations.length
      ,
    },
    cells: cells,
    footer: (
      <div
        style={{
          marginLeft: 7,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <View
          style={{
            backgroundColor: "#a4a0ef",
            height: "3px",
            width: "30px",
            marginTop: "3px",
          }}
        ></View>
        <Text style={{ fontSize: "8px", marginRight: "8px", marginLeft: "5px" }}>Leader View</Text>
        <View
          style={{
            backgroundColor: "#bcd680",
            height: "3px",
            width: "30px",
            marginTop: "3px",
          }}
        ></View>
        <Text style={{ fontSize: "8px", marginLeft: "4px" }}> Rater View </Text>
      </div>
    ),

  };
}

// view data - RATER
export function getRaterViewGroupData(assessments: any[], leaderInvitations: any[], raterInvitations: any[]) {
  let questions = getAllQuestionsFromAssessments(assessments)?.filter((que: any) => que?.type === "SCALE");

  const scaleQuestions = getScaleQuestionsByRaterSorting({ invitations: raterInvitations, allQuestionsFromClient: questions });

  const cells: PDFColumn[] = [
    {
      flex: 2,
      totalFlex: 6,
      textColor: "#1C549A",
      value: "Competency",
      cells: [
        {
          flex: 1,
          totalFlex: 4,
          textColor: "#1C549A",
          textAligment: "center",
          value: "Leader's Answer",
          cells: [
            {
              flex: 1,
              totalFlex: 3,
              textColor: "#1C549A",
              textAligment: "center",
              value: "Raters' Answer",
              cells: [
                {
                  flex: 2,
                  totalFlex: 2,
                  textAligment: "center",
                  value: (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        color: "#1C549A",
                      }}
                    >
                      <Text>0</Text>
                      <Text>1</Text>
                      <Text>2</Text>
                      <Text>3</Text>
                      <Text>4</Text>
                      <Text>5</Text>
                      <Text>6</Text>
                      <Text>7</Text>
                      <Text>8</Text>
                      <Text>9</Text>
                      <Text>10</Text>
                    </View>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  Array.from(new Set(scaleQuestions?.map((que: any) => que.competency))).map(
    (competency: any) => {
      cells.push({
        value: competency,
        color: "#F0EBEB",
        textColor: "black",
        flex: 2,
        totalFlex: 6,
        cells: Array.from(
          new Set(
            scaleQuestions?.filter((que: any) => que.competency === competency)
          )
        ).map((que: any) => {
          return {
            value: `${(
              (getOwnerProgress(leaderInvitations, que?.id) ?? 0) / 10
            )?.toFixed(1)}`,
            color: "#F0EBEB",
            textColor: "black",
            flex: 1,
            textAligment: "center",

            totalFlex: 4,
            cells: Array.from(
              new Set(
                scaleQuestions?.filter(
                  (que: any) => que.competency === competency
                )
              )
            ).map((que: any) => {
              return {
                value: `${(
                  (getRatersProgress(raterInvitations, que?.id) ?? 0) / 10
                )?.toFixed(1)}`,
                color: "#F0EBEB",
                textColor: "black",
                flex: 1,
                totalFlex: 3,
                textAligment: "center",

                cells: scaleQuestions
                  .filter((q: any) => {
                    if (q.competency === competency) {
                      if (getRaterAnswers(raterInvitations, q?.id)! > 0) {
                        return q;
                      }
                    }
                  })
                  .map((que: any) => {
                    return {
                      value: (
                        <ProgressDiv
                          leaderWidth={getOwnerProgress(leaderInvitations, que?.id)}
                          raterWidth={getRatersProgress(raterInvitations, que?.id)}
                        />
                      ),
                      textAligment: "center",
                      flex: 2,
                      totalFlex: 2,
                    };
                  }),
              };
            }),
          };
        }),
      });
    }
  );
  return {
    cells: cells,
  };
}

export function getLeaderViewGroupData(assessments: any[], leaderInvitations: any[], raterInvitations: any[]) {
  let questions = getAllQuestionsFromAssessments(assessments)?.filter((que: any) => que?.type === "SCALE");

  const scaleQuestions = getScaleQuestionsByLeadersSorting(
    { invitations: leaderInvitations, allQuestionsFromClient: questions }
  );

  const cells: PDFColumn[] = [
    {
      flex: 2,
      totalFlex: 6,
      textColor: "#1C549A",
      value: "Competency",
      cells: [
        {
          flex: 1,
          totalFlex: 4,
          textColor: "#1C549A",
          textAligment: "center",

          value: "Leader's Answer",
          cells: [
            {
              flex: 1,
              totalFlex: 3,
              textColor: "#1C549A",
              textAligment: "center",

              value: "Raters' Answer",
              cells: [
                {
                  flex: 2,
                  totalFlex: 2,
                  textAligment: "center",

                  value: (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        color: "#1C549A",
                      }}
                    >
                      <Text>0</Text>
                      <Text>1</Text>
                      <Text>2</Text>
                      <Text>3</Text>
                      <Text>4</Text>
                      <Text>5</Text>
                      <Text>6</Text>
                      <Text>7</Text>
                      <Text>8</Text>
                      <Text>9</Text>
                      <Text>10</Text>
                    </View>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  Array.from(new Set(scaleQuestions?.map((que: any) => que.competency))).map(
    (competency: any) => {
      cells.push({
        value: competency,
        color: "#F0EBEB",
        textColor: "black",
        flex: 2,
        totalFlex: 6,
        cells: Array.from(
          new Set(
            scaleQuestions?.filter((que: any) => que.competency === competency)
          )
        ).map((que: any) => {
          return {
            value: `${(
              (getOwnerProgress(leaderInvitations, que?.id) ?? 0) / 10
            )?.toFixed(1)}`,
            color: "#F0EBEB",
            textColor: "black",
            flex: 1,
            textAligment: "center",
            totalFlex: 4,
            cells: Array.from(
              new Set(
                scaleQuestions?.filter(
                  (que: any) => que.competency === competency
                )
              )
            ).map((que: any) => {
              return {
                value: `${(
                  (getRatersProgress(raterInvitations, que?.id) ?? 0) / 10
                )?.toFixed(1)}`,
                color: "#F0EBEB",
                textColor: "black",
                flex: 1,
                totalFlex: 3,
                textAligment: "center",
                cells: scaleQuestions
                  .filter((q: any) => {
                    if (q.competency === competency) {
                      if (getRaterAnswers(raterInvitations, q?.id)! > 0) {
                        return q;
                      }
                    }
                  })
                  .map((que: any) => {
                    return {
                      value: (
                        <ProgressDiv
                          leaderWidth={getOwnerProgress(leaderInvitations, que?.id)}
                          raterWidth={getRatersProgress(raterInvitations, que?.id)}
                        />
                      ),
                      flex: 2,
                      textAligment: "center",
                      totalFlex: 2,
                    };
                  }),
              };
            }),
          };
        }),
      });
    }
  );
  return {
    cells: cells,
  };
}


export const getAllLeaders = (leaderInvitations: any[]) => {
  const cells: PDFColumn[] = [
    {
      flex: 1,
      totalFlex: 2,
      textColor: "#1C549A",
      value: "Leader's Name",
      textAligment: "center",
      cells: [
        {
          flex: 1,
          totalFlex: 1,
          textColor: "#1C549A",
          textAligment: "center",

          value: "Email",

        },
      ],
    },
  ];

  let emails: string[] = []
  const uniqueLeaderInvitations = leaderInvitations?.filter((inv) => {
    if (!emails.includes(inv?.email)) {
      emails.push(inv?.email)
      return true;
    }
    return false;
  })

  uniqueLeaderInvitations.forEach((prevInvitation) => {
    cells.push({
      value: `${prevInvitation?.firstname}  ${prevInvitation?.lastname}`,
      color: "#F0EBEB",
      textColor: "black",
      textAligment: "center",
      flex: 1,
      totalFlex: 2,
      cells: uniqueLeaderInvitations?.filter((invitation) => invitation.email === prevInvitation.email).map((invitation) => {
        return {
          value: invitation?.email,
          color: "#F0EBEB",
          textColor: "black",
          flex: 1,
          textAligment: "center",
          totalFlex: 1,
        }
      })
    })
  })



  return {
    cells: cells,
  };
}


export const getAllIncludedRaters = (raterInvitations: any[]) => {

  const cells: PDFColumn[] = [
    {
      flex: 1,
      totalFlex: 2,
      textColor: "#1C549A",
      value: "Rater's name",
      textAligment: "center",
      cells: [
        {
          flex: 1,
          totalFlex: 1,
          textColor: "#1C549A",
          textAligment: "center",

          value: "Email",

        },
      ],
    },
  ];
  let emails: string[] = []; // Keeps track of all the invitation emails that are selected
  const uniqueRaterInvitations = raterInvitations.filter((invitation) => {
    if (!emails.includes(invitation.email)) {
      emails.push(invitation.email)
      return true
    }
    return false
  })
  uniqueRaterInvitations.forEach((inv) => {
    cells.push({
      value: `${inv.firstname} ${inv.lastname}`,
      color: "#F0EBEB",
      textColor: "black",
      textAligment: "center",
      flex: 1,
      totalFlex: 2,
      cells: uniqueRaterInvitations?.filter((invitation) => invitation.email === inv.email).map((inv) => {
        return {
          value: inv.email,
          color: "#F0EBEB",
          textColor: "black",
          flex: 1,
          textAligment: "center",
          totalFlex: 1,
        }
      })
    })
  })



  return {
    cells: cells,
  };
}