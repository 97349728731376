import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";

class Admin extends React.Component {
	componentDidUpdate(e) {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}
	getRoutes = routes => {
		return routes.map((prop, key) => {
			if (prop.layout === "/admin") {
				return (
					<Route
						exact
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};
	getBrandText = path => {
		if (this.props.location.pathname.includes("/admin/clients/viewClient"))
			return "Project";
		else if (this.props.location.pathname.includes("/viewAssessment/"))
			return "Rater";
		else if (this.props.location.pathname.includes("/viewProject/"))
			return "Assessment";
		for (let i = 0; i < routes.length; i++) {
			if (
				this.props.location.pathname.indexOf(
					routes[i].layout + routes[i].path
				) !== -1
			) {
				return routes[i].name;
			}
		}
		return "Brand";
	};
	render() {
		let { uid } = this.props;
		if (!uid) {
			return <Redirect to="/auth/login" />;
		}
		return (
			<>
				<Sidebar
					{...this.props}
					routes={routes}
					logo={{
						innerLink: "/admin/index",
						imgSrc: require("assets/img/brand/argon-react.png"),
						imgAlt: "...",
					}}
				/>
				<div className="main-content" ref="mainContent">
					<AdminNavbar
						{...this.props}
						brandText={this.getBrandText(this.props.location.pathname)}
					/>
					<Switch>
						{this.getRoutes(routes)}

						<Redirect from="*" to="/admin/index" />
					</Switch>
				</div>
			</>
		);
	}
}

export default connect(state => ({ uid: state.firebase.auth.uid }))(Admin);