import { resetPassword } from "actions/authActions";
import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
// reactstrap components
import {
	Button,
	Card,
	CardBody, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon,
	InputGroupText,

} from "reactstrap";
import { compose } from "redux";


class ForgetPassword extends React.Component {
	resetPassword = e => {
		e.preventDefault();
		this.props.resetPassword(e.target.email.value);
		this.props.history.push("/auth/login");

	};
	render() {
		const { authError } = this.props;

		// TODO:SHOW toast when click on password reset

		return (
			<>
				<Col lg="5" md="7">
					<Card className="bg-secondary shadow border-0">
						<CardBody className="px-lg-5 py-lg-5">
							<div className="text-center text-muted mb-4">
								<small>Forget Password</small>
							</div>
							<Form onSubmit={this.resetPassword} role="form">
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											name="email"
											placeholder="Email"
											type="email"
											autoComplete="new-email"
										/>
									</InputGroup>
								</FormGroup>
								{authError ? (
									<h3 className="text-danger"> {authError}</h3>
								) : null}
								<div className="text-center">
									<Button className="my-4" color="primary">
										Reset Password
									</Button>
								</div>
							</Form>
						</CardBody>

					</Card>
				</Col>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		// isLoad: state.auth.isLoad,
		// uid: state.auth.uid,
		// authError: state.auth.authError,
		consultants: state.firestore.ordered.consultants,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		resetPassword: (email) => dispatch(resetPassword(email)),
	};
};

export default compose(
	firestoreConnect([{ collection: "consultants" }]),
	connect(mapStateToProps, mapDispatchToProps)
)(ForgetPassword);
