import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
  Form,
  Collapse,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { deleteProjects, addProjects } from "actions/clientActions";
import CardBody from "reactstrap/lib/CardBody";
import DeleteDialog from "components/DeleteDialog";
import AddProjectForm from "components/AddProjectForm";

class Projects extends React.Component {
  state = {
    client_id: "",
    isDeleteProject: false,
    deleteProjectData: {},
    deleteKey: '',
    isOpenAddProjectForm: false
  };

  toggleDeleteProject = () => {
    this.setState({
      isDeleteProject: !this.state.isDeleteProject,
    })
  }
  toggleAddProjectForm = () => {
    this.setState({
      isOpenAddProjectForm: !this.state.isOpenAddProjectForm,
    })
  }
  render() {
    const { clients, assessments, consultants, uid } = this.props;
    const thisConsultant =
      consultants && consultants.find((con) => con.id === uid);
    const clientId = this.props.match.params.id;
    const thisClient =
      clients && clients.filter((client) => client.id === clientId)[0];

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--9" fluid>
          <Row>
            <div className="col">
              <Card className=" shadow">
                <CardHeader className=" border-0">
                  <h3 className=" mb-0">Projects</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row className="align-items-center">
                      <Col md={8}>
                        <FormGroup>
                          <Label>Client:</Label>
                          <Input
                            type="select"
                            name="client_id"
                            value={this.state.client_id}
                            onChange={(e) =>
                              this.setState({
                                client_id: e.target.value,
                              })
                            }
                          >
                            <option value="" disabled>
                              --Select Client--
                            </option>
                            {clients &&
                              clients
                                .filter((client) => client.consultantId === uid)
                                .map((client) => (
                                  <option key={client.id} value={client.id}>
                                    {client.name}
                                  </option>
                                ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <div style={{ display: "flex", flexDirection: "row", padding: "20px" }} >
                        <Row style={{ marginRight: "10px" }} >
                          <Col  >
                            <Button
                              color="default"
                              style={{ paddingRight: "40px", paddingLeft: "40px" }}
                              disabled={this.state.client_id === ""}
                              block
                              onClick={() => this.setState({
                                isOpenAddProjectForm: true
                              })}
                            >
                              Add Project
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button
                              color="danger"
                              disabled={this.state.client_id === ""}
                              block
                              onClick={() => this.setState({
                                client_id: ""
                              })}
                            >
                              <i class="fas fa-times"></i>
                            </Button>
                          </Col>
                        </Row>

                      </div>
                    </Row>
                  </Form>
                </CardBody>

                {this.state.isOpenAddProjectForm && (
                  <AddProjectForm
                    toggle={this.toggleAddProjectForm}
                    isOpenForm={this.state.isOpenAddProjectForm}
                    clientId={this.state.client_id}
                    props={this.props}
                  />
                )}

                <Table className="align-items-center  table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* <th scope="col">Client Id</th> */}
                      <th scope="col">Project Name</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Time Zone</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clients &&
                      this.state.client_id === "" &&
                      clients
                        .filter((client) => client.consultantId === uid)
                        .map((client) => {
                          return client.projects.map((project, id) => {
                            let {
                              name,
                              start_date,
                              end_date,
                              time_zone,
                              status,
                            } = project;
                            return (
                              <tr>

                                <td>
                                  <Link
                                    style={{ padding: "0.25rem 0.5rem" }}
                                    to={{
                                      pathname:
                                        "/admin/clients/" +
                                        client.id +
                                        "/viewProject/" +
                                        project.id,
                                      state: {
                                        client: thisClient && thisClient.name,
                                      },
                                    }}
                                  >
                                    {name}
                                  </Link>
                                </td>

                                <td>{start_date}</td>
                                <td>{end_date}</td>
                                <td>{time_zone}</td>
                                <td className="text-capitalize">
                                  {String(status).replace("_", " ")}
                                </td>
                                <td>
                                  <Link
                                    style={{ padding: "0.25rem 0.5rem" }}
                                    to={
                                      "/admin/clients/" +
                                      client.id +
                                      "/editProject/" +
                                      project.id
                                    }
                                    id={'editProject'}
                                  >
                                    <Button size="sm" color="white">
                                      <i className="ni ni-settings  " />
                                    </Button>
                                  </Link>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={'editProject'}
                                  >
                                    Edit Project
                                  </UncontrolledTooltip>

                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        deleteKey: "deleteProject",
                                        deleteProjectData: project,
                                      })
                                      this.toggleDeleteProject();
                                    }}
                                    size="sm"
                                    color="white"
                                    id={'deleteProject'}
                                  >
                                    <i className="fas fa-trash text-red " />
                                  </Button>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={'deleteProject'}
                                  >
                                    Delete Project
                                  </UncontrolledTooltip>
                                  <Link
                                    style={{ padding: "0.25rem 0.5rem" }}
                                    to={{
                                      pathname:
                                        "/admin/clients/" +
                                        client.id +
                                        "/viewProject/" +
                                        project.id,
                                      state: {
                                        client: thisClient && thisClient.name,
                                        prevLink: "/admin/projects"
                                      },
                                    }}
                                    id={'viewProject'}
                                  >
                                    <Button size="sm" color="white">
                                      <i className="fas fa-eye  " />
                                    </Button>
                                  </Link>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={'viewProject'}
                                  >
                                    View Project
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                            );
                          });
                        })}
                    {clients &&
                      this.state.client_id !== "" &&
                      clients
                        .filter(
                          (client) =>
                            client.consultantId === uid &&
                            client.id === this.state.client_id
                        )
                        .map((client) => {
                          return client.projects.map((project) => {
                            let {
                              name,
                              start_date,
                              end_date,
                              time_zone,
                              status,
                            } = project;
                            return (
                              <tr>
                                <td>
                                  <Link
                                    style={{ padding: "0.25rem 0.5rem" }}
                                    to={{
                                      pathname:
                                        "/admin/clients/" +
                                        client.id +
                                        "/viewProject/" +
                                        project.id,
                                      state: {
                                        client: thisClient && thisClient.name,
                                      },
                                    }}
                                  >
                                    {name}
                                  </Link>
                                </td>
                                <td>{start_date}</td>
                                <td>{end_date}</td>
                                <td>{time_zone}</td>
                                <td className="text-capitalize">
                                  {String(status).replace("_", " ")}
                                </td>
                                <td>
                                  <Link
                                    style={{ padding: "0.25rem 0.5rem" }}
                                    to={
                                      "/admin/clients/" +
                                      client.id +
                                      "/editProject/" +
                                      project.id
                                    }
                                  >
                                    <Button size="sm" color="white">
                                      <i className="ni ni-settings  " />
                                    </Button>
                                  </Link>

                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        deleteKey: "deleteProject",
                                        deleteProjectData: project,
                                      })
                                      this.toggleDeleteProject()
                                    }}
                                    size="sm"
                                    color="white"
                                  >
                                    <i className="fas fa-trash text-red" />
                                  </Button>
                                  <Link
                                    style={{ padding: "0.25rem 0.5rem" }}
                                    to={{
                                      pathname:
                                        "/admin/clients/" +
                                        client.id +
                                        "/viewProject/" +
                                        project.id,
                                      state: {
                                        client: thisClient && thisClient.name,
                                      },
                                    }}
                                  >
                                    <Button size="sm" color="white">
                                      <i className="fas fa-eye  " />
                                    </Button>
                                  </Link>
                                </td>
                              </tr>
                            );
                          });
                        })}
                    {this.state.isDeleteProject && (
                      <DeleteDialog
                        toggle={this.toggleDeleteProject}
                        deleteKey={this.state.deleteKey}
                        deleteData={this.state.isDeleteProject}
                        onDelete={async () => {
                          await this.props.deleteProjects(this.state.deleteProjectData, this.state.deleteProjectData?.clientId);
                        }}
                      />
                    )}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    authError: state.auth_consultant.authError,
    clients: state.firestore.ordered.clients,
    templates: state.firestore.ordered.templates,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addProject: (project, clientId) =>
      dispatch(addProjects(project, clientId)),
    deleteProjects: (project, clientId) => dispatch(deleteProjects(project, clientId)),

  };
};

export default compose(
  firestoreConnect([{ collection: "clients" }]),
  connect(mapStateToProps, mapDispatchToProps)
)(Projects);
